@charset "UTF-8";
@import "global.scss";

.thumb-list {
  display: flex;
  flex-wrap: wrap;

  &--item {
    width: calc(33.33% - 14px);
    margin: 0 20px 20px 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .image-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      position: relative;
      overflow: hidden;
      border-radius: 20px 20px 0 0;

      .img {
        max-width: 100%;
      }

      .badge {
        position: absolute;
        left: 20px;
        top: 20px;
      }

      .btn-like {
        position: absolute;
        right: 15px;
        top: 24px;
      }
    }

    .text-wrap {
      border: 1px solid $colLine;
      border-radius: 0 0 20px 20px;
      padding: 30px;

      .tit {
        font-size: 1.8rem;
        font-weight: 600;
        @include text_ellipsis;
      }

      .info {
        display: flex;
        align-items: center;
        color: $textCol2;
        font-size: 1.4rem;
        margin: 8px 0;

        .badge {
          margin-left: 6px;
        }
      }

      .bottom {
        margin-top: 16px;
      }
    }
  }

  &.type2 {
    .thumb-list--item {
      .image-wrap {
        height: 172px;

        img {
          min-width: 100%;
          min-height: 100%;
        }
      }

      .text-wrap {
        padding: 20px 30px;

        .top {
          margin-bottom: 8px;
          font-size: 1.4rem;
          display: flex;
          align-items: center;

          .badge {
            margin-left: 6px;
          }
        }

        .rate {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .btn-star {
            margin-right: 2px;
          }

          .count {
            color: $textCol2;
            font-size: 1.4rem;
          }
        }

        .bottom {
          text-align: right;
          margin-top: 7px;

          .strong {
            font-weight: 900;
            font-size: 2.4rem;
            color: #000;
            margin-top: 6px;
          }
        }
      }
    }
  }
}

.text-list {
  &--item {
    display: flex;
    border: 1px solid $colLine;
    padding: 32px 30px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .col {
      margin-right: 30px;
      font-size: 1.6rem;
      white-space: nowrap;

      &:nth-child(1) {
        width: 7%;
        max-width: 7%;
      }

      &:nth-child(2) {
        width: 10%;
        max-width: 10%;
      }

      &:nth-child(3) {
        width: 35%;
        max-width: 35%;
      }

      &:nth-child(4) {
        width: 23%;
        max-width: 23%;
      }

      &:nth-child(5) {
        width: 20%;
        max-width: 20%;
      }

      .label {
        display: block;
        font-size: 1.2rem;
        font-weight: 400;
        color: $textCol3;
        margin-bottom: 8px;
      }

      .text {
        display: inline-block;
        @include text_ellipsis;
        width: auto;
      }

      .num {
        display: inline-block;
        position: relative;
        top: -3px;
        margin-left: 4px;
        color: $textCol2;
      }
    }
  }

  &.type2 {
    .text-list--item {
      border: none;
      border-bottom: 1px solid $colLine;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      margin: 15px 0;
      cursor: auto;

      .left,
      .right {
        display: flex;
        font-size: 1.4rem;
        font-weight: 400;
        align-items: center;
      }

      .badge-wrap {
        max-width: 80px;
        min-width: 80px;
      }

      .strong {
        font-weight: 600;
        max-width: 312px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-height: 15px;
      }

      .right {
        width: auto;

        & > * {
          margin-left: 30px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:nth-child(1) {
            max-width: 100px;
            width: 100px;
            text-align: left;
          }

          &:nth-child(2) {
            max-width: 80px;
          }
        }
      }
    }
  }

  &.type3 {
    .text-list--item {
      align-items: center;

      .col {
        margin-right: 20px;

        &:nth-child(1) {
          min-width: 18%;
          max-width: 18%;
        }

        &:nth-child(2) {
          min-width: 25%;
          max-width: 25%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          min-width: 60px;
          max-width: 60px;
        }

        .text {
          &.name,
          .ellipsis {
            @include text_ellipsis;
            width: max-content;
            max-width: 85px;
            display: inline-block;
          }

          .ellipsis {
            max-width: 45px;
            vertical-align: middle;
          }

          &.badge {
            font-weight: 600;
            height: 24px;
            line-height: 24px;
            vertical-align: unset;

            &.badge-hide:nth-child(n + 3) {
              display: none;
            }
          }

          & + .num {
            font-size: 1.4rem;
            font-weight: 400;
            vertical-align: text-top;
          }
        }

        .badge {
          vertical-align: top;
        }

        .grade {
          display: inline-block;
          margin-left: 4px;

          &.type {
            &-1 {
              @include ic(grade_1, 16, 16);
            }

            &-2 {
              @include ic(grade_2, 16, 16);
            }

            &-3 {
              @include ic(grade_3, 16, 16);
            }

            &-4 {
              @include ic(grade_4, 16, 16);
            }

            &-5 {
              @include ic(grade_5, 16, 16);
            }
          }
        }

        .rate {
          background: url(../assets/images/icon/star_fill.svg) no-repeat left
            center;
          padding-left: 18px;
          font-size: 1.4rem;
          color: $textCol2;
          vertical-align: text-top;
          margin-left: 20px;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 16px;
            background-color: $colLine;
            position: absolute;
            left: -12px;
            top: 0;
          }
        }
      }
    }
  }

  &.type4 {
    margin-top: 40px;

    .text-list--item {
      align-items: center;
      padding: 22px 30px;
      border-radius: 20px;
      transition: all 0.3s;

      &:hover:not(.top) {
        background-color: $colBg;
      }

      &.top {
        border: none;
        padding: 0 30px;
        margin-bottom: 2px;
      }

      .col {
        padding-right: 16px;
        margin: 0;

        &:nth-child(1) {
          min-width: 112px;
          max-width: 112px;
        }

        &:nth-child(2) {
          min-width: 100px;
          max-width: 100px;
          padding: 0 16px;
        }

        &:nth-child(3) {
          min-width: 164px;
          max-width: 164px;
          padding: 0 10px;
        }

        &:nth-child(4) {
          min-width: 360px;
          max-width: 360px;
          padding: 0 10px;
        }

        &:nth-child(5) {
          min-width: 90px;
          max-width: 90px;
          padding: 0 11px;
        }

        &:nth-child(6) {
          min-width: 142px;
          max-width: 142px;
          padding: 0 0 0 10px;
        }

        .text {
          font-size: 1.4rem;
          font-weight: 400;

          strong {
            font-weight: 600;
          }

          &.name {
            @include text_ellipsis;
            width: max-content;
            max-width: 85px;
            display: inline-block;
          }

          .ellipsis {
            @include text_ellipsis;
            vertical-align: middle;
          }
        }

        .badge {
          vertical-align: top;
        }

        .like {
          font-size: 1.2rem;

          &:before {
            content: "";
            display: inline-block;
            margin-right: 4px;
            @include ic(like_color, 14, 14);
            vertical-align: bottom;
          }
        }

        .btn-profile {
          color: #0a7aff;
          font-weight: 400;
          font-size: 1.4rem;

          &:after {
            content: "";
            display: inline-block;
            margin-left: 4px;
            @include ic(arrow_right_blue, 14, 14);
          }
        }

        .grade {
          display: inline-block;
          margin-left: 4px;
          min-width: fit-content;
          padding-left: 20px;
          font-size: 1.2rem;
          line-height: 1.4;
          position: relative;
          top: -1px;

          &.type {
            &-1 {
              @include ic(grade_1, 16, 16);
              background-position: left center;
            }

            &-2 {
              @include ic(grade_2, 16, 16);
              background-position: left center;
            }

            &-3 {
              @include ic(grade_3, 16, 16);
              background-position: left center;
            }

            &-4 {
              @include ic(grade_4, 16, 16);
              background-position: left center;
            }

            &-5 {
              @include ic(grade_5, 16, 16);
              background-position: left center;
            }
          }
        }
      }
    }
  }

  &.type5 {
    .text-list--item {
      align-items: center;
      padding: 22px 30px;
      border-radius: 20px;
      transition: all 0.3s;

      &:hover:not(.top) {
        background-color: $colBg;
      }

      &.top {
        border: none;
        padding: 0 30px;
        margin-bottom: 2px;
      }

      .col {
        margin-right: 20px;
        display: flex;
        align-items: center;

        &:nth-child(1) {
          min-width: 60%;
          max-width: 60%;
        }

        &:nth-child(2) {
          min-width: 22%;
          max-width: 22%;
        }

        &:nth-child(3) {
          min-width: 15%;
          max-width: 15%;
        }

        .text {
          font-size: 1.2rem;
          color: $textCol2;

          .ellipsis {
            @include text_ellipsis;
            vertical-align: middle;
            font-weight: 600;
            font-size: 1.4rem;
            color: $textCol1;
          }
        }

        .view,
        .talk {
          font-size: 1.2rem;
          display: inline-flex;
          min-width: 72px;
          align-items: center;

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
          }
        }

        .view {
          color: $colSub;

          &:before {
            @include ic(view_green, 16, 16);
          }
        }

        .talk {
          color: #0a7aff;

          &:before {
            @include ic(comment_blue, 16, 16);
            min-width: 16px;
          }
        }
      }
    }
  }

  &.type6 {
    .text-list--item {
      align-items: center;
      padding: 17px 30px;
      border-radius: 20px;
      transition: all 0.3s;

      &:hover:not(.top) {
        background-color: $colBg;
      }

      &.top {
        border: none;
        padding: 0 30px;
        margin-bottom: 2px;
      }

      .col {
        display: flex;
        align-items: center;
        margin: 0;

        &:nth-child(1) {
          min-width: 76px;
          max-width: 76px;
        }

        &:nth-child(2) {
          min-width: 614px;
          max-width: 614px;
          padding: 0 30px;
        }

        &:nth-child(3) {
          min-width: 198px;
          max-width: 198px;
        }

        &:nth-child(4) {
          min-width: 80px;
          max-width: 80px;
        }

        .text {
          font-size: 1.2rem;
          color: $textCol2;

          .ellipsis {
            @include text_ellipsis;
            vertical-align: middle;
            font-weight: 600;
            font-size: 1.4rem;
            color: $textCol1;
          }
        }

        .view,
        .talk {
          font-size: 1.2rem;
          display: inline-flex;
          min-width: 72px;
          align-items: center;

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
          }
        }

        .view {
          color: $colSub;

          &:before {
            @include ic(view_green, 16, 16);
          }
        }

        .talk {
          color: #0a7aff;

          &:before {
            @include ic(comment_blue, 16, 16);
            min-width: 16px;
          }
        }
      }
    }
  }

  @media (max-width: $pc) {
    &--item {
      width: fit-content;
    }

    &.type2 {
      .text-list--item {
        .right {
          margin-top: 10px;

          & > :first-child {
            margin-left: 0;
          }
        }
      }
    }

    &.type4 {
      overflow-x: auto;
      white-space: nowrap;
      width: calc(100% + 50px);
      padding: 0 25px;
      margin-top: 20px;
      position: relative;
      left: -25px;

      .text-list--item {
        flex-wrap: nowrap;

        // &>div {
        //     min-width: unset !important;
        //     flex: auto !important;

        //     &:nth-child(1) {
        //         width: 110px !important;
        //     }

        //     &:nth-child(2),
        //     &:nth-child(5) {
        //         width: 60px !important;
        //     }

        //     &:nth-child(3),
        //     &:nth-child(4) {
        //         width: 130px !important;
        //     }

        //     &:nth-child(6) {
        //         width: 105px !important;
        //     }
        // }
      }
    }

    &.type5 {
      overflow-x: auto;
      white-space: nowrap;

      .text-list--item {
        flex-wrap: nowrap;
        width: fit-content;
        padding: 16px 20px;

        & > div {
          min-width: unset !important;
          flex: auto !important;

          &:nth-child(1) {
            width: 300px !important;
          }

          &:nth-child(2) {
            width: 140px !important;
          }

          &:nth-child(3) {
            width: 80px !important;
            margin-right: 0;
          }
        }
      }
    }

    &.type6 {
      overflow-x: auto;
      white-space: nowrap;
      width: calc(100% + 50px);
      position: relative;
      left: -25px;
      padding: 0 25px;

      .text-list--item {
        flex-wrap: nowrap;
        width: fit-content;

        // &>div {
        //     min-width: unset !important;
        //     flex: auto !important;

        //     &:nth-child(1) {
        //         width: 60px !important;
        //     }

        //     &:nth-child(2) {
        //         width: 300px !important;
        //     }

        //     &:nth-child(3) {
        //         width: 140px !important;
        //         margin-right: 0;
        //     }

        //     &:nth-child(4) {
        //         width: 80px !important;
        //         margin-right: 0;
        //     }
        // }
      }
    }
  }

  @media (max-width: $mo) {
    &.type2 {
      &.mt {
        margin-top: 30px;
      }

      .text-list--item {
        width: 100%;
        display: block;
        padding: 20px 0;
        margin: 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        .left {
          margin-bottom: 7px;

          .badge-wrap {
            width: 64px;
            min-width: 64px;
          }
        }

        .right {
          margin-top: 0;
          padding-left: 64px;

          & > div {
            width: auto !important;
            min-width: unset !important;
            color: #999;
            margin-left: 0;

            &:first-child:after {
              content: "/";
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
}

.card-list {
  overflow: hidden;
  position: relative;
  width: calc(100% + 38px);
  position: relative;
  left: -19px;
  padding: 0 9px;

  &--item {
    height: 200px;
    background-color: #000;
    margin: 0 10px;
    border-radius: 10px;
    padding: 30px;
    cursor: pointer;

    .top {
      .badge {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        height: 24px;
        line-height: 23px;
        border: 1px solid $colSub;

        &.type {
          &-2 {
            border-color: #0a7aff;
          }

          &-3 {
            border-color: #5712eb;
          }
        }
      }
    }

    .tit {
      margin-top: 30px;
      font-size: 2rem;
      font-weight: 600;
      color: #fff;
      line-height: 1.2;

      .count {
        font-size: 1.4rem;
        color: $colPrimary;
        margin-left: 4px;
      }
    }

    .bottom {
      margin-top: 20px;
      font-size: 1.4rem;
      color: $colGrey;
    }
  }
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  @include ic(slide_arrow_right, 40, 40);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  right: 20px;
  top: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 2;

  &::before {
    display: none;
  }

  &:hover,
  &:focus {
    @include ic(slide_arrow_right, 40, 40);
    background-color: rgba(255, 255, 255, 1);
  }
}

.slick-arrow.slick-prev {
  @include ic(slide_arrow_left, 40, 40);
  right: unset;
  left: 20px;

  &:hover,
  &:focus {
    @include ic(slide_arrow_left, 40, 40);
  }
}

.faq {
  &-list {
    &--tit {
      font-size: 1.2rem;
      color: $colSub;
      margin-bottom: 20px;
    }

    .qs {
      display: flex;
      flex-direction: column;

      .tit {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .btn.q {
        margin-bottom: 16px;
        height: 54px;
        background-color: $colBg;
        width: fit-content;
        padding: 0 14px;
        border-radius: 10px;
        font-size: 1.8rem;
        font-weight: 600;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-bottom {
    margin-top: 100px;
    padding: 62px 0;
    width: 100vw;
    background-color: $colBg;
    position: relative;
    left: calc(-50vw + 510px);
    text-align: center;
    line-height: 1.3;
    font-size: 1.6rem;

    .strong {
      font-size: 2.4rem;
      font-weight: 700;
      @include gradient;
      margin-top: 10px;
    }
  }

  @media (max-width: $pc) {
    &-bottom {
      width: calc(100% + 50px);
      left: -25px;
    }
  }

  @media (max-width: $mo) {
    &-list {
      .qs {
        margin-top: 60px;

        &:first-child {
          margin-top: 0;
        }

        .tit {
          font-size: 2rem;
        }

        .btn {
          &.q {
            word-break: keep-all;
            font-size: 1.4rem;
            height: auto;
            min-height: 50px;
            text-align: left;
            line-height: 1.4;
            padding: 12px 18px;
          }
        }
      }
    }

    &-bottom {
      margin-top: 60px;
    }
  }
}

.terms {
  padding: 40px 0;

  &-tit {
    font-size: 2.4rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;

    .date {
      font-size: 1.8rem;
      color: $textCol1;
      font-weight: 600;
      margin-left: 20px;
    }
  }

  &-con {
    font-size: 1.6rem;
    white-space: pre-line;
    line-height: 1.4;
    word-break: keep-all;
  }

  &-table {
    display: flex;
    flex-direction: column;
    margin: 13px 0 -20px;

    .tr {
      display: flex;

      & > * {
        flex: 1;
      }
    }

    .th {
      background-color: $colBg;
      margin-right: 10px;
      padding: 12px 20px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 1.4rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .td {
      border-bottom: 1px solid $colLine;
      padding: 20px;
      font-weight: 400;
      font-size: 1.4rem;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-link {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 26px 0;
    padding-left: 20px;
    background: url(../assets/images/icon/arrow_down.svg) no-repeat left center;
  }

  @media (max-width: $mo) {
    &-table {
      width: 200%;

      .th,
      .td {
        padding: 8px 10px;
      }

      &.type2 {
        width: 100%;

        .tr {
          flex-direction: column;

          .th {
            padding: 13px 20px;
          }

          .td {
            padding: 20px;
            border: none;
          }
        }
      }
    }
  }
}

.login {
  &-wrap {
    position: relative;
    height: 570px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  &-logo {
    width: 100%;
    height: 87px;
    background: url(../assets/images/logo.svg) no-repeat center / 100% 100%;
  }

  &-guide {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #000;
    margin-top: 20px;
  }

  &-find {
    font-size: 1.2rem;
    color: $textCol3;
    margin: 30px auto;
    display: block;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &-sns {
    border-top: 1px solid $textCol2;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;

    .top {
      width: 42px;
      font-size: 1.2rem;
      margin: -5px auto 0;
      text-align: center;
      background-color: #fff;
    }

    &--btn {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 0 9px;
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        font-weight: 600;
        color: $textCol3;

        .image {
          margin-bottom: 8px;
        }

        &.kakao .image {
          @include ic(sns_kakao, 60, 60);
        }

        &.naver .image {
          @include ic(sns_naver, 60, 60);
        }

        &.google .image {
          @include ic(sns_google, 60, 60);
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-logo {
      margin: 33px auto 16px;
      height: calc(100vw / 4);
    }

    &-wrap {
      min-height: 500px;
      height: 100%;
      max-width: unset;
    }

    &-sns {
      bottom: 20px;

      &--btn {
        .btn {
          margin: 0 12.5px;
        }
      }
    }
  }
}

.join {
  &-wrap {
    position: relative;
    width: 300px;
    margin: 100px auto 0;

    .btn-close {
      position: absolute;
      right: -35px;
      top: -35px;
      @include ic(close, 17, 17);
      background-size: 100%;
    }

    .btn-wrap {
      margin: 14px 0;
    }
  }

  &-tit {
    font-size: 1.8rem;
    text-align: center;
  }

  &-bottom {
    margin-top: 80px;

    &--con {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.top {
        border-bottom: 1px solid $colLine;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: $mo) {
    &-wrap {
      margin: 30px auto 0;
      width: 100%;
      height: auto;

      .btn-lg {
        width: 100%;
      }

      .btn-close {
        right: 0;
        top: 0;
      }
    }
  }
}

.mypage {
  &-con {
    .floating-btn {
      display: none;
      position: fixed;
      right: 30px;
      bottom: 30px;
      width: 50px;
      height: 50px;
      background-color: $colPrimary;
      border-radius: 50%;
      @include ic(state_6_on, 50, 50);
      cursor: pointer;
      z-index: 9;

      &.active:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #df2e47;
        position: absolute;
        left: 2px;
        top: 2px;
      }
    }
  }

  &-tab {
    margin: 40px 0 40px;
    display: flex;
    align-items: center;

    .btn {
      min-width: 116px;
      height: 34px;
      border: 1px solid $colLine;
      margin-right: 10px;
      border-radius: 5px;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 0 18px;

      &.c:before,
      &.h:before {
        content: "";
        @include ic(client_fill, 14, 14);
        margin-right: 6px;
        min-width: 14px;
      }

      &.h:before {
        @include ic(heroes_fill, 14, 14);
      }

      &:hover {
        background-color: $colSub2;
        border-color: $colSub2;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      }

      &:active,
      &.active {
        background-color: $colPrimary;
        border-color: $colPrimary;
        color: #000;
        font-weight: 600;
      }
    }
  }

  &-profile {
    display: flex;
    width: 100%;

    .left {
      width: 162px;
      margin-right: 20px;
    }

    .right {
      width: calc(100% - 162px);
    }
  }

  &-tit {
    padding: 30px 0;
    display: flex;
    align-items: flex-start;
    font-size: 1.6rem;
    font-weight: 600;

    img {
      width: 18px;
      margin-right: 6px;

      &.top {
        position: relative;
        top: -5px;
      }
    }

    &.text {
      padding: 5px 0 20px;
      font-size: 3rem;
      font-weight: 700;
    }

    &--sub {
      margin-top: -25px;
      font-size: 1.4rem;
      font-weight: 400;
      color: $textCol2;
    }
  }

  &-none {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    font-size: 1.8rem;
    color: $textCol3;
    font-weight: 400;
  }

  &-state {
    display: flex;

    .left {
      min-width: 276px;
      width: 276px;
      margin-right: 20px;
      border-radius: 5px;

      .tab {
        &-btn {
          display: flex;

          .tooltip {
            flex: 1;

            .btn {
              width: 100%;
            }
          }

          .btn {
            flex: 1;
            height: 40px;
            background-color: $colBg;
            color: $colGrey;
            font-size: 1.4rem;
            position: relative;

            &.active {
              background-color: $colSub2;
              color: $textCol1;
              font-weight: 600;
            }

            &.new:after {
              content: "";
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 2px;
              background-color: #df2e47;
              position: relative;
              margin-left: 2px;
              top: -6px;
            }

            &:first-child {
              border-radius: 5px 0 0 0;
            }

            &:last-child {
              border-radius: 0 5px 0 0;
            }
          }
        }

        &-con {
          padding: 25px 25px 22px;
          border: 1px solid $colLine;
          border-top: none;

          &-m {
            border-radius: 20px;
            border-top: 1px solid $colLine;

            .info-list.show {
              margin-bottom: 20px;
            }

            > ul.hide > li:nth-child(n + 3) {
              display: none;
            }

            > ul.show > li:nth-child(n + 3) {
              display: list-item;
            }

            > .m-btn {
              display: flex;
              text-align: center;
              width: 100%;
              color: $textCol3;

              &::after {
                content: "";
                display: inline-block;
                margin-left: 4px;
                width: 16px;
                min-width: 16px;
                height: 16px;
                background-color: $textCol3;
                -webkit-mask: url(../assets/images/icon/arrow_down.svg)
                  no-repeat center center;
                mask: url(../assets/images/icon/arrow_down.svg) no-repeat center
                  center;
              }

              &.show {
                &::after {
                  transform: rotate(-180deg);
                }
              }
            }

            &.message {
              background-color: #00ff85;
              width: 100vw;
              height: 100%;
              position: fixed;
              top: 0;
              left: 0;
              background-color: #fff;
              z-index: 9999;
              border: none;
              border-radius: 0;

              > .btn-close {
                display: block;
                position: absolute;
                right: 35px;
                top: 35px;
                @include ic(close, 20, 20);
                background-size: 100%;
                cursor: pointer;
                z-index: 2;
              }

              > .message-wrap {
                height: calc(100% - 105px);
              }
            }
          }

          .badge-md {
            margin-bottom: 5px;
          }

          .message {
            &-wrap {
              border: none;
              padding: 0;
              width: calc(100% + 25px);
              position: relative;
              left: -10px;
              height: 500px;
              overflow-y: auto;

              display: flex;
              flex-direction: column;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              .box {
                width: fit-content;
              }

              .btn-history {
                margin: 0 auto 30px;
                display: block;
                font-size: 1.2rem;
                color: $textCol3;

                &:after {
                  content: "";
                  display: inline-block;
                  @include ic(arrow_prev, 20, 8);
                  background-size: 100%;
                  transform: rotate(90deg);
                }
              }

              .date {
                .state {
                  margin-left: 6px;
                  padding-left: 6px;
                  border-left: 1px solid $colLine;
                  line-height: 1;
                  display: inline-block;
                  height: 12px;

                  &.read {
                    color: #0a7aff;
                  }
                }
              }
            }

            &-tit {
              padding: 9px;
              text-align: center;
              font-weight: 500;
              font-size: 18px;
            }

            &-guide {
              font-size: 1.2rem;
              font-weight: 400;
              text-align: center;
              line-height: 1.5;
              color: $textCol2;
              white-space: nowrap;
              margin-bottom: 30px;
            }

            &-right {
              .box {
                margin-left: auto;
              }

              .date {
                .state {
                  margin: 0 6px 0 0;
                  padding: 0 6px 0 0;
                  border-left: none;
                  border-right: 1px solid $colLine;
                }
              }
            }

            &-input {
              position: relative;
              padding: 16px 60px 16px 18px;
              width: calc(100% + 25px);
              left: -10px;
              margin-top: 40px;
              border: 1px solid #eee;
              border-radius: 5px;

              .btn {
                position: absolute;
                right: 8px;
                top: 10px;
                height: 30px;
              }

              .input-text {
                padding: 0;
                resize: none;
                border: none;
                border-radius: 0;
                height: 18px;
              }
            }
          }
        }
      }

      .info-list {
        &--item {
          margin-bottom: 20px;

          .label {
            font-size: 1.2rem;
            color: $textCol3;
            font-weight: 400;
          }

          .text {
            margin-top: 6px;
            font-size: 1.4rem;
            line-height: 1.5;

            .badge {
              vertical-align: text-bottom;
              margin-right: 4px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          .manager {
            display: flex;
            margin-top: 6px;
            align-items: center;

            .img-wrap {
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              overflow: hidden;
              border-radius: 5px;

              img {
                width: 100%;
                height: auto;
              }
            }

            .txt-wrap {
              .name {
                font-size: 1.4rem;
                font-weight: 500;
                margin-bottom: 10px;
              }

              .text {
                margin: 6px 0 0;
                line-height: 1;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }

    .right {
      width: 100%;

      .state {
        border: 1px solid $colLine;
        border-radius: 5px;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
          font-size: 1.2rem;
          color: $colGrey;
          margin-right: 25px;
          text-align: center;
          width: 80px;
          position: relative;

          &:nth-last-child(2) {
            margin-right: 40px;
          }

          &:last-child {
            margin: 0 -20px 0 0;
            width: 160px;
            border-left: 1px solid $colLine;

            &:after {
              display: none;
            }
          }

          &:first-child:after {
            display: none;
          }

          &:after {
            content: "";
            display: block;
            width: 20px;
            height: 3px;
            background-color: $colBg;
            position: absolute;
            left: -23px;
            top: 25px;
            border-radius: 3px;
          }

          &:before {
            content: "";
            display: block;
            background-color: $colBg;
            border-radius: 25px;
            margin: 0 auto 6px;
          }

          &.active {
            color: #000;
            font-weight: 600;

            &:before,
            &:after {
              background-color: $colPrimary;
              cursor: pointer; //
            }

            &.state {
              &-1:before {
                @include ic(state_1_on, 50, 50);
              }

              &-2:before {
                @include ic(state_2_on, 50, 50);
              }

              &-3:before {
                @include ic(state_3_on, 50, 50);
              }

              &-4:before {
                @include ic(state_4_on, 50, 50);
              }

              &-5:before {
                @include ic(state_5_on, 50, 50);
              }

              &-6:before {
                @include ic(state_6_on, 50, 50);
              }

              &-7:before {
                @include ic(state_7_on, 50, 50);
              }
            }
          }
        }

        &-1:before {
          @include ic(state_1_on, 50, 50);
        }

        &-2:before {
          @include ic(state_2, 50, 50);
        }

        &-3:before {
          @include ic(state_3, 50, 50);
        }

        &-4:before {
          @include ic(state_8, 50, 50);
        }

        &-5:before {
          @include ic(state_4, 50, 50);
        }

        // &-6:before {
        //     @include ic(state_6_on, 50, 50);
        // }

        &-7:before {
          @include ic(state_7, 50, 50);
        }

        &-guide {
          padding: 120px;
          text-align: center;
          font-size: 1.6rem;
          line-height: 1.3;

          .icon {
            border-radius: 40px;
            margin: 0 auto 40px;

            &.type {
              &-meatball {
                @include ic(dots_green_lg, 74, 74);
                background-color: #000;
              }

              &-check {
                @include ic(check_wh_lg, 74, 74);
                background-color: $colPrimary;
              }

              &-write {
                @include ic(pen_wh_lg, 74, 74);
                background-color: #000;
              }

              &-close {
                @include ic(close_wh_lg, 74, 74);
                background-color: #000;
              }
            }
          }

          .sub {
            font-size: 1.4rem;
            font-weight: 400;
            color: $textCol2;
            margin-top: 20px;

            &.type2 {
              width: max-content;
              text-align: left;
              max-width: 365px;
              margin: 20px auto 0;
              // padding: 0 56px;
              line-height: 1.5;
            }
          }

          .review-input {
            width: 364px;
            margin: 40px auto 0;

            & + .btn-lg {
              margin-top: 5px;
              width: 364px;
              height: 42px;
            }

            .rate {
              margin-bottom: 11px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .btn-star {
              width: 24px;
              height: 24px;
              background-position: top;
              margin-right: 4px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .box {
            text-align: left;
            padding: 27px;
            border: 1px solid $colLine;
            border-radius: 10px;
            width: 80%;
            max-width: 364px;
            margin: 40px auto 0;
            font-size: 1.4rem;
            font-weight: 400;
            color: $textCol2;
            line-height: 1.5;
          }

          .btn-lg {
            margin-top: 40px;
            width: 80%;
            max-width: 364px;
          }

          .profile-detail {
            width: calc(100% + 115px);
            position: relative;
            left: -50px;
            border-top: none;

            .label {
              text-align: left;
            }
          }

          .info-list {
            width: 360px;
            margin: 40px auto 0;

            &--item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;
              font-size: 1.4rem;
              font-weight: 400;

              .label {
                font-weight: 600;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &-tip {
          margin: 40px 0;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.7;

          strong {
            color: #0a7aff;
            font-weight: 600;
            margin: 0 2px;
          }

          p {
            display: flex;
            align-items: center;

            &:before {
              content: "";
              display: inline-block;
              @include ic(bulb_color, 22, 22);
              margin-right: 6px;
            }
          }
        }
      }

      .list {
        &-sort {
          margin-bottom: 6px;

          .btn {
            font-size: 1.4rem;
            color: $textCol3;
            font-weight: 400;
            padding-left: 20px;
            background: url(../assets/images/icon/menu_line_grey.svg) no-repeat
              left center;
          }
        }

        &-wrap {
          display: flex;

          .list {
            flex: 1;
            margin-right: 9px;

            &:last-child {
              margin-right: 0;
            }

            &.active {
              .list-tit {
                background-color: #5712eb;
                color: #fff;
              }
            }

            &.done {
              .list-tit {
                background-color: $colGrey;
                color: #fff;
              }

              .list-top {
                .btn-blueline {
                  border-color: $textCol2;
                  color: $textCol2;
                }
              }
            }

            &-tit {
              font-size: 1.4rem;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid $colLine;
              border-radius: 5px;
              padding: 0 14px;
              color: $colGrey;
              margin-bottom: 20px;

              .btn-plus {
                @include ic(m_plus, 20, 20);
              }

              .btn-minus {
                @include ic(m_minus, 20, 20);
              }

              .right {
                width: auto;
                font-size: 1.2rem;
                display: flex;
                align-items: center;

                .input-switch {
                  margin-left: 4px;
                }
              }
            }

            @media (max-width: $mo) {
              &.active {
                .list-tit {
                  background-color: #d6d6d6 !important;
                  color: #fff;
                  margin-bottom: 0;
                  cursor: pointer;

                  &.m-list-active {
                    background-color: #5712eb !important;
                  }
                }
              }

              &-tit {
                &.done {
                  background-color: #d6d6d6 !important;
                  color: #fff;
                  margin-bottom: 0;
                  cursor: pointer;
                }

                &.m-list-active {
                  background-color: #5712eb !important;
                  margin-bottom: 15px;
                }
              }
            }

            &-top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 8px;
              height: 20px;

              .right {
                width: auto;
              }
            }

            .none {
              width: 100%;
              height: 142px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              color: $colGrey;
              border-radius: 5px;
              border: 1px solid $colLine;
            }

            .card {
              height: 142px;
              position: relative;
              display: flex;
              width: 100%;
              padding: 17px 14px;
              border: 1px solid $colLine;
              border-radius: 5px;
              margin-bottom: 4px;
              align-items: flex-start;

              &.disabled {
                background-color: $colBg;

                .box,
                .info > *:not(.state) {
                  filter: grayscale(1);
                  opacity: 0.25;
                }
              }

              .box {
                margin-top: 2px;
                min-width: 12px;
                cursor: pointer; //
              }

              input:checked + .box:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: -1px;
                top: -1px;
                border: 1px solid #0a7aff;
                border-radius: 5px;
              }

              .info {
                position: relative;
                margin-left: 10px;
                width: 100%;

                .state {
                  position: absolute;
                  right: 0;
                  top: 0;
                  padding: 0 6px;
                  border-radius: 0;
                  border: none;
                  height: 16px;
                  font-size: 1.2rem;
                  border-radius: 5px;
                  font-weight: 400;
                  margin-bottom: 8px;

                  &.type {
                    &-1 {
                      background-color: $colGrey;
                      color: #fff;
                    }

                    &-2 {
                      background-color: $colPrimary;
                      color: #000;

                      &.like:after {
                        content: "";
                        display: inline-block;
                        @include ic(heart_fill, 10, 8);
                        background-size: 100%;
                        margin-left: 2px;
                      }
                    }

                    &-3 {
                      background-color: #43f4ff;
                      color: #000;
                    }
                  }
                }

                .name {
                  font-size: 1.4rem;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  span {
                    display: inline-block;
                  }

                  .txt {
                    @include text_ellipsis;
                    width: auto;
                    max-width: 75px;

                    & + span {
                      margin-left: 4px;
                    }
                  }

                  .grade {
                    &-1 {
                      @include ic(grade_1, 16, 16);
                    }

                    &-2 {
                      @include ic(grade_2, 16, 16);
                    }

                    &-3 {
                      @include ic(grade_3, 16, 16);
                    }

                    &-4 {
                      @include ic(grade_4, 16, 16);
                    }

                    &-5 {
                      @include ic(grade_5, 16, 16);
                    }
                  }
                }

                .rate {
                  margin: 10px 0;
                  font-size: 1.4rem;
                  font-weight: 400;
                  color: $textCol2;
                  background: url(../assets/images/icon/star_fill.svg) no-repeat
                    left center;
                  padding-left: 18px;
                  margin-bottom: 8px;
                }

                .info {
                  font-size: 1.2rem;
                  font-weight: 500;
                  color: $textCol2;
                  width: auto;
                  margin: 0 0 6px 0;
                  margin-bottom: 8px;
                }

                .date {
                  margin-top: 16px;
                  font-size: 1.2rem;
                  font-weight: 400;
                  color: $textCol3;
                  display: flex;
                  align-items: center;

                  .badge {
                    margin-left: 4px !important;
                  }
                }
              }
            }

            @media (max-width: $mo) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &-guide {
    background-color: $colBg;
    padding: 30px;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;

    .tit,
    .sub,
    strong {
      font-weight: 600;
    }

    .tit {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      margin-left: -4px;

      &:after,
      &:before {
        content: "";
        display: inline-block;
        @include ic(check_line, 14, 14);
        background-size: 14px;
        margin: 0 4px;
      }
    }

    .sub {
      margin-bottom: 4px;
    }

    .text {
      &-item {
        padding-left: 16px;
        position: relative;
        word-break: keep-all;

        &:before {
          content: "";
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 3px;
          background-color: $textCol1;
          position: absolute;
          left: 5px;
          top: 8px;
        }
      }
    }
  }

  @media (max-width: $tab) {
    &-con {
      .state-tip {
        margin: 40px 0;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.7;

        strong {
          color: #0a7aff;
          font-weight: 600;
          margin: 0 2px;
        }

        p {
          display: flex;
          align-items: center;

          &:before {
            content: "";
            display: inline-block;
            @include ic(bulb_color, 22, 22);
            margin-right: 6px;
          }
        }
      }

      .floating-btn {
        display: block;
      }
    }

    &-state {
      flex-direction: column;

      .left {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: $mo) {
    &-tit {
      padding: 13px 0 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      // display: block;

      &.mt-40 {
        margin-top: 17px !important;
      }

      &.text {
        padding: 0 0 12px;
      }

      &--sub {
        margin-top: -10px;
        line-height: 1.4;
      }

      img.top {
        top: 0;
      }
    }

    &-con {
      .top-back {
        margin-top: 134px;
      }

      .state-tip {
        margin: 30px 0;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.7;

        strong {
          color: #0a7aff;
          font-weight: 600;
          margin: 0 2px;
        }

        p {
          display: block;
          word-break: keep-all;
          align-items: center;
          position: relative;
          padding-left: 28px;

          &:before {
            content: "";
            display: inline-block;
            @include ic(bulb_color, 22, 22);
            margin-right: 6px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }

    &-tit {
      &.text {
        font-size: 2.4rem;
      }
    }

    &-tab {
      overflow-x: scroll;
      margin: 30px 0 0;
      width: calc(100% + 25px);
      padding-right: 15px;
      margin-bottom: 17px;

      & + .mypage-con {
        .section-tit:first-child {
          padding-top: 13px;
        }
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .btn {
        margin: 0 10px 0 0;
        white-space: nowrap;
        min-width: 116px;

        &.c,
        &.h {
          min-width: 150px;
        }
      }
    }

    &-profile {
      // display: block;

      .left {
        margin-right: 14px;
      }

      .left,
      .right {
        width: 100%;
      }

      @media (max-width: $mo) {
        .left {
          width: fit-content;
        }
      }
    }

    &-state {
      .right {
        .history-list.type2 {
          margin-top: 10px;
        }

        .list-sort {
          margin-bottom: 14px;
        }

        .state-guide {
          padding: 0;

          .btn-lg,
          .box,
          .review-input,
          .review-input + .btn-lg {
            width: 100%;
            height: 50px;
          }

          .info-list {
            width: 100%;
            min-height: 50px;
          }

          .review-input {
            height: auto;
            margin-bottom: 35px;

            .btn-star {
              width: 20px;
              height: 20px;
              background-size: 20px;
            }
          }

          .profile-detail {
            width: 100%;
            left: 0;
          }
        }

        & > .state {
          padding: 30px 25px;
          position: absolute;
          top: 34px;
          left: 0;
          width: 100vw;
          border: none;
          overflow-x: scroll;
          justify-content: flex-start;
          border-bottom: 1px solid $colLine;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          & > div {
            flex-basis: content;
            margin-right: 20px;
            word-break: keep-all;
            display: flex;
            align-items: center;
            white-space: nowrap;
            min-width: fit-content;
            font-size: 1.4rem;

            &:nth-last-child(2) {
              margin-right: 15px;
            }

            &:last-child {
              // width: auto;
              margin: 0;
              padding-left: 15px;

              &:before {
                display: none;
              }
            }

            &:after {
              display: none;
            }

            &:before {
              width: 20px;
              height: 20px;
              min-width: 20px;
              background-image: none;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 20px;
              margin: 0 6px 0 0;
            }

            &.state-1:before,
            &.state-6:before {
              content: "1";
            }

            &.state-2:before,
            &.state-7:before {
              content: "2";
            }

            &.state-3:before {
              content: "3";
            }

            &.state-4:before {
              content: "4";
            }

            &.active:before {
              background-image: none !important;
              width: 16px !important;
              height: 16px !important;
              min-width: 16px;
              margin: 0 8px 0 0;
            }
          }

          &-tip {
            p {
              flex-wrap: wrap;
            }
          }
        }

        .list-wrap {
          flex-direction: column;

          .list {
            margin-bottom: 14px;

            &-tit {
              margin-bottom: 0;
            }

            &-top {
              height: 30px;
              margin: 15px 0;
            }

            &:last-child {
              .list-top {
                display: none;
              }
            }

            .card {
              margin: 8px 0;

              &:last-child {
                margin: 8px 0 0;
              }
            }

            .box.sm {
              width: 16px;
              height: 16px;
              min-width: 16px;
              background-size: 10px;
              margin-top: 0;
              background-position: 50% 49%;

              &:after {
                border-radius: 10px !important;
              }
            }
          }
        }
      }

      .left {
        .tab-con .message-input {
          margin-top: 30px;
        }

        .info-list--item {
          .text {
            margin-top: 4px;

            .badge {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.project-list {
  &--item {
    border: 1px solid $colLine;
    padding: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .top {
      margin-bottom: 24px;

      .badge {
        margin-right: 4px;

        &.done {
          color: #fff;
          background-color: #d6d6d6;
        }
      }

      .right {
        float: right;

        .badge {
          width: 88px;
          text-align: center;
          margin: 0;

          &.auto {
            width: auto;
          }

          &.done {
            background-color: #d6d6d6;
          }
        }
      }
    }

    .tit {
      font-size: 2rem;
      line-height: 1.1;
      font-weight: 600;
      @include text_ellipsis;

      &.type-like {
        text-overflow: unset;
        overflow: visible;

        .text {
          @include text_ellipsis;
          width: auto;
          max-width: calc(100% - 25px);
          display: inline-block;
        }

        .btn-like {
          width: 20px;
          background-size: 20px;
          margin-left: 6px;
        }
      }
    }

    .info {
      margin: 14px 0 12px;
      font-size: 1.6rem;
      font-weight: 500;
      color: $textCol2;

      .text {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-right: 30px;

        .badge {
          margin-left: 10px;

          & + .badge {
            margin-left: 5px;
          }
        }

        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 16px;
          background-color: $colLine;
          position: absolute;
          right: -17px;
          top: 0;
        }

        &:last-child:after {
          display: none;
        }
      }
    }

    .option {
      font-size: 1.2rem;
      color: $textCol2;
      font-weight: 600;
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
        color: $textCol2;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }

      .badge {
        height: 24px;
        line-height: 24px;
        padding: 0 12px;
        font-weight: 600;
        font-size: 1.2rem;
        color: $textCol1;
        margin-right: 3px;

        &.badge-hide:nth-child(n + 4) {
          display: none;
        }

        &.badge-hide-m:nth-child(n + 2) {
          display: none;
        }
      }

      .text {
        font-size: 1.4rem;
        margin: 0 0 0 5px;
        font-weight: 400;
      }
    }

    &.done {
      color: $colGrey;

      .info {
        color: $colGrey;
      }

      .label {
        color: $colGrey;
      }

      .badge {
        background-color: $colGrey;
        color: #fff;

        &.orange,
        &.blue-line {
          border-color: $colGrey;
          color: $colGrey;
          background-color: #fff;
        }
      }

      .option .text {
        color: $colGrey;
      }

      .tit.type-like .btn-like {
        filter: grayscale(1);
        opacity: 0.3;
      }
    }
  }

  &.type2 {
    .project-list--item {
      padding: 28px 24px;

      .tit {
        font-size: 1.8rem;
        position: relative;
        padding: 4px 80px 4px 0;

        .right {
          position: absolute;
          right: 0;
          top: 1px;
        }

        .detail {
          display: flex;
          align-items: center;

          .name {
            font-size: 1.8rem;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
          }

          .grade {
            margin-left: 4px;

            &.type-1 {
              @include ic(grade_1, 16, 16);
            }

            &.type-2 {
              @include ic(grade_2, 16, 16);
            }

            &.type-3 {
              @include ic(grade_3, 16, 16);
            }

            &.type-4 {
              @include ic(grade_4, 16, 16);
            }

            &.type-5 {
              @include ic(grade_5, 16, 16);
            }
          }

          .rate {
            background: url(../assets/images/icon/star_fill.svg) no-repeat left
              center;
            padding-left: 18px;
            font-size: 1.4rem;
            font-weight: 400;
            color: $textCol2;
            vertical-align: text-top;
            margin-left: 20px;
            position: relative;

            &:after {
              content: "";
              display: block;
              width: 1px;
              height: 16px;
              background-color: $colLine;
              position: absolute;
              left: -12px;
              top: 0;
            }
          }
        }
      }

      .info {
        font-size: 1.4rem;
        margin: 8px 0;

        .text .badge {
          margin: 0 5px 0 0;
        }
      }

      .option {
        margin-top: 14px;
        display: block;
        @include text_ellipsis;

        .badge.grey {
          color: $textCol3;
          margin-right: -1px;
        }
      }
    }
  }

  @media (max-width: $tab) {
    &--item {
      padding: 25px;
      border-radius: 10px;

      .info {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: $mo) {
    &--item {
      padding: 25px;

      .top {
        margin-bottom: 10px;
        white-space: nowrap;

        .badge {
          margin-right: 4px;
          font-size: 1.2rem;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin: 16px 0 10px;

        .text {
          margin-bottom: 8px;
          white-space: nowrap;
          display: block;

          .badge {
            margin: 6px 5px 0 0;
          }

          &:after {
            display: none;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .option {
        display: block;

        .text,
        .label {
          margin: 0 5px 0 0;
        }

        .badge {
          margin: 0 5px 4px 0;
        }
      }

      .top {
        .right .badge {
          width: 76px;
        }
      }
    }

    &.type2 {
      .project-list--item {
        .tit {
          .right {
            .badge {
              padding: 0 12px;
            }
          }
        }
      }
    }
  }
}

.project-detail {
  display: flex;
  margin-top: 20px;

  & > .left {
    width: calc(100% - 350px);

    .project-list--item {
      padding: 0;
      border: none;
      cursor: auto;

      .tit {
        font-size: 3rem;
        font-weight: 700;

        &.type-like {
          display: flex;
          align-items: center;

          .btn-like {
            min-width: 24px;
            background-size: 24px;
          }
        }
      }

      .info {
        margin-top: 20px;
        font-size: 1.4rem;

        .text {
          display: flex;
          margin-bottom: 12px;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  & > .right,
  & > .left > .right {
    margin: 40px 0 0 20px;
    width: 330px;

    .right-box {
      background-color: $colBg;
      padding: 10px;
      border-radius: 20px;
      position: sticky;
      top: 110px;

      .box-tit {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 6px 8px 14px;
      }

      .project-list--item {
        background-color: #fff;
        border: none;

        .option .badge {
          margin-right: 4px;
        }
      }
    }
  }

  @media (max-width: $tab) {
    flex-direction: column;

    & > .left {
      width: 100%;
    }

    & > .right {
      width: 100%;
      margin: 20px 0 0;
    }
  }

  @media (max-width: $mo) {
    & > .left {
      .project-list--item {
        .top {
          margin-bottom: 24px;

          .right {
            float: unset;
            margin-top: 5px;
          }
        }

        .tit {
          font-size: 2.4rem;
        }

        .info {
          .text {
            display: block;

            &:last-child {
              margin-bottom: 0;
            }

            br ~ .badge {
              margin: 10px 5px 0 0;
            }
          }
        }
      }
    }

    & > .left > .right {
      margin: 50px 0 0 -25px;
      padding: 0 16px;
      overflow-x: scroll;
      width: calc(100vw);
      z-index: 22;

      .right-box {
        width: fit-content;

        .project-list {
          display: flex;

          &--item {
            margin-bottom: 0;
            margin-right: 10px;
            padding: 24px;
            width: 309px;

            &:last-child {
              margin-right: 0;
            }

            .tit {
              font-size: 1.8rem;

              & + .info {
                margin-top: 7px;
              }
            }

            .info {
              font-size: 1.4rem;
              margin-top: 8px;

              .text {
                margin-bottom: 0;
              }
            }

            .option {
              margin-top: 14px;

              .badge {
                margin: 0 4px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

.profile {
  &-top {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 18px 12px;
    margin-bottom: 40px;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      @include ic(notice_line, 30, 30);
      margin-right: 8px;
      min-width: 30px;
    }

    .text {
      @include text_ellipsis;
      width: auto;
      max-width: calc(100% - 40px);
      display: inline-block;
    }
  }

  &-word {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  &-info {
    display: flex;
    position: relative;

    .image {
      width: 158px;
      height: 158px;
      min-width: 158px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 5px;
      margin-right: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      font-size: 1.4rem;

      p {
        margin-bottom: 13px;

        .badge {
          margin-right: 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .badges {
        display: flex;
        align-items: center;

        .badge {
          margin-right: 4px;
        }
      }

      .name {
        margin: 14px 0 20px;
        font-size: 3rem;
        font-weight: 700;
        color: #000;
        display: flex;
        align-items: center;
      }

      .grade {
        min-width: 26px;
        background-size: 100%;
        margin-left: 6px;

        &.type {
          &-1 {
            @include ic(grade_1_fill, 26, 26);
          }

          &-2 {
            @include ic(grade_2_fill, 26, 26);
          }

          &-3 {
            @include ic(grade_3_fill, 26, 26);
          }

          &-4 {
            @include ic(grade_4_fill, 26, 26);
          }

          &-5 {
            @include ic(grade_5_fill, 26, 26);
          }
        }
      }

      .rate {
        margin-left: 10px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        border-left: 1px solid $colLine;

        .star {
          @include ic(star_line, 24, 24);
          margin-right: 5px;
          background-size: 100%;
          background-position: bottom;

          &.fill {
            @include ic(star_fill, 24, 24);
          }

          &.half {
            @include ic(star_half, 24, 24);
          }
        }

        .num {
          font-size: 2rem;
          font-weight: 600;
          color: $textCol2;
        }
      }
    }
  }

  &-sub {
    margin: 40px 0 16px;
    font-size: 1.2rem;
    color: $colSub;
    font-weight: 400;

    .right {
      float: right;

      .text {
        color: $textCol1;
      }

      .input-switch {
        position: relative;
        top: 1px;
      }
    }
  }

  &-badges {
    .row {
      display: flex;
    }

    .label {
      min-width: 60px;
      font-size: 1.2rem;
      margin: 6px 10px 0 0;
    }

    .badges {
      .badge {
        margin-bottom: 6px;
      }
    }
  }

  &-box {
    border: 1px solid $colLine;
    border-radius: 5px;
    padding: 22px;
    font-size: 1.4rem;
    line-height: 1.4;
    word-break: break-all;
    font-weight: 400;
    white-space: pre-line;

    & + .btn-full {
      margin-top: 16px;
    }
  }

  &-history {
    &--item {
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      // word-break: keep-all;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .left {
        min-width: 200px;
        margin-right: 20px;

        .name {
          font-size: 1.6rem;
          font-weight: 600;
        }

        .team {
          font-size: 1.2rem;
          color: $textCol2;
        }

        .date {
          font-size: 1.2rem;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }
  }

  &-detail {
    border-top: 1px solid #000;
    margin-top: 40px;

    .col-wrap {
      display: flex;

      .col {
        flex: 1;

        &:last-child {
          border-left: 1px solid $colLine;
          padding-left: 24px;
        }

        .row {
          margin-bottom: 26px;
          font-size: 1.4rem;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          text-align: left;

          .badge {
            margin-left: 4px;
          }

          .label {
            display: inline-block;
            font-weight: 600;
            width: 86px;
            min-width: 86px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .btn-full {
            margin-top: 0;
            height: 24px;
            font-size: 1.2rem;
            position: relative;
            top: -6px;
          }

          .a-link {
            color: #0a7aff;
            text-decoration: underline;
            text-underline-offset: 2px;
          }
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-word {
      margin-bottom: 30px;
    }

    &-box {
      padding: 24px;
    }

    &-badges {
      margin-right: -25px;
    }

    &-sub {
      margin: 30px 0 16px;

      &.type2 {
        margin: 40px 0 23px;

        .right {
          position: relative;
          top: -8px;
        }
      }
    }

    &-top {
      margin-bottom: 30px;
      padding: 14px 12px;
    }

    &-info {
      flex-direction: column;

      .image {
        margin-bottom: 20px;
      }

      .text {
        .name {
          font-size: 2.4rem;
          margin: 14px 0;
        }

        .grade {
          width: 20px !important;
          height: 20px !important;
          background-size: 20px;
          margin-left: 4px;
        }

        .rate {
          .num {
            font-size: 1.8rem;
          }

          .star,
          .star.fill {
            width: 26px;
            height: 26px;
            margin-right: 4px;
          }
        }

        .badges {
          .badge {
            margin-right: 4px;
          }
        }

        p {
          margin-bottom: 12px;
        }
      }
    }

    &-history {
      &--item {
        flex-direction: column;

        .left {
          margin-bottom: 14px;
        }
      }
    }

    &-detail {
      margin-top: 30px;

      .col-wrap {
        flex-direction: column;

        .col:last-child {
          padding-left: 0;
          border: none;
          margin-top: 14px;
        }

        .col .row {
          margin-bottom: 14px;

          .btn-full {
            height: 50px;
            top: 0;
            font-size: 1.4rem;
          }
        }
      }
    }

    &-badges {
      .row {
        .badges {
          display: flex;
          overflow: auto;

          .badge {
            padding: 0 12px;
          }
        }
      }
    }
  }
}

.message {
  &-wrap {
    border: 1px solid $colLine;
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;

    .box {
      max-width: 400px;
      width: fit-content;
      padding: 12px 18px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      word-break: keep-all;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
      }
    }

    .date {
      font-size: 1.2rem;
      font-weight: 400;
      color: $textCol3;
      margin-top: 6px;
    }
  }

  &-scroll {
    height: 100%;
    overflow-y: auto;
  }

  &-left,
  &-right {
    margin-bottom: 20px;
    width: 100%;

    .box {
      white-space: pre-wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-left {
    padding-left: 10px;
    clear: both;

    .box {
      background-color: $colBg;

      &:after {
        border-bottom: 10px solid transparent;
        border-top: 10px solid $colBg;
        border-right: 0 solid $colBg;
        border-left: 10px solid transparent;
        left: -9px;
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: right;
    padding-right: 10px;

    .box {
      background-color: $colPrimary;
      text-align: right;

      &:after {
        border-bottom: 10px solid transparent;
        border-top: 10px solid $colPrimary;
        border-right: 10px solid transparent;
        border-left: 0 solid $colPrimary;
        right: -8px;
      }
    }

    .date {
      text-align: right;
    }
  }

  @media (max-width: $mo) {
    &-left .box,
    &-right .box {
      width: 100%;
    }
  }
}

.percent {
  &-wrap {
    .top {
      font-size: 1.6rem;
      color: $textCol3;
      font-weight: 600;
      margin-bottom: 4px;

      strong {
        font-size: 1.8rem;
        color: #000;
      }
    }

    .percent {
      display: flex;
      align-items: center;
      position: relative;

      .bar {
        display: inline-block;
        background-color: #5712eb;
        height: 24px;
        border-radius: 20px;

        &-wrap {
          position: relative;
          width: 500px;
          height: 24px;
          border-radius: 20px;
          background-color: $colBg;
        }
      }

      .num {
        margin-left: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        color: $textCol3;

        &.active {
          color: #5712eb;

          strong {
            color: #5712eb;
          }
        }

        strong {
          font-size: 2.4rem;
          font-weight: 900;
          color: $textCol3;
        }
      }

      .btn-history {
        border: 1px solid #5712eb;
        color: #5712eb;
        height: 24px;
        position: absolute;
        right: 0;

        &:before {
          content: "";
          display: inline-block;
          @include ic(history_line_blue, 16, 16);
          margin-right: 6px;
        }
      }
    }
  }

  @media (max-width: $tab) {
    &-wrap {
      .percent {
        .bar-wrap {
          width: calc(100% - 200px);
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-wrap {
      margin-top: 10px;

      .percent {
        position: relative;

        .bar-wrap {
          width: calc(100% - 50px);
          height: 14px;

          .bar {
            height: 14px;
          }
        }

        .num {
          position: absolute;
          right: 48px;
          top: -24px;
          font-size: 1.4rem;

          strong {
            font-size: 2rem;
          }
        }

        .btn-history {
          text-indent: -9999px;
          overflow: hidden;
          width: 38px;
          min-width: 38px;
          height: 38px;
          top: -24px;

          &:before {
            margin-right: 0;
            width: 20px;
            min-width: 20px;
            height: 20px;
            background-size: 100%;
          }
        }
      }
    }
  }
}

.history-list {
  margin-top: 40px;

  &--top {
    display: flex;
    position: relative;
    margin-top: 20px;

    .text {
      height: 30px;
      line-height: 28px;
      font-size: 1.4rem;
      margin: 0 6px;
      display: inline-block;
    }

    .input-select {
      width: auto;
      height: 28px;

      &.first {
        margin-right: 40px;
        position: relative;
        overflow: visible;

        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 28px;
          position: absolute;
          right: -20px;
          top: 0;
          background-color: $colLine;
        }
      }
    }

    .input-text {
      width: 185px;
      height: 30px;
    }

    .radio-list {
      display: flex;
      margin-left: 5px;

      .input-radio {
        input:checked + .box {
          background-color: $colSub2;
          color: #000;
          border-color: $colSub2;
        }

        .box {
          font-size: 1.4rem;
          font-weight: 400;
          color: $textCol3;
          height: 30px;
          background-color: #fff;
          border: 1px solid $colLine;
          border-radius: 0;
          border-left: none;
        }

        &:first-child {
          .box {
            border-left: 1px solid $colLine;
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          .box {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }

    .right {
      position: absolute;
      right: 0;
    }
  }

  &.type2 {
    .history-list--item {
      & > div {
        &:nth-child(1) {
          width: 9%;
        }

        &:nth-child(2) {
          width: 36%;
        }

        &:nth-child(4) {
          width: 18%;
        }

        &:nth-child(5) {
          width: 7%;
        }

        &:nth-child(6) {
          width: 17%;
        }
      }

      &.entry {
        margin-top: -1px;
        background-color: #fff;
        flex-direction: column;
        height: auto;
        padding-top: 0;

        .row {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0 0 0 72px;
          height: 50px;

          .label {
            min-width: 70px;
            max-width: 70px;
            margin-right: 20px;
          }

          a {
            font-size: 1.4rem;
            text-decoration: underline;
            text-underline-offset: 2px;
            max-width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1.5;
          }

          .btn-sm {
            width: 68px;
            margin-left: 10px;
          }

          .input-text {
            width: 310px;
          }
        }
      }
    }
  }

  &.type3 {
    margin-top: 0;

    .history-list--item {
      justify-content: space-evenly;

      &.top {
        padding-bottom: 0;
      }

      & > div {
        width: 12%;
        padding: 0;

        &:nth-child(1) {
          width: 77px;
          min-width: 77px;
        }

        &:nth-child(2) {
          width: 107px;
          min-width: 107px;
        }

        &:nth-child(3) {
          width: 170px;
          min-width: 170px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 94px;
          min-width: 94px;
        }

        &:nth-child(6),
        &:nth-child(8) {
          width: 110px;
          min-width: 110px;
        }

        &:nth-child(7) {
          width: 106px;
          min-width: 106px;
        }

        &:nth-child(9),
        &:nth-child(10) {
          width: 80px;
          min-width: 80px;
        }

        &.strong {
          font-weight: 500;
        }

        .sort {
          margin-left: 6px;

          &.des {
            @include ic(arrow_bottom_fill, 8, 7);
            background-size: 100%;
          }

          &.asc {
            @include ic(arrow_top_fill, 8, 7);
            background-size: 100%;
          }
        }
      }
    }
  }

  &--item {
    display: flex;
    align-items: center;
    padding: 16px 10px;
    border-bottom: 1px solid $colLine;
    height: 61px;

    & > div {
      width: 14%;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 0 10px;

      &.tit {
        @include text_ellipsis;
        font-size: 1.4rem;
        font-weight: 600;
      }

      &:nth-child(1) {
        width: 11%;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.text-right {
        text-align: right;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 13%;
      }

      strong {
        font-size: 1.4rem;
        font-weight: 600;
        color: #5712eb;
      }

      .btn-list {
        @include ic(doc_down_blue, 24, 24);
        border: 1px solid #5712eb;
        border-radius: 5px;
      }
    }

    &.top {
      border-bottom: none;

      & > div {
        color: $textCol3;
      }
    }

    &.active > div {
      color: #0a7aff;
    }

    &.stroke > div {
      color: $colGrey;
      text-decoration: line-through;
    }
  }

  &--bottom {
    margin: 16px 10px;
  }

  @media (max-width: $mo) {
    overflow-x: auto;
    white-space: nowrap;
    width: calc(100% + 25px);
    padding-right: 25px;
    margin-top: 30px;

    &--top {
      overflow-x: scroll;
      overflow-y: visible;
      white-space: nowrap;
      width: calc(100% + 25px);
      padding-right: 25px;

      &.dropdown {
        overflow: visible;
        flex-wrap: wrap;

        .custom-select {
          margin-bottom: 10px;
        }

        .radio-list {
          width: calc(100% - 38px);

          .input-radio {
            width: 25%;
          }
        }

        .btn-sm {
          width: 28px;
          height: 28px;
          padding: 0;
        }
      }

      .custom-select {
        min-width: 100px !important;
        margin-right: 5px;
        height: 30px;

        &:first-child {
          margin-right: 40px;
        }
      }

      .input-select.m-margin {
        margin-right: 100px;
        margin-bottom: 10px;

        &::after {
          display: none;
        }
      }

      .input-date {
        width: 150px;
      }

      .radio-list {
        margin: 10px 150px 0 0;
      }

      .right {
        position: relative;
        margin-left: 10px;

        .btn-down {
          text-indent: -9999px;
          overflow: hidden;

          &:before {
            margin-right: 0;
          }
        }
      }
    }

    &--bottom {
      margin: 16px 0;
    }

    &--item {
      width: max-content;

      &.top {
        padding: 0 10px;
        height: 30px;
      }

      & > div {
        &:nth-child(1),
        &:nth-child(6),
        &:nth-child(3),
        &:nth-child(7) {
          width: 80px;
        }

        &:nth-child(2) {
          width: 150px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 100px;
        }
      }
    }

    &.type2 {
      .history-list--item {
        border-bottom: none;
        border-top: 1px solid $colLine;

        & > div {
          &:nth-child(1) {
            width: 80px;
          }

          &:nth-child(2) {
            width: 300px;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 80px;
          }

          &:last-child {
            width: 100px;
          }
        }

        &.entry,
        &.top {
          border: none;
        }

        &.entry {
          .row {
            padding: 0;
            height: 30px;

            .input-text {
              height: 28px;
            }
          }
        }
      }
    }

    &.type3 {
      padding-top: 15px;

      .history-list--item {
        & > div {
          &:nth-child(1),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(9) {
            width: 80px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 150px;
          }

          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            width: 100px;
          }

          &:nth-child(10) {
            width: 60px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    &--top {
      .radio-list {
        margin: 0;
      }
    }
  }
}

.text-history {
  max-height: 460px;
  overflow-y: auto;
  padding: 0 20px 0 10px;

  &--item {
    font-size: 1.2rem;
    font-weight: 400;
    color: $textCol2;
    display: flex;
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid $colLine;

    &:first-child {
      padding-top: 20px;
    }

    .badge {
      margin-right: 8px;
    }

    .text {
      .top {
        font-size: 1.4rem;
        color: $textCol1;
        font-weight: 500;
      }

      .row {
        margin-top: 8px;

        &.stroke {
          text-decoration: line-through;
          color: $textCol3;
        }

        .label {
          font-weight: 500;
          color: $textCol2;
          width: 60px;
          display: inline-block;
        }
      }
    }

    .date {
      position: absolute;
      right: 0;
      bottom: 30px;
      color: $textCol3;
    }
  }

  @media (max-width: $mo) {
    padding: 0;
    max-height: calc(100% - 40px);

    &--item {
      padding: 20px 0 48px;

      .date {
        bottom: 20px;
      }

      .badge {
        width: 18px;
        min-width: 18px;
        height: 18px;
        background-size: 18px;
      }
    }
  }
}

.survey-list {
  margin-top: 40px;

  &--item {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    border-bottom: 1px solid $colLine;
    justify-content: space-between;

    .num {
      display: inline-block;
      font-weight: 600;
      width: 30px;
    }

    .check {
      .box.text {
        width: 94px;
        height: 28px;
        border-color: $colGrey;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 17px;
        color: $colGrey;
        background: url(../assets/images/icon/check_grey.svg) no-repeat 33% 50%;
      }

      input:checked + .box.text {
        color: #5712eb;
        border-color: #5712eb;
        background: url(../assets/images/icon/check_blue.svg) no-repeat 33% 50%;
      }
    }
  }

  @media (max-width: $mo) {
    margin-top: 0;

    &--item {
      display: block;
      height: auto;
      padding: 16px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border: none;
        padding-bottom: 10px;
      }

      .q {
        line-height: 1.5;
        position: relative;
        padding-left: 30px;

        .num {
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .check {
        margin-top: 16px;

        .input-checkbox {
          width: 100%;
        }

        .box.text {
          width: 100%;
          height: 40px;
          margin: 0;
          background-position: calc(50% - 15px) center !important;
          text-indent: -10px;
        }
      }
    }
  }
}

.calculate {
  &-top {
    display: flex;
    margin-top: 20px;

    & > .left,
    & > .right {
      flex: 1;
      border: 1px solid $colLine;
      border-radius: 20px;
      padding: 30px;
    }

    & > .right {
      margin-left: 10px;
    }

    .tit {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;

      .tooltip {
        margin-left: 4px;
      }
    }

    .date {
      color: $textCol3;
      font-size: 1.2rem;
      margin-top: 10px;
      height: 12px;
    }

    .num {
      text-align: right;
      font-size: 3rem;
      font-weight: 700;
      margin-top: 10px;
      color: #000;

      .text {
        font-size: 1.4rem;
        font-weight: 600;
        color: $textCol2;
        margin-left: 4px;
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      flex-direction: column;

      & > .right {
        margin: 10px 0 0 0;
      }
    }
  }
}

.quit-list {
  display: flex;
  flex-direction: column;
  margin: 20px 0 60px;
  align-items: flex-start;

  &--box {
    padding: 18px 14px;
    font-size: 1.8rem;
    font-weight: 600;
    background-color: $colBg;
    border-radius: 10px;
    margin-bottom: 16px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .box {
      &:after {
        content: "";
        display: block;
        position: relative;
        z-index: 1;
        top: -2px;
        @include ic(check_round_off, 18, 18);
      }

      background-size: 100%;
      border: none;
      background-color: transparent;
      margin-right: 2px;
    }

    .text {
      font-size: 1.8rem;
      font-weight: 600;
      color: $textCol1;
      position: relative;
      z-index: 1;
    }

    input:checked + .box {
      background-size: 100%;
      background-color: transparent;

      &:after {
        @include ic(check_round, 18, 18);
        top: -3px;
      }

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $colPrimary;
        border-radius: 10px;
      }
    }
  }

  @media (max-width: $mo) {
    margin: 30px 0 60px;

    &--box {
      font-size: 1.6rem;
      margin-bottom: 12px;
      padding: 18px;

      .text {
        font-size: 1.6rem;
      }

      .box {
        height: 15px;
      }
    }
  }
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .input-checkbox {
    margin: 8px 0 10px;

    input:checked {
      & + .box {
        @include ic(check_active, 15, 15);
        min-width: 15px;
        background-color: transparent;
        background-size: 100%;
        border: none;

        @media (max-width: $mo) {
          min-width: 18px;
          height: 18px;
        }
      }

      & ~ .text {
        color: $colPrimary;
      }
    }

    .box {
      @include ic(check_active_off, 15, 15);
      min-width: 15px;
      border: none;
      border-radius: 10px;
      background-size: 100%;
      background-color: transparent;

      @media (max-width: $mo) {
        min-width: 18px;
        height: 18px;
      }
    }

    .text {
      font-size: 1.4rem;
      color: $textCol3;
      font-weight: 600;
      white-space: nowrap;
      margin-left: 7px;

      @media (max-width: $mo) {
        font-size: 1.6rem;
      }
    }
  }

  .input-textarea {
    background-color: transparent;
    color: #fff;
  }

  &.type2 {
    flex-direction: row;

    .input-checkbox {
      margin-right: 16px;
    }
  }

  @media (max-width: $mo) {
    &.type2 {
      flex-wrap: wrap;
    }
  }
}

.register {
  &-top {
    text-align: center;
    margin-top: 100px;
    font-size: 1.8rem;
    color: $textCol2;

    .tit {
      font-size: 2.4rem;
      margin-bottom: 12px;
      color: $textCol1;

      > .btn-close {
        display: block;
        position: absolute;
        right: 30px;
        top: 0;
        @include ic(close, 20, 20);
        background-size: 100%;
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0;

    .btn {
      width: 330px;
      height: 300px;
      background-color: #000;
      flex-direction: column;
      color: $colBg;
      padding: 25px;
      word-break: keep-all;
      line-height: 1.5;
      position: relative;
      top: 0;
      // transition: all .3s;

      // &:hover {
      //     animation: bounce_top 1.5s infinite linear;

      //     @keyframes bounce_top {
      //         0% {
      //             top: 0;
      //         }

      //         20% {
      //             top: -10px;
      //         }

      //         50% {
      //             top: -20px;
      //         }

      //         70% {
      //             top: -10px;
      //         }

      //         100% {
      //             top: 0;
      //         }
      //     }
      // }

      &:first-child {
        margin-right: 20px;
      }

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-bottom: 30px solid transparent;
        border-top: 0 solid #000;
        border-right: 30px solid transparent;
        border-left: 30px solid #000;
        position: absolute;
        right: -30px;
        bottom: -30px;
      }

      .tit {
        font-size: 2.4rem;
        font-weight: 600;
        color: $colPrimary;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: $mo) {
    width: 100%;
    height: 100%;
    position: fixed;
    padding-top: 20px;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 999;

    &-top {
      margin-top: 20px;
      font-size: 1.4rem;

      .tit {
        font-size: 1.8rem;
        position: relative;
      }
    }

    &-btn {
      flex-direction: column;
      height: calc(100% - 144px);
      margin: 50px 0 94px 0;

      .btn {
        &:first-child {
          margin-right: 0;
          margin-bottom: 34px;
        }

        height: 50%;
        padding: 25px 20px;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-bottom: 20px solid transparent;
          border-top: 0 solid #000;
          border-right: 20px solid transparent;
          border-left: 20px solid #000;
          position: absolute;
          right: -20px;
          bottom: -20px;
        }
      }
    }
  }
}

.search {
  &-top {
    margin: 60px 0 30px;

    .count {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 20px;

      .right {
        float: right;

        &.sort-btn {
          .btn {
            margin-left: 24px;
            position: relative;

            &:after {
              content: "";
              display: block;
              width: 1px;
              height: 12px;
              background-color: $colLine;
              position: absolute;
              left: -15px;
              top: 3px;
            }

            &:first-child:after {
              display: none;
            }

            &.active {
              color: #000;
              font-weight: 600;
            }
          }
        }
      }
    }

    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .select-multi,
        .input-select {
          height: 36px;
          margin-right: 10px;
        }
      }

      .right {
        .input-wrap.search {
          width: 350px;
          position: relative;

          .btn-search {
            @include ic(search_line_grey, 16, 16);
            position: absolute;
            left: 16px;
            top: calc(50% - 8px);
            background-size: 14px;
          }

          .input-text {
            height: 36px;
            padding-left: 40px;
          }
        }
      }
    }
  }

  &-none {
    padding: 120px 0;
    text-align: center;

    .icon {
      @include ic(search_circle_lg, 74, 74);
      margin: 0 auto 40px;
    }

    p {
      color: $textCol2;
      margin: 10px 0;
      font-size: 1.4rem;

      &.strong {
        color: $textCol1;
        font-weight: 500;
        font-size: 1.6rem;
      }
    }

    .btn-lg {
      margin-top: 30px;
    }
  }

  @media (max-width: $tab) {
    &-top {
      .input .right .input-wrap.search {
        width: 250px;
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      margin: 30px 0 16px;

      .m-search-guide {
        width: 100%;
        display: flex;
        margin-top: 4px;
      }

      .count {
        margin-bottom: 14px;
      }

      .m-search-guide {
        width: 100%;
        display: flex;
        margin-top: 4px;
      }

      .input {
        flex-direction: column;
        align-items: flex-start;

        .left {
          flex-wrap: wrap;

          &.type-col2 {
            .select-multi:first-child {
              min-width: calc(50% - 5px) !important;
              width: calc(50% - 5px);
              margin-right: 10px;
            }

            .select-multi:nth-child(2) {
              min-width: calc(50% - 5px) !important;
              width: calc(50% - 5px);
            }
          }

          .select-multi {
            margin-bottom: 10px;
            margin-right: 0;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .custom-select {
            width: 104px;
            min-width: unset !important;
            margin-right: 4px;

            &.m-full {
              width: 100%;
              margin-right: 0;
            }
          }

          .input-wrap {
            max-width: unset;
            width: calc(100% - 108px);

            .input-text {
              height: 36px;
              padding: 0 8px 0 28px;
            }

            .btn-search {
              @include ic(search_line_grey, 16, 16);
              position: absolute;
              left: 12px;
              top: calc(50% - 8px);
              background-size: 14px;
            }
          }

          .input-select {
            margin-bottom: 5px;
            margin-right: 0;
          }
        }

        .right {
          margin-top: 10px;
          width: 100%;

          &.m-mt-14 {
            margin-top: 14px;
          }

          .input-wrap,
          .input-wrap .input-text {
            width: 100% !important;
          }
        }
      }

      .count:not(.basic) {
        .right {
          float: none;
          margin-top: 10px;

          &.sort-btn {
            .btn {
              margin: 0 24px 10px 0;
            }
          }
        }
      }
    }

    &-none {
      padding: 0;
    }
  }
}

.qna {
  &-list {
    &--item {
      &:first-child .q {
        padding-top: 0;
      }

      .q {
        display: flex;
        align-items: center;
        padding: 17px 0;

        .badge-sm {
          min-width: 57px;
          padding: 0 6px;
        }

        .tit {
          @include text_ellipsis;
          display: block;
          margin-right: 10px;
          font-size: 1.4rem;
          font-weight: 400;
          text-align: left;
        }

        .btn {
          margin-left: 10px;

          &-a,
          &-delete,
          &-modi {
            color: $colSub;
            text-decoration: underline;
            white-space: nowrap;
            font-size: 1.2rem;
          }

          &-a {
            color: $colSub;
          }

          &-delete {
            color: $textCol3;
          }

          &-modi {
            color: #0a7aff;
          }

          &-report {
            @include ic(siren_line_grey, 16, 16);
            min-width: 30px;
          }
        }

        .name {
          @include text_ellipsis;
          min-width: 60px;
          width: 60px;
          font-size: 1.2rem;
          margin-left: 10px;
          font-weight: 400;
          text-align: right;
        }

        .date {
          font-size: 1.2rem;
          white-space: nowrap;
          margin-left: 10px;
          font-weight: 400;
          min-width: 104px;
          width: 104px;
          text-align: right;
        }

        &-detail {
          font-size: 1.4rem;
          font-weight: 400;
          word-break: break-all;
          margin-left: 67px;
          border-radius: 5px;
          line-height: 1.4;
          overflow-y: auto;
          transition: all 0.3s;
          transform: scaleY(0);
          transform-origin: top;
          max-height: 1px;
          padding: 0 18px;
          background-color: #fff;
          white-space: pre-line;
        }

        &.active {
          & + .q-detail {
            transform: scaleY(1);
            max-height: 100px;
            padding: 18px;
            background-color: $colBg;

            & + .a {
              // display: block;
              padding: 18px;
              transform: scaleY(1);
              height: fit-content;
            }
          }

          & ~ .a {
            margin-top: 10px;
          }
        }
      }

      .a {
        // display: none;
        padding: 0;
        height: 0;
        transform-origin: top;
        transition: all 0.3s;
        transform: scaleY(0);
        margin-left: 93px;
        border: 1px solid $colLine;

        border-radius: 5px;
        position: relative;

        &:after {
          content: "";
          display: block;
          @include ic(re, 20, 20);
          position: absolute;
          left: -25px;
          top: 18px;
        }

        .input-textarea {
          border: none;
          padding: 0;
          background-color: transparent;
        }

        .text {
          font-size: 1.4rem;
          line-height: 1.4;
          font-weight: 400;
          word-break: keep-all;
          max-height: 100px;
          overflow: auto;
        }

        &.readonly {
          background-color: $colBg;
          border: none;
        }

        .btns {
          text-align: right;
          margin-top: 10px;

          .btn {
            margin-left: 6px;
            font-size: 1.2rem;
          }

          .date {
            margin-left: 10px;
            font-weight: 400;
            color: $textCol3;
          }
        }
      }
    }
  }

  &-input {
    width: 100%;
    max-width: unset;
    margin-bottom: 30px;

    .textarea-wrap {
      border: 1px solid $colLine;
      padding: 18px;
      border-radius: 5px;
      position: relative;

      .input-textarea {
        border: none;
        padding: 0;
        background-color: transparent;
      }

      .btns {
        text-align: right;
        margin-top: 10px;

        .btn {
          margin-left: 6px;
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-list {
      &--item {
        .q-detail {
          margin-left: 0;
        }

        .a {
          margin-left: 26px;
        }

        .q {
          position: relative;
          padding-bottom: 40px;

          .btn-a {
            width: 15%;
          }

          .name {
            width: 20%;
            flex-grow: 1;
          }
        }

        .tit {
          position: absolute;
          left: 0;
          bottom: 15px;
          margin: 0 !important;
        }
      }
    }
  }
}

// request-top
.request {
  &-top {
    margin-bottom: 50px;
    padding: 50px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #000;
    color: #fff;
    line-height: 1.3;
    font-size: 1.6rem;

    .content-wrapper {
      text-align: left;
      position: relative;
      width: $pc;
      height: 100%;
    }

    strong {
      font-size: 2.4rem;
      font-weight: 700;
      color: #fff;
    }

    p {
      margin-top: 16px;
      line-height: 1.4;
      color: $colGrey;
      font-size: 1.8rem;
    }

    .input-checkbox {
      background-color: $colPrimary;
      border-radius: 5px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 18px 28px;
      width: 300px;
      height: 50px;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .box {
        &:after {
          content: "";
          display: block;
          position: relative;
          z-index: 1;
          top: -2px;
          @include ic(_icon16_arrow_R, 10, 16);
          background-size: 10px;
        }

        background-image: none !important;
        background-size: 100%;
        border: none;
        background-color: transparent;
        // margin-right:2px;
      }

      .text {
        font-size: 1.4rem;
        font-weight: 600;
        color: $textCol1;
        position: relative;
        z-index: 1;
        margin-left: 0px;
        margin-right: 6.5px;
      }

      input:checked + .box {
        background-size: 100%;
        background-color: transparent;

        &:after {
          @include ic(_icon16_arrow_R, 10, 16);
          top: -2px;
          background-size: 10px;
        }

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $colPrimary;
          border-radius: 50px;
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      padding: 50px 25px;
      margin-bottom: 30px;

      .content-wrapper {
        margin-left: 0;
        text-align: left;
        position: unset;
      }

      strong {
        font-size: 2rem;
      }

      p {
        font-size: 14px;
        line-height: 23px;
      }

      .input-checkbox {
        white-space: nowrap;
        padding: 18px 28px;
        width: 100%;
        border-radius: 5px;
        background-color: $colPrimary;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.heroes {
  &-list {
    display: flex;
    flex-wrap: wrap;

    &--item {
      border: 1px solid $colLine;
      border-radius: 20px;
      padding: 40px;
      flex: 1;
      min-width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      height: 204px;
      margin: 0 20px 20px 0;
      cursor: pointer;
      position: relative;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &.heroes-page {
        height: 268px;
      }

      .btn-like {
        position: absolute;
        right: 40px;
        top: 40px;
      }

      .top {
        margin-bottom: 20px;
      }

      .info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .name {
          font-size: 1.8rem;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
        }

        .grade {
          margin-left: 4px;

          &.type-1 {
            @include ic(grade_1, 16, 16);
          }

          &.type-2 {
            @include ic(grade_2, 16, 16);
          }

          &.type-3 {
            @include ic(grade_3, 16, 16);
          }

          &.type-4 {
            @include ic(grade_4, 16, 16);
          }

          &.type-5 {
            @include ic(grade_5, 16, 16);
          }
        }

        .rate {
          background: url(../assets/images/icon/star_fill.svg) no-repeat left
            center / auto 100%;
          padding-left: 18px;
          font-size: 1.4rem;
          font-weight: 400;
          color: $textCol2;
          vertical-align: text-top;
          margin-left: 20px;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 16px;
            background-color: $colLine;
            position: absolute;
            left: -12px;
            top: 0;
          }

          &.no-score {
            display: none;
          }
        }
      }

      .info2 {
        margin-bottom: 12px;
        font-size: 1.6rem;
        color: $textCol2;

        .text {
          margin-right: 30px;
          position: relative;

          .badge {
            margin-right: 2px;
            vertical-align: bottom;

            &:first-child {
              margin-left: 6px;
            }
          }

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 16px;
            background-color: $colLine;
            position: absolute;
            right: -18px;
            top: 0;
          }

          &:last-child:after {
            display: none;
          }
        }
      }

      .title {
        font-size: 2rem;
        font-weight: 600;
        @include text_ellipsis;
        padding-right: 40px;
        margin-bottom: 20px;
        text-align: left;

        &.null {
          margin-bottom: 0;
        }
      }

      .tag {
        display: flex;
        align-items: center;
        margin-top: 6px;

        .label {
          font-size: 1.2rem;
          font-weight: 600;
          color: $textCol2;
          min-width: 70px;
          text-align: left;
        }

        .badges {
          display: flex;
          align-items: center;
          height: 24px;
          overflow-x: auto;
          overflow-y: hidden;
          padding-right: 40px;
          margin-right: -40px;

          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .badges {
          .badge-hide:nth-child(n + 3) {
            display: none;
          }

          .badge-hide-m:nth-child(n + 2) {
            display: none;
          }

          .text {
            font-size: 1.2rem;
            margin: 0 0 0 5px;
            font-weight: 400;
          }
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-list {
      &--item {
        min-width: unset;
        max-width: unset;
        width: 100%;
        height: 172px;
        padding: 25px;
        margin: 0 0 10px 0;

        &.heroes-page {
          height: 280px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .info {
          margin-bottom: 14px;
        }

        .btn-like {
          top: 20px;
          right: 20px;
        }

        .title {
          margin-bottom: 16px;
        }

        .tag {
          margin-top: 4px;
        }

        .info2 {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .text:first-child {
            margin-bottom: 8px;

            &::after {
              display: none;
            }
          }

          .text {
            display: flex;
            align-items: center;
            height: 16px;
            color: #333;

            &.column {
              display: block;
              height: auto;

              .badge {
                margin-top: 6px;

                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }

          .label {
            color: #999;
            font-size: 1.2rem;
            width: 62px;
            min-width: 62px;
            display: inline-block;

            &.div {
              margin-left: 20px;
              width: 40px;
              min-width: 40px;
              position: relative;

              &:after {
                content: "";
                display: block;
                width: 1px;
                height: 16px;
                background-color: $colLine;
                position: absolute;
                left: -10px;
                top: -2px;
              }
            }

            & + .badge {
              margin-left: 6px;
            }
          }

          .badge {
            margin-right: 5px !important;
          }
        }

        .tag {
          .label {
            color: #999;
          }

          .badges {
            overflow: auto;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          }
        }
      }
    }
  }
}

.review {
  &-list {
    &--item {
      background-color: $colBg;
      padding: 18px;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .top {
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .left {
          display: flex;
          align-items: center;

          .rate {
            margin: -2px 0 0 10px;
            display: flex;
            align-items: center;

            .star {
              @include ic(star_line, 12, 12);
              margin-right: 2px;
              background-size: 100%;
              background-position: bottom;

              &.fill {
                @include ic(star_fill, 12, 12);
              }

              &.half {
                @include ic(star_half, 12, 12);
              }
            }
          }
        }

        .right {
          font-size: 1.2rem;
          color: $textCol2;
          font-weight: 400;

          .btn {
            font-size: 1.2rem;
            color: $textCol3;
            margin-right: 4px;
          }
        }
      }

      .con {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.5;
        word-break: keep-all;
      }

      .bottom {
        margin-top: 20px;

        .text {
          margin-right: 22px;
          font-size: 1.2rem;
          color: $textCol2;
          position: relative;

          strong {
            color: $textCol2;
            margin-right: 2px;
          }

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 14px;
            background-color: $colGrey;
            position: absolute;
            right: -11px;
            top: 0;
          }

          &:last-child:after {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-list {
      &--item {
        .top {
          margin-bottom: 14px;

          .left {
            .name {
              max-width: 50px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .rate {
              .star,
              .star.fill {
                width: 16px;
                height: 16px;
              }
            }
          }

          .right {
            text-align: right;
            width: 100%;
          }
        }

        .bottom {
          .text {
            display: block;
            margin-bottom: 6px;
            font-weight: 400;

            strong {
              font-weight: 600;
            }

            &:after {
              display: none;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.community {
  display: flex;
  margin-top: 20px;

  &-write {
    width: 100%;

    .input-wrap {
      max-width: unset;
    }
  }

  &-left {
    width: calc(100% - 290px);
  }

  &-right {
    margin: -65px 0 0 20px;
    width: 270px;

    .btn-lg {
      width: 100%;
    }

    .right-box {
      position: sticky;
      top: 110px;
    }
  }

  &-tit {
    margin-bottom: 20px;

    img {
      position: relative;
      top: 4px;
    }

    .btn {
      font-size: 2rem;
      font-weight: 800;
      margin-left: 4px;

      span {
        margin-left: 4px;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      @include ic(arrow_right, 20, 14);
    }
  }

  &-sub {
    margin: -15px 0 20px;
    font-size: 1.4rem;
    font-weight: 400;
  }

  &-ranking {
    margin-top: 40px;
    padding: 0 20px;

    .top {
      background: url(../assets/images/icon/rank_color.svg) no-repeat left
        center;
      height: 30px;
      margin-bottom: 20px;
      padding-left: 30px;
      font-size: 1.6rem;
      font-weight: 600;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .date {
        font-size: 1.2rem;
        font-weight: 400;
        color: $textCol2;
      }
    }

    .list {
      &-item {
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 16px;

        .num {
          font-weight: 600;
          width: 20px;
          margin-right: 6px;
        }

        .grade {
          display: block;
          margin-left: 6px;

          &.type {
            &-1 {
              @include ic(grade_1, 16, 16);
            }

            &-2 {
              @include ic(grade_2, 16, 16);
            }

            &-3 {
              @include ic(grade_3, 16, 16);
            }

            &-4 {
              @include ic(grade_4, 16, 16);
            }

            &-5 {
              @include ic(grade_5, 16, 16);
            }
          }
        }

        &.no_change {
          &:before {
            content: "";
            display: flex;
            align-items: center;
            width: 30px;
            height: 16px;
            margin-right: 6px;
          }
        }

        &.up,
        &.down,
        &.new,
        &.none {
          &:before {
            content: "";
            display: flex;
            align-items: center;
            width: 30px;
            height: 16px;
            background: url(../assets/images/icon/rank_up.svg) no-repeat left
              center;
            margin-right: 6px;
          }
        }

        &.down {
          &:before {
            background: url(../assets/images/icon/rank_down.svg) no-repeat left
              center;
          }
        }

        &.new {
          &:before {
            background: none;
            content: "NEW";
            color: #df2e47;
            font-size: 1rem;
            font-weight: 600;
          }
        }

        &.no_change {
          &:before {
            background: none;
            content: "-";
            color: #999;
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  &-card {
    display: flex;
    flex-wrap: wrap;

    &--item {
      width: calc(50% - 5px);
      margin: 0 10px 10px 0;
      border: 1px solid $colLine;
      padding: 30px;
      border-radius: 5px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .con {
        width: 100%;
        height: 30px;
        display: block;
        text-align: left;
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0 10px;
        border-radius: 5px;

        .tit {
          @include text_ellipsis;
          width: fit-content;
          max-width: 170px;
          display: inline-block;
          vertical-align: middle;
          margin-top: -1px;
        }

        .count {
          color: $colSub;
        }

        .date {
          float: right;
          font-size: 1.2rem;
          font-weight: 400;
          color: $textCol2;
          margin-top: 2px;
        }

        &:hover {
          background-color: $colBg;
        }
      }
    }

    &--tit {
      font-size: 1.6rem;
      color: #0a7aff;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .btn {
        font-size: 1.2rem;
        color: $textCol3;
      }
    }
  }

  &-story {
    & > .tit {
      font-size: 1.6rem;
      color: #0a7aff;
      font-weight: 800;
    }

    .list {
      margin-bottom: 40px;

      &-item {
        display: flex;
        padding: 40px 0;
        border-bottom: 1px solid $colLine;
        cursor: pointer;
        align-items: center;

        .img-wrap {
          width: 100px;
          min-width: 100px;
          height: 100px;
          border-radius: 50px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;

          img {
            width: 100%;
          }
        }

        .info {
          width: calc(100% - 130px);

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hash {
              margin-left: 10px;
              font-size: 1.2rem;

              &:before {
                content: "#";
                margin-right: 1px;
              }
            }

            .right {
              display: flex;
              align-items: center;
            }

            .view {
              display: flex;
              align-items: center;
              font-size: 1.2rem;
              font-weight: 600;
              margin-right: 20px;

              &:before {
                content: "";
                display: block;
                margin-right: 4px;
                @include ic(view, 16, 16);
                filter: brightness(0);
              }
            }

            .date {
              font-size: 1.2rem;
              color: $textCol2;
              font-weight: 400;
            }
          }

          .tit {
            margin: 14px 0 10px;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.3;
          }

          .bottom {
            font-size: 1.6rem;
            color: $textCol2;
          }
        }
      }
    }

    &--bottom {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        border: 1px solid $colLine;
        border-radius: 30px;
        padding: 13px 20px;
        display: flex;
        align-items: center;

        .heart,
        .like,
        .pro {
          display: flex;
          align-items: center;
          margin-right: 15px;
          font-size: 1.2rem;
          font-weight: 600;

          &:last-child {
            margin-right: 2px;
          }

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
          }
        }

        .heart:before {
          @include ic(heart_line_sm, 16, 16);
        }

        .like:before {
          @include ic(like, 16, 16);
        }

        .pro:before {
          @include ic(pro_line, 16, 16);
        }

        .heart.active:before {
          @include ic(heart_fill, 16, 16);
          background-size: 15px;
        }

        .like.active:before {
          @include ic(like_color, 16, 16);
        }

        .pro.active:before {
          @include ic(pro_color, 16, 16);
        }
      }

      .date {
        font-size: 1.2rem;
        font-weight: 400;
        color: $textCol2;
      }
    }
  }

  &-search {
    position: relative;
    padding: 80px 0;
    margin-bottom: 60px;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 200%;
      height: 1px;
      position: absolute;
      left: -50%;
      bottom: 0;
      background-color: $colLine;
      z-index: -1;
    }

    &:after {
      bottom: unset;
      top: 0;
    }

    &--tit {
      padding: 0 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 40px;

      .btn {
        font-size: 1.2rem;
        font-weight: 400;
        color: $textCol3;
      }
    }

    .list {
      margin-bottom: 80px;
      padding: 0 80px 80px;
      border-bottom: 1px solid $colLine;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      &-item {
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 30px;
        display: flex;
        cursor: pointer;
        position: relative;
        line-height: 1.4;

        .left,
        .right {
          white-space: nowrap;
          flex-basis: 0;
          flex-grow: 0;
        }

        .left {
          font-weight: 600;
          position: relative;

          &:after {
            content: "｜";
            display: inline-block;
          }
        }

        .right {
          font-size: 1.2rem;
          color: $textCol2;
          // position: absolute;
          right: 0;
        }

        .center {
          margin: 0 10px;
          color: $textCol2;
          min-width: calc(100% - 193px);
          max-width: 100%;
          flex-basis: 0;
          flex-grow: 10;

          @media (max-width: $mo) {
            min-width: calc(100% - 193px);
            max-width: 100%;
          }

          .top {
            font-weight: 600;
            @include text_ellipsis;
          }

          .bottom {
            margin-top: 10px;
            @include text_ellipsis;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--guide {
      text-align: center;
      padding: 50px 0 80px;
      font-size: 2.4rem;
      font-weight: 700;
      color: #000;
    }

    &--none {
      padding: 88px 0 88px;
      font-size: 2.4rem;
      font-weight: 700;
      color: $textCol3;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 60px;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 200%;
        height: 1px;
        position: absolute;
        left: -50%;
        bottom: 0;
        background-color: $colLine;
        z-index: -1;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        color: $colGrey;
        margin-top: 10px;
      }
    }
  }

  &-category {
    &--top {
      margin-top: -10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-checkbox {
        margin-right: 6px;

        input:checked + .label {
          background-color: #000;
          color: $colPrimary;
        }

        .label {
          height: 36px;
          line-height: 36px;
        }
      }

      .input-select {
        height: 36px;
      }
    }
  }

  &-list {
    margin-top: 30px;

    &--tit {
      font-size: 1.6rem;
      font-weight: 600;
      margin-top: 100px;
    }

    &--item {
      padding: 34px 40px;
      border: 1px solid $colLine;
      border-radius: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $colBg;
        border-color: $colBg;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid $colLine;

        .power {
          margin-right: 30px;
        }

        .hash {
          margin-left: 10px;
          font-size: 1.2rem;

          &:before {
            content: "#";
            margin-right: 1px;
          }
        }
      }

      .con {
        .name {
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .text {
            @include text_ellipsis;
            display: inline-block;
            width: auto;
          }

          .grade {
            display: inline-flex;
            padding-left: 20px;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
            margin-left: 6px;
            background-position: left center !important;
            white-space: nowrap;

            &.type {
              &-1 {
                @include ic(grade_1, auto, 16);
                color: $textCol3;
              }

              &-2 {
                @include ic(grade_2, auto, 16);
                color: #d2632a;
              }

              &-3 {
                @include ic(grade_3, auto, 16);
                color: #0aa7ff;
              }

              &-4 {
                @include ic(grade_4, auto, 16);
                color: #ffbc27;
              }

              &-5 {
                @include ic(grade_5, auto, 16);
                color: #0bb899;
              }
            }
          }
        }

        .tit {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .con {
          font-size: 1.4rem;
          color: $textCol2;
          @include text_ellipsis;
          margin-bottom: 20px;
          line-height: normal;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        .view {
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-weight: 600;

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
            @include ic(view, 16, 16);
            filter: brightness(0);
          }
        }

        .comment {
          display: flex;
          align-items: center;
          margin-right: 30px;
          font-weight: 600;

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
            @include ic(comment_line, 15, 12);
          }
        }

        .date {
          color: $textCol2;
          position: relative;
          font-weight: 400;

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 14px;
            background-color: $colLine;
            position: absolute;
            left: -15px;
            top: 0;
          }
        }
      }
    }

    &.type2 {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 660px) {
        display: flex;
      }
      .community-list--item {
        //width: calc(100% / 3 - 40px / 3);
        //margin: 0 20px 20px 0;
        margin: 0;
        padding: 30px;
        border-radius: 10px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .top {
          border-bottom: none;
          margin-bottom: 0;
        }

        .tit {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 16px;
          line-height: 1.2;
          @include text_ellipsis_multi(3, 23);
        }

        .con {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .left {
            width: 80px;
            min-width: 80px;
            height: 80px;
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            img {
              width: 100%;
            }
          }

          .right {
            .tag {
              font-size: 1.2rem;
            }

            .name {
              font-size: 1.4rem;
              color: #000;
              margin: 6px 0 10px;
            }

            .hash {
              display: flex;
              flex-wrap: wrap;

              span {
                font-size: 1.2rem;
                color: $textCol3;
                margin-right: 8px;
                white-space: nowrap;
                line-height: 1.3;

                &:before {
                  content: "#";
                }
              }
            }
          }
        }

        .bottom {
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            font-weight: 600;

            .like {
              display: flex;
              align-items: center;
              margin-right: 10px;

              &:before {
                content: "";
                display: block;
                margin-right: 4px;
                @include ic(heart_line_sm, 16, 16);
                background-size: 15px;
                filter: brightness(0);
              }
            }
          }

          .date {
            color: $textCol3;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.type3 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .community-list--item {
        width: calc(100% / 3 - 40px / 3);
        margin: 0 19px 19px 0;
        padding: 30px;
        border-radius: 10px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .top {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 10px;

          .badge {
            margin-right: 2px;
          }
        }

        .tit {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 16px;
          @include text_ellipsis_multi(3, 23);
          line-height: 1.2;
        }

        .con {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          .left {
            .tag {
              font-size: 1.2rem;
            }

            .name {
              font-size: 1.8rem;
              font-weight: 600;
              color: #000;
              margin: 6px 0 2px;
            }
          }

          .right {
            width: 80px;
            min-width: 98px;
            height: 98px;
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
            }
          }
        }

        .bottom {
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            font-weight: 600;

            .like {
              display: flex;
              align-items: center;
              margin-right: 10px;

              &:before {
                content: "";
                display: block;
                margin-right: 4px;
                @include ic(heart_line_sm, 16, 16);
                background-size: 15px;
                filter: brightness(0);
              }
            }
          }

          .date {
            color: $textCol3;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.detail {
      .community-list--item {
        padding: 0;
        border: none;
        border-bottom: 1px solid $colLine;
        border-radius: 0;
        padding-bottom: 40px;
        cursor: auto;

        &:hover {
          background-color: transparent;
        }

        .top {
          position: relative;
          padding-bottom: 20px;

          .right {
            position: absolute;
            right: 0;
            top: 5px;

            .more {
              @include ic(meatball, 20, 20);
              transform: rotate(90deg);
            }

            .menu-wrap {
              display: flex;
              flex-direction: column;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
              width: 98px;
              border-radius: 5px;
              position: absolute;
              right: 10px;
              top: 30px;
              z-index: -1;
              opacity: 0;
              overflow: hidden;

              .btn {
                padding: 13px 20px;
                width: 98px;
                font-size: 1.4rem;
                background-color: #fff;

                &:hover {
                  background-color: $colBg;
                }

                &:active {
                  background-color: $colGrey;
                }
              }

              &.active {
                z-index: 1;
                opacity: 1;
                transition: opacity 0.3s;
              }
            }
          }
        }

        .con {
          position: relative;

          .con {
            text-overflow: unset;
            white-space: pre-wrap;
            line-height: 1.5;
            word-break: keep-all;
          }

          .btn-more {
            width: 100%;
            height: 30px;
            background-color: $colBg;
            position: absolute;
            left: 0;
            bottom: -35px;
            border-radius: 5px;
            font-size: 1.2rem;
            font-weight: 600;
            color: $textCol3;

            & + .con {
              max-height: 190px;
              margin-bottom: 55px;
            }
          }
        }
      }
    }
  }

  &-other {
    margin-top: 40px;

    & > .tit {
      font-size: 1.6rem;
      padding-bottom: 22px;
      margin-bottom: 22px;
      border-bottom: 1px solid $colLine;

      .btn {
        font-size: 1.6rem;
        font-weight: 800;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }

    .list {
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid $colLine;

        .tit {
          font-size: 1.4rem;
          font-weight: 600;
          text-align: left;
          @include text_ellipsis;
          display: block;
        }

        .date {
          font-size: 1.2rem;
          font-weight: 400;
          min-width: 70px;
          white-space: nowrap;
          margin-left: 10px;
        }
      }
    }
  }

  @media (max-width: $tab) {
    flex-direction: column;

    &-left {
      width: 100%;
    }

    &-right {
      width: 100%;
      margin: 20px 0 0 0;
    }
  }

  @media (max-width: $mo) {
    margin-top: -17px;

    &-card {
      &--item {
        width: 100%;
        margin: 0 0 10px 0;
        padding: 30px;

        .con {
          padding: 0;

          & + .con {
            margin-top: 6px;
          }

          .count {
            margin-left: 2px;
          }
        }
      }
    }

    &-story {
      .list-item {
        // flex-direction: column;
        align-items: flex-start;

        .img-wrap {
          margin: 0 30px 0 0;
        }

        .info {
          width: 100%;
          position: relative;
          padding-bottom: 32px;

          .top {
            .right {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }

          .tit {
            margin: 16px 0 10px;
            @include text_ellipsis_multi(2, 25);
            height: auto;
            max-height: 50px;
          }
        }
      }
    }

    &-search {
      padding: 40px 0;

      &--tit {
        padding: 0;
      }

      .list {
        padding: 0 0 40px;
        margin-bottom: 40px;
      }
    }

    &-category {
      &--top {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;

        &.type-flex {
          flex-direction: row;

          .right {
            margin-top: 0;
          }

          .left + .input {
            margin-left: 4px;
          }
        }

        &.ju-s {
          justify-content: flex-start;

          .right {
            margin-left: 4px;
          }
        }

        .left {
          .input-checkbox {
            margin: 0 4px 10px 0;
          }
        }
      }
    }

    &-list {
      &--item {
        padding: 20px;

        .top {
          flex-wrap: wrap;

          .badge.power {
            margin-right: calc(100% - 150px);
            margin-bottom: 10px;
          }
        }

        .con {
          .tit {
            @include text_ellipsis_multi(2, 25);
            height: auto;
            max-height: 50px;
          }

          .con {
            @include text_ellipsis_multi(3, 27);
            height: auto;
            max-height: 52px;
            white-space: normal;
            line-height: 1.3;
          }
        }
      }

      &.type2,
      &.type3 {
        .community-list--item {
          width: 100%;
          margin: 0 0 20px 0;
          padding: 30px;

          &:last-child {
            margin: 0 0 30px 0;
          }

          .top {
            padding-bottom: 25px;
          }
        }
      }

      &.detail {
        margin-top: 20px;

        .community-list--item {
          .top {
            flex-direction: column;
            align-items: flex-start;

            .hash {
              margin: 14px 0 0 0;
            }
          }

          .con {
            .name {
              margin-bottom: 22px;
            }

            .tit,
            .con {
              display: block;
              max-height: unset;
            }
          }
        }
      }
    }

    &-tit {
      &.mt-100 {
        margin-top: 60px !important;
      }
    }

    &-ranking {
      background: #f6f6f6;
      padding: 14px 19px 20px;
      border-radius: 20px;
      margin: 60px 0 60px;

      .top {
        margin: 0 2px 20px;
      }

      .list {
        width: 50%;
        display: inline-block;
        vertical-align: top;
      }

      .list-item {
        .num {
          min-width: 20px;
        }

        .text {
          @include text_ellipsis;
        }

        &:before {
          min-width: 30px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-other {
      margin-top: 60px;
    }
  }
}

.store {
  &-top {
    height: 770px;
    background: url(../assets/images/store_bg.svg) no-repeat center;
    position: relative;
    width: calc(100% + 400px);
    left: -200px;
    padding: 40px 200px 0;

    &:before {
      content: "";
      display: block;
      width: 200%;
      height: 770px;
      position: absolute;
      left: -50%;
      top: 0;
      background-color: #000;
      z-index: -1;
    }

    .top-btn {
      .btn {
        color: #fff;
        border: 1px solid #0bb899;
      }
    }

    .list-top {
      margin: 60px 0 20px;
      font-size: 2.4rem;
      color: #fff;
      font-weight: 700;
    }

    .thumb-list {
      &--item {
        border: 1px solid $colLine;
        border-radius: 21px;

        .text-wrap {
          border: none;

          .top {
            color: $colGrey;

            .badge {
              color: #000;
            }
          }

          .tit {
            color: #fff;
          }

          .rate .count {
            color: $colGrey !important;
          }
        }
      }
    }

    .center {
      text-align: center;
      margin-top: 80px;
      font-size: 3rem;
      font-weight: 600;
      color: $colPrimary;

      .accent {
        height: 60px;
        padding: 0 25px;
        line-height: 60px;
        font-size: 3.4rem;
        font-weight: 800;
        color: #000;
        background-color: $colPrimary;
        border-radius: 20px;
        width: fit-content;
        margin: 10px auto 0;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    &--item {
      margin: 0 20px 20px 0;
      width: calc(50% - 10px);
      border: 1px solid $colLine;
      border-radius: 20px;
      padding: 30px;
      display: flex;
      cursor: pointer;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .img-wrap {
        width: 150px;
        min-width: 150px;
        height: 150px;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colBg;
        margin-right: 30px;

        img {
          width: 100%;
        }
      }

      .txt-wrap {
        .top {
          margin-bottom: 16px;
        }

        .name {
          margin: 0 0 10px;
          font-size: 1.8rem;
          color: $textCol2;
        }

        .tit {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .rate {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .btn {
            margin-right: 1px;
          }

          .count {
            font-size: 1.4rem;
            color: $textCol2;
            margin-left: 2px;
          }
        }

        .badge {
          margin: 0 5px 5px 0;
        }

        .badge-hide:nth-child(n + 3) {
          display: none;
        }

        .badge-hide-m:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }

  &-detail {
    display: flex;
    margin-top: 20px;

    & > .left {
      width: calc(100% - 350px);
      margin-bottom: 60px;

      &.wd-100 {
        width: 100%;

        .btn-send {
          margin: 40px auto;

          &:before {
            content: "";
            display: block;
            @include ic(send_line, 16, 16);
            margin-right: 6px;
          }
        }
      }

      .image-wrap {
        width: 100%;
        // height: 353px;
        aspect-ratio: 1.75 / 1;
        // height: auto;
        border-radius: 20px;
        overflow: hidden;

        &.type2 {
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .store-list--item {
        border: none;
        padding: 0;
        margin-top: 20px;
        cursor: auto;

        .name {
          font-size: 2.4rem;
        }

        .tit {
          font-size: 3rem;
          font-weight: 700;
        }

        .rate {
          .btn-star {
            width: 24px;
            height: 24px;
            cursor: default;
            margin-right: 2px;
          }

          .count {
            font-size: 2rem;
            font-weight: 600;
            margin-left: 6px;
          }
        }
      }
    }

    & > .right {
      margin: 0 0 0 20px;
      width: 330px;

      .right-box {
        position: sticky;
        top: 110px;

        .btn-primary {
          width: 100%;
          height: 44px;

          &:before {
            content: "";
            display: block;
            @include ic(send_line, 16, 16);
            margin-right: 6px;
          }
        }

        .box-tit {
          font-size: 1.6rem;
          font-weight: 600;
          margin: 30px 0 10px;
        }

        .thumb-list {
          flex-direction: column;

          &--item {
            width: 100%;

            .badges {
              margin-top: 8px;

              .badge {
                margin: 0 5px 5px 0;
              }
            }
          }
        }
      }

      @media (max-width: $mo) {
        margin: 50px 0 0 -25px !important;
        padding: 0 16px;
        overflow-x: scroll;
        width: 100vw !important;

        .right-box {
          background-color: #f6f6f6;
          padding: 10px;
          border-radius: 20px;
          position: sticky;
          top: 110px;
          width: fit-content;

          .box-tit {
            font-size: 1.6rem;
            font-weight: 600;
            margin: 6px 8px 14px;
          }

          .thumb-list {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            &--item {
              width: 330px;
              margin: 0;
              margin-right: 10px;

              .image-wrap {
                height: 177px !important;
              }

              &:last-child {
                margin-right: 0;
              }

              .text-wrap {
                background-color: #fff;
              }
            }
          }
        }
      }
    }

    &--box {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  @media (max-width: $pc) {
    &-top {
      width: calc(100% + 50px);
      left: -25px;
      height: auto;
      padding: 100px 20px;
      background: #000;

      &:before {
        display: none;
      }
    }
  }

  @media (max-width: $tab) {
    &-top {
      .thumb-list--item {
        width: calc(50% - 25px);

        // &:nth-child(3) {
        //     display: none;
        // }
      }
    }

    &-detail {
      flex-direction: column;

      & > .left {
        width: 100%;
        margin: 0;

        .btn-send {
          margin: 30px auto 50px !important;
        }
      }

      & > .right {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      padding: 50px 20px;

      .thumb-list--item {
        width: 100%;
        margin: 0 0 10px 0;

        &:last-child {
          margin: 0;
        }

        .text-wrap {
          padding: 20px 30px 30px !important;
        }
      }

      .list-top {
        margin: 10px 0 20px;
        font-size: 2rem;
      }

      .center {
        margin: 60px 0 10px;
        font-size: 2.4rem;

        .accent {
          font-size: 3rem;
          font-weight: 700;
          height: 56px;
          line-height: 56px;
        }
      }
    }

    &-list {
      &--item {
        width: 100%;
        margin: 0 0 20px 0;
        padding: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        .txt-wrap .top {
          .badge {
            margin: 0 4px 0 0;
          }
        }

        .img-wrap {
          min-width: 80px;
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
      }
    }
  }
}

.edit-box {
  margin-top: 16px;
  // height: 500px;
  // background-color: #F6F6F6;
  border-radius: 5px;
  // padding: 18px;
  font-size: 1.4rem;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: $mo) {
    margin-top: 20px;
  }
}

.comment {
  &-list {
    &.re {
      margin: 16px -20px 0 40px;

      .comment-list--item {
        padding: 18px 0;

        &:last-child {
          border-bottom: none;
        }

        &.write {
          border: 1px solid $colLine;
          padding: 18px 20px;
        }
      }
    }

    &--item {
      padding: 18px 20px;
      position: relative;
      border-bottom: 1px solid $colLine;

      &.write {
        border: 1px solid $colLine;
        border-radius: 5px;
        margin-bottom: 30px;

        .input-textarea {
          border: none;
          padding: 0;
        }

        .count {
          font-size: 1.2rem;
          font-weight: 400;
          color: $textCol3;
          position: absolute;
          left: 0;
          bottom: -20px;
        }
      }

      &.choice {
        border: 1px solid $colPrimary;
        border-radius: 5px;

        .right-top {
          top: 55px;
        }
      }

      .badges {
        margin-bottom: 14px;
      }

      .right-top {
        position: absolute;
        right: 14px;
        top: 18px;

        .btn-more {
          @include ic(meatball, 16, 16);
          background-size: 12px;
          transform: rotate(90deg);
          filter: invert(0.5);
        }

        .menu-wrap {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
          width: 98px;
          border-radius: 5px;
          position: absolute;
          right: 10px;
          top: 20px;
          z-index: -1;
          opacity: 0;
          overflow: hidden;

          .btn {
            padding: 13px 20px;
            width: 98px;
            font-size: 1.4rem;
            background-color: #fff;

            &:hover {
              background-color: $colBg;
            }

            &:active {
              background-color: $colGrey;
            }
          }

          &.active {
            z-index: 1;
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }

      .name {
        font-size: 1.6rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .text {
          @include text_ellipsis;
          max-width: 120px;
          width: unset;
        }

        .grade {
          display: inline-flex;
          padding-left: 20px;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 600;
          margin-left: 6px;
          background-position: left center !important;

          &.type {
            &-1 {
              @include ic(grade_1, auto, 16);
              color: $textCol3;
            }

            &-2 {
              @include ic(grade_2, auto, 16);
              color: #d2632a;
            }

            &-3 {
              @include ic(grade_3, auto, 16);
              color: #0aa7ff;
            }

            &-4 {
              @include ic(grade_4, auto, 16);
              color: #ffbc27;
            }

            &-5 {
              @include ic(grade_5, auto, 16);
              color: #0bb899;
            }
          }
        }

        .date {
          font-size: 1.2rem;
          font-weight: 400;
          color: $textCol3;
          margin-left: 12px;
        }
      }

      .con {
        font-size: 1.4rem;
        font-weight: 400;
        color: $textCol2;
        line-height: 1.4;
      }

      .img-wrap {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 10px 0;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
        }

        &:hover {
          .img-dim {
            opacity: 1;
          }
        }

        .img-dim {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(51, 51, 51, 0.4);
          backdrop-filter: blur(2px);
          opacity: 0;
          transition: opacity 0.3s;

          .btn-delete {
            @include ic(close_sm, 17, 17);
            border-radius: 10px;
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
      }

      .register-comment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        .photo {
          cursor: pointer;

          input {
            appearance: none;
            width: 0;
            height: 0;
            position: absolute;
            display: none;
          }

          .icon {
            display: block;
            @include ic(camera_line, 20, 14);
            background-size: 100%;
            filter: invert(1);
          }
        }

        .right {
          .btn {
            font-size: 1.4rem;
            font-weight: 600;
            margin-left: 14px;
          }
        }
      }

      .bottom {
        margin-top: 14px;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;
        position: relative;

        .btn {
          font-weight: 600;
        }

        .comment {
          &-more {
            margin-right: 14px;

            &.active:before {
              transform: rotate(0);
            }

            &:before {
              content: "";
              display: inline-block;
              @include ic(arrow_select_sm, 20, 20);
              transform: rotate(-90deg);
            }
          }

          &-write {
            &:before {
              content: "";
              display: inline-block;
              @include ic(re, 20, 20);
              filter: invert(1);
              background-position: center 60%;
            }

            &.type2:before {
              background: none;
              content: "@";
              filter: none;
              height: 18px;
            }
          }
        }

        .state {
          margin-left: 30px;
          position: relative;
          display: flex;
          align-items: center;

          &:before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: $colLine;
            position: absolute;
            left: -14px;
            top: 0;
          }

          .heart,
          .like,
          .pro,
          .question {
            display: flex;
            align-items: center;
            margin-right: 15px;
            font-size: 1.2rem;
            font-weight: 600;

            &:last-child {
              margin-right: 2px;
            }

            &:before {
              content: "";
              display: block;
              margin-right: 4px;
            }
          }

          .heart:before {
            @include ic(heart_line_sm, 16, 16);
          }

          .like:before {
            @include ic(like, 16, 16);
          }

          .pro:before {
            @include ic(pro_line, 16, 16);
          }

          .question:before {
            @include ic(question, 16, 16);
          }

          .heart.active:before {
            @include ic(heart_fill, 16, 16);
            background-size: 15px;
          }

          .like.active:before {
            @include ic(like_color, 16, 16);
          }

          .pro.active:before {
            @include ic(pro_color, 16, 16);
          }

          .question.active:before {
            @include ic(question_color, 16, 16);
          }
        }

        .btn-choice {
          position: absolute;
          right: 0;
          top: 2px;

          &:before {
            content: "";
            display: block;
            margin-right: 4px;
            @include ic(check_round, 17, 17);
          }
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-list {
      &.re {
        margin: 16px -20px 0 20px;

        .bottom {
          margin-right: 20px;
        }
      }

      &--item {
        .bottom {
          flex-wrap: wrap;

          .state {
            width: 100%;
            margin-top: 10px;
            justify-content: flex-end;

            &:before {
              display: none;
            }
          }

          &.type-mo {
            flex-wrap: nowrap;
            white-space: nowrap;

            .state {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

.consulting {
  &-top {
    position: relative;
    padding: 100px 0;
    height: 827px;
    margin: 60px 0 40px;

    &:before {
      content: "";
      display: block;
      width: 100vw;
      height: 827px;
      position: absolute;
      left: calc(50% - 50vw);
      top: 0;
      background-color: #000;
      z-index: -1;
    }

    & > .top {
      font-size: 5rem;
      font-weight: 300;
      color: #fff;
      line-height: 1.2;
      margin-bottom: 100px;

      strong {
        font-weight: 700;
        color: #fff;
      }

      p {
        font-size: 2.4rem;
        font-weight: 700;
        margin-top: 16px;
      }
    }

    .list {
      display: flex;
      margin-bottom: 40px;

      &-item {
        position: relative;
        width: 110px;
        text-align: center;
        margin-right: 54px;

        .step {
          font-size: 1.2rem;
          font-weight: 400;
          color: $textCol3;
          margin-bottom: 4px;
        }

        .name {
          color: #fff;
          font-size: 1.6rem;
          font-weight: 600;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: -35px;
          top: calc(50% - 10px);
          @include ic(arrow_right_primary, 20, 20);
        }

        &:last-child:after {
          display: none;
        }

        .icon {
          background-color: $colPrimary;
          border-radius: 40px;
          margin: 0 auto 10px;

          &-1 {
            @include ic(consulting_step1, 74, 74);
          }

          &-2 {
            @include ic(consulting_step2, 74, 74);
          }

          &-3 {
            @include ic(consulting_step3, 74, 74);
          }

          &-4 {
            @include ic(consulting_step4, 74, 74);
          }

          &-5 {
            @include ic(consulting_step5, 74, 74);
          }
        }
      }
    }

    & > .box {
      display: flex;
      align-items: flex-start;
      padding: 50px;
      width: 100%;
      border: 1px solid $textCol1;
      border-radius: 20px;

      .top {
        font-size: 1.2rem;
        font-weight: 400;
        color: $textCol3;
        margin-bottom: 16px;
      }

      .left {
        margin-right: 50px;

        .tit {
          font-size: 2.4rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.3;
        }

        .name {
          color: $textCol3;
          font-size: 1.4rem;
          font-weight: 600;
          margin-top: 10px;
        }
      }

      .right {
        .price {
          display: flex;

          .box {
            flex: 1;
            background-color: #43f4ff;
            color: #333333;
            padding: 10px 20px;
            display: flex;
            align-items: flex-end;
            border-radius: 10px;
            white-space: nowrap;
            font-size: 1.4rem;
            font-weight: 600;

            &:first-child {
              margin-right: 12px;
            }

            strong {
              font-size: 3rem;
              font-weight: 700;
              color: #000;
              margin-right: 10px;
              letter-spacing: -1px;
            }
          }
        }

        .tip {
          background: url(../assets/images/icon/bulb_color.svg) no-repeat left
            center;
          font-size: 1.4rem;
          font-weight: 600;
          color: $textCol3;
          padding-left: 20px;
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: $pc) {
    &-top {
      width: calc(100% + 50px);
      left: -25px;
      height: auto;
      padding: 100px 20px;
      background-color: #000;

      &:before {
        display: none;
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      padding: 60px 25px;
      margin: 30px 0 40px;

      & ~ .community-list {
        &--tit {
          margin-top: 60px;
        }

        &.type3 {
          .community-list--item {
            &:last-child {
              margin-bottom: 20px;
            }
          }

          .community-list--item .tit {
            height: auto;
            max-height: 69px;
            margin-bottom: 40px;
          }

          .community-list--item .top {
            padding-bottom: 12px;

            .badge {
              background-color: $colPrimary;
            }
          }
        }
      }

      .top {
        font-size: 3.7rem;
        margin-bottom: 60px;
        line-height: 1.3;

        p {
          font-size: 2.4rem;
          margin-top: 20px;
        }
      }

      .list {
        flex-direction: column;

        &-item {
          margin-right: 20px;
          width: 100%;
          text-align: left;
          padding: 20px 0 0 94px;
          height: 74px;
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 0;
          }

          &:after {
            transform: rotate(90deg);
            right: unset;
            left: 26px;
            top: 88px;
          }

          .icon {
            position: absolute;
            left: 0;
            top: 0;
          }

          .name {
            word-break: keep-all;
            line-height: 1.2;
            font-size: 1.8rem;
          }
        }
      }

      .box {
        padding: 30px;
        flex-direction: column;

        .left {
          margin: 0 0 32px 0;

          .tit {
            font-size: 2rem;
          }
        }

        .right {
          .tip {
            font-size: 1.2rem;
            padding: 2px 0 2px 20px;
            margin-top: 9px;
          }

          .price {
            .box {
              flex-direction: row;
              align-items: center;
              padding: 11px 14px;

              strong {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}

.customer {
  &-top {
    margin-top: 40px;

    .tit {
      font-size: 3rem;
      font-weight: 700;
      color: $textCol1;
      margin-bottom: 30px;
    }

    &--btn {
      display: flex;

      .btn {
        flex: 1;
        height: 210px;
        border: 1px solid $colLine;
        border-radius: 10px;
        margin-right: 20px;
        font-size: 2.4rem;
        font-weight: 700;
        color: $textCol1;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .icon {
          border-radius: 40px;
          margin-bottom: 20px;

          &-1 {
            @include ic(customer_icon1, 74, 74);
          }

          &-2 {
            @include ic(customer_icon2, 74, 74);
          }

          &-3 {
            @include ic(customer_icon3, 74, 74);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      .tit {
        font-size: 2rem;
        margin-bottom: 20px;
      }

      .btn {
        height: fit-content;
        font-size: 1.6rem;
        margin-right: 5px;
        padding: 20px 0;

        .icon {
          width: 40px;
          height: 40px;
          background-size: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.board {
  &-list {
    margin-top: 21px;

    &--item {
      padding: 13px 0;
      display: flex;
      align-items: center;

      .badge-wrap {
        width: 100px;
      }

      .tit {
        @include text_ellipsis;
        font-size: 1.4rem;
        font-weight: 400;
        color: $textCol1;
        display: block;
        width: calc(100% - 200px);
        text-align: left;
      }

      .date {
        font-size: 1.2rem;
        font-weight: 400;
        width: 100px;
        text-align: right;
      }
    }
  }

  &-detail {
    margin: 20px 0 30px;

    .top {
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 10px;

      .badge {
        margin-right: 10px;
      }
    }

    .tit {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .con {
      padding: 30px 0;
      border-top: 1px solid $colLine;
      border-bottom: 1px solid $colLine;
      font-size: 1.4rem;
      font-weight: 400;
      color: $textCol1;
      line-height: 1.5;
    }
  }

  &-bottom {
    padding-bottom: 50px;

    & > div {
      display: flex;

      & > .btn {
        width: 50px;
        margin-right: 50px;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 1.4rem;

        &.prev {
          background: url(../assets/images/icon/arrow_up.svg) no-repeat right
            center;
        }

        &.next {
          background: url(../assets/images/icon/arrow_down.svg) no-repeat right
            center;
        }
      }

      .board-list--item {
        width: calc(100% - 100px);
      }
    }
  }

  @media (max-width: $mo) {
    &-list {
      margin-top: 20px;

      &--item {
        .badge-wrap {
          width: 80px;
        }

        .tit {
          width: calc(100% - 140px);
          padding: 0 10px;
        }

        .date {
          width: 70px;
        }
      }
    }

    &-bottom {
      & > div {
        & > .btn {
          margin-right: 20px;
        }

        .board-list--item {
          width: calc(100% - 70px);
        }
      }
    }
  }
}

.infopage {
  &-top {
    height: 370px;
    font-size: 5rem;
    font-weight: 300;
    color: $colPrimary;
    padding-top: 100px;
    line-height: 1.2;

    span {
      position: relative;

      &:before {
        content: "";
        display: block;
        @include ic(info_shape1, 60, 60);
        position: absolute;
        left: -20px;
        top: -60px;
      }
    }

    strong {
      position: relative;
      color: $colPrimary;

      &:before,
      &:after {
        content: "";
        display: block;
        @include ic(info_shape3, 100, 30);
        background-size: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -40px;
      }

      &:after {
        @include ic(info_shape2, 40, 40);
        left: unset;
        bottom: unset;
        right: -50px;
        top: -20px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 100vw;
      height: 370px;
      position: absolute;
      left: calc(50% - 50vw);
      top: 0;
      background-color: #000;
      z-index: -1;
    }
  }

  &-tit {
    margin: 100px 0 50px;
    font-size: 5rem;
    font-weight: 300;
    @include gradient;
    width: fit-content;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 38px;
      height: 1px;
      background-color: $colLine;
      position: absolute;
      left: 0;
      bottom: -25px;
    }
  }

  &-text {
    strong {
      font-size: 3rem;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      color: $textCol2;
      line-height: 1.2;
      margin-top: 20px;
    }
  }

  &-img {
    padding: 100px 0 140px;

    &--list {
      img {
        width: 100%;
      }

      .tit {
        position: absolute;
        left: 40px;
        top: 40px;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.1;
      }

      .text {
        position: absolute;
        left: 40px;
        top: 123px;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.3;
      }

      .item {
        position: relative;
        margin-bottom: 10px;

        .tit {
          left: 35px;
          top: 35px;
          font-size: 20px;
        }

        .text {
          left: 35px;
          top: 65px;
          font-size: 14px;
        }
      }
    }

    .slick-list {
      margin: 0 -10px;
    }

    .slick-slide > div {
      padding: 0 10px;
    }
  }

  &-bottom {
    padding-top: 80px;
    height: 135px;
    position: relative;
    color: #fff;
    font-size: 1.6rem;
    width: calc(100% + 50px);
    left: -25px;

    ::after {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      position: absolute;
      left: calc(50% - 50vw);
      top: 0;
      bottom: 0;
      background-color: #000;
      z-index: -1;
    }

    .bottom {
      margin-top: 10px;
      display: flex;
      align-items: center;

      .label {
        color: #fff;
        font-size: 2.4rem;
        font-weight: 700;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid $textCol2;
        height: 25px;
        display: inline-block;
        margin-left: 35px;

        &:first-child {
          margin-left: 0;
        }
      }

      .text {
        @include gradient;
        font-size: 2.4rem;
        font-weight: 700;

        &.btn {
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background: linear-gradient(to bottom right, #00ff85, #0a7aff);
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  @media (max-width: $tab) {
    &-top {
      font-size: 3.7rem;
      height: 334px;
      padding-top: 60px;

      span:before {
        width: 38px;
        height: 38px;
        background-size: 38px;
        left: 170px;
        top: 0;
      }

      strong:before {
        bottom: -30px;
      }

      strong:after {
        width: 28px;
        height: 28px;
        background-size: 28px;
        right: -30px;
        top: -25px;
      }

      &:after {
        height: 334px;
      }
    }

    &-tit {
      strong {
        word-break: keep-all;
      }
    }
  }

  @media (max-width: $mo) {
    &-top {
      strong {
        display: block;
        width: fit-content;
      }
    }

    &-tit {
      margin: 60px 0 50px;
    }

    &-text {
      strong {
        font-size: 2rem;
        line-height: 1.2;
      }
    }

    &-img {
      padding: 50px 0 40px;
    }

    &-bottom {
      background-color: #000;
      padding: 50px 20px 0;
      height: auto;
      width: calc(100% + 50px);
      left: -25px;

      &:after {
        display: none;
      }

      .top {
        margin-bottom: 20px;
      }

      .bottom {
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.6;

        .label {
          margin-left: 0;
          border: none;
          font-size: 2rem;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 22px;
            background-color: #666;
            position: absolute;
            right: 0;
            top: 6px;
          }
        }

        .text {
          margin-bottom: 40px;
          font-size: 2rem;
        }
      }
    }
  }
}

.slick-list {
  overflow: visible !important;
}

.pre-line {
  white-space: pre-line;
}

.read-only {
  color: #d9d9d9;
}

.swiper-slide {
  position: relative;
  // overflow: hidden;
}

.home-btn-floating {
  position: sticky;
  left: calc(100% - 270px - 34px);
  bottom: 34px;
  width: 270px;
  height: 50px;
  margin-bottom: 34px;
  padding: 17px 36px;
  border-radius: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 9;

  &::after {
    content: "";
    display: block;
    @include ic(send_line, 16, 16);
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 5px;
  }
}

.file-down-btn-container {
  max-width: 400px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 22px;
  }
}

.file-down-btn {
  position: relative;
  background-color: #fff;
  line-height: 50px;
  padding: 14px 42px;
  width: 100%;
  height: 44px;
  border: 1px solid $colLine;
  border-radius: 5px;
  justify-content: flex-start;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: normal;
  }

  &:before {
    content: "";
    display: block;
    @include ic(clip_line, 14, 14);
    position: absolute;
    top: 14px;
    left: 20px;
  }

  &::after {
    content: "";
    display: block;
    @include ic(down_line_16, 16, 16);
    position: absolute;
    right: 20px;
  }
}

.topic-editor {
  &-container {
    width: 100%;
    font-size: 1.4rem;
    border: 1px solid #eee;
    padding: 14px 17px;
    border-radius: 5px;
  }

  &-textarea {
    caret-color: rgb(11, 184, 153);
    min-height: 400px;
    line-height: 1.5;
    font-family: "Pretendard", sans-serif;
    color: #333;
    font-weight: 400;

    img {
      max-width: 100%;
    }

    &[contenteditable="true"]:empty:before {
      content: attr(placeholder);
      color: $textCol3;
      white-space: break-spaces;
    }
  }

  &-photo {
    cursor: pointer;

    input {
      appearance: none;
      width: 0;
      height: 0;
      position: absolute;
      display: none;
    }

    .icon {
      display: block;
      @include ic(camera_line, 20, 14);
      background-size: 100%;
      filter: invert(1);
    }
  }
}

.topic-file {
  max-width: 500px;

  .input-text {
    padding-right: 40px;
  }

  .file-delete-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    @include ic(close_green, 20, 20);
  }
}

.topic-editor {
  &-container {
    width: 100%;
    font-size: 1.4rem;
    border: 1px solid #eee;
    padding: 14px 17px;
    border-radius: 5px;
  }

  &-textarea {
    caret-color: rgb(11, 184, 153);
    min-height: 400px;
    line-height: 1.5;
    font-family: "Pretendard", sans-serif;
    color: #333;
    font-weight: 400;
    outline: none;
    white-space: break-spaces;

    img {
      max-width: 100%;
    }

    &[contenteditable="true"]:empty:before {
      content: attr(placeholder);
      color: $textCol3;
      white-space: break-spaces;
    }
  }

  &-photo {
    display: inline-block;
    cursor: pointer;

    input {
      appearance: none;
      width: 0;
      height: 0;
      position: absolute;
      display: none;
    }

    .icon {
      display: block;
      @include ic(camera_line, 20, 14);
      background-size: 100%;
      filter: invert(1);
    }
  }
}

.no-score {
  display: none;
}

.main-top3 {
  .badges {
    .badge-hide:nth-child(n + 3) {
      display: none;
    }
  }
}
