@charset "UTF-8";
@import "global.scss";

@media (min-width: $pc) {
  .win *::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
}

.win *::-webkit-scrollbar-thumb {
  background-color: $textCol4;
  border-radius: 6px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.win *::-webkit-scrollbar-track {
  background-color: $colBg;
}

body,
html {
  width: 100%;
  //min-height: 100%;
  height: auto;
}

.m-block {
  display: none !important;

  @media (max-width: $mo) {
    display: block !important;
  }
}

.m-flex {
  display: none !important;

  @media (max-width: $mo) {
    display: flex !important;
  }
}

.m-none {
  @media (max-width: $mo) {
    display: none !important;
  }
}

.txt {
  &-c {
    &--primary {
      color: $colPrimary !important;
    }

    &--sub {
      color: $colSub !important;
    }

    &--red {
      color: $colError !important;
    }

    &--blue {
      color: #0a7aff !important;
    }

    &--grey {
      color: $textCol3 !important;
    }

    &--purple {
      color: #5712eb !important;
    }
  }
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }

  &-4 {
    margin-top: 4px !important;
  }

  &-10 {
    margin-top: 10px !important;
  }

  &-13 {
    margin-top: 13px !important;
  }

  &-15 {
    margin-top: 15px !important;

    @media (max-width: $mo) {
      margin-top: 14px !important;
    }
  }

  &-20 {
    margin-top: 20px !important;
  }

  &-30 {
    margin-top: 30px !important;
  }

  &-40 {
    margin-top: 40px !important;
  }

  &-60 {
    margin-top: 60px !important;
  }

  &-70 {
    margin-top: 70px !important;
  }

  &-100 {
    margin-top: 100px !important;
  }

  @media (max-width: $mo) {
    &-10 {
      &.m-mt-16 {
        margin-top: 16px !important;
      }
    }

    &-70 {
      margin-top: 35px !important;
    }

    &-100 {
      margin-top: 50px !important;

      &.btn-wrap {
        margin-top: 30px !important;
      }
    }
  }
}

@media (max-width: $mo) {
  .m-mt-6 {
    margin-top: 6px !important;
  }

  .m-row {
    display: flex !important;

    .col-sm {
      width: 121px !important;
      min-width: 121px;
      margin-right: 10px;
    }

    .ml-10 {
      margin: 0 0 0 10px !important;
    }

    .input-radio {
      width: 100%;

      .box {
        font-size: 1.4rem;
        white-space: nowrap;
      }
    }

    .img {
      width: 90px;
      height: 90px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.mb {
  &-0 {
    margin-bottom: 0 !important;
  }

  &-20 {
    margin-bottom: 20px !important;
  }
}

.ml {
  &-10 {
    margin-left: 10px;
  }

  &-20 {
    margin-left: 20px;
  }
}

.mr {
  &-0 {
    margin-right: 0 !important;
  }

  &-5 {
    margin-right: 5px !important;
  }
}

.h-100 {
  min-height: calc(100vh - 353px);

  @media (max-width: $mo) {
    height: auto;
  }
}

.hr-line {
  border: 1px solid $colLine;
  margin-top: 30px;
}

.btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: $font, sans-serif;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: #333;

  &:disabled {
    background-color: #d6d6d6 !important;
    color: #fff !important;
    cursor: default;

    &:hover {
      box-shadow: none !important;
    }
  }

  &-wrap {
    text-align: center;
    margin: 20px 0;

    .btn-half {
      max-width: 145px;

      @media (max-width: $mo) {
        width: calc(50% - 5px);
        max-width: unset;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &.type2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 620px;
      margin: 0 auto;

      .btn-lg {
        width: 200px;
        margin: 0 5px;
      }

      .btn-like {
        margin: -15px 15px 0 0;
      }

      .btn-half {
        width: 50%;
        max-width: unset;
      }
    }

    @media (max-width: $mo) {
      .ml-20 {
        margin: 10px 0 0 0;
      }

      &.type2 .btn-like {
        margin: -15px 15px 0px 11px;
      }

      .btn-lg {
        width: calc(50% - 2px);
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }

        &:only-child {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &-xsm {
    padding: 0 10px;
    height: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;

    @media (max-width: $mo) {
      height: 30px;
    }
  }

  &-sm {
    padding: 0 10px;
    height: 28px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
  }

  &-md {
    padding: 10px 18px;
    height: 34px;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 5px;
  }

  &-lg {
    width: 300px;
    height: 50px;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 5px;

    @media (max-width: $mo) {
      width: 100%;
    }
  }

  &-half {
    width: 50%;
    height: 50px;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 5px;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  &-full {
    width: 100%;
    margin-top: 30px;
    height: 50px;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 5px;
  }

  &-underline {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &-direct {
    .arrow {
      @include ic(arrow_right, 8, 12);
      background-size: 5px;
      margin-left: 8px;
      opacity: 0.8;

      &.wh {
        @include ic(arrow_back, 8, 12);
        background-size: 8px;
        transform: rotate(180deg);
        filter: invert(1);
        opacity: 1;
      }
    }
  }

  &-primary {
    background-color: $colPrimary;
    color: #000;

    &:active {
      background-color: $colSub;
    }

    &.type2 {
      &.link:after {
        content: "";
        display: block;
        @include ic(arrow_next_bl, 6, 12);
        margin-left: 6px;
      }
    }
  }

  &-black {
    background-color: #000;
    color: #fff;

    &:active {
      color: $textCol3;
    }

    &:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    }

    &.type2 {
      color: $colPrimary;

      &:active {
        background-color: $textCol1;
        color: $colSub;
      }

      &.link:after {
        content: "";
        display: block;
        @include ic(arrow_right_active, 6, 12);
        margin-left: 6px;
      }
    }

    &.type3 {
      color: $colPrimary;
      border: 2px solid $colPrimary;

      &:active {
        background-color: $textCol1;
        color: $colSub;
      }
    }
  }

  &-grey {
    background-color: $colGrey;
  }

  &-l-grey {
    background-color: $colBg;
    color: $textCol3;
  }

  &-line {
    border: 1px solid $textCol1;

    &.grey {
      border-color: $colGrey;
      color: $colGrey;
    }
  }

  &-blue {
    background-color: #0a7aff;
    color: $colPrimary;
  }

  &-blueline {
    border: 1px solid #0a7aff;
    color: #0a7aff;

    &:disabled {
      background-color: #fff !important;
      border-color: $colLine !important;
      color: $colGrey !important;
    }
  }

  &-prupleline {
    border: 1px solid #5712eb;
    color: #5712eb;

    &.btn-down:before {
      @include ic(down_line_blue, 16, 16);
    }
  }

  &-like {
    @include ic(icon_heart_line, 26, 20);
    background-size: 100%;
    min-width: 26px;

    &.active {
      background-image: url(../assets/images/icon/heart_fill.svg);
      background-size: 100%;
    }

    &.type2 {
      @include ic(heart_line_color, 25, 40);
      padding-top: 40px;

      &.active {
        background-image: url(../assets/images/icon/heart_fill.svg);
      }
    }
  }

  &-star {
    @include ic(star_line, 12, 12);
    background-size: 100%;

    &.active {
      background-image: url(../assets/images/icon/star_fill.svg);
      width: 14px;
      height: 13px;
    }

    &.half {
      background-image: url(../assets/images/icon/star_half.svg);
      width: 14px;
      height: 13px;
    }
  }

  &-down {
    &:before {
      content: "";
      display: inline-block;
      @include ic(down_line, 16, 16);
      margin-right: 5px;
    }

    &:disabled:before {
      @include ic(down_line_white, 16, 16);
    }
  }

  &-add {
    &:before {
      content: "";
      display: inline-block;
      @include ic(add_line, 12, 12);
      margin-right: 4px;
    }
  }

  &-floating {
    @include ic(top, 50, 50);
    position: sticky;
    left: 100%;
    bottom: 100px;
    background-color: $colBg;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    z-index: 9;
  }

  @media (max-width: $mo) {
    &-wrap {
      margin: 20px 0 0;
    }

    &-floating {
      // display: none;
      bottom: 50px;
    }

    &-like {
      font-size: 1.2rem;
    }
  }
}

.badge {
  padding: 0 14px;
  height: 26px;
  line-height: 26px;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;

  // &+.badge {
  //     margin-left: 5px;
  // }

  &.m5 {
    margin-right: 5px;
    margin-left: 0;
  }

  &-c {
    @include ic(client_fill, 14, 14);
    padding: 0;
  }

  &-h {
    @include ic(heroes_fill, 14, 14);
    padding: 0;
  }

  &-sm {
    padding: 0 6px;
    height: 16px;
    line-height: 16px;
    font-size: 1.2rem;
  }

  &-md {
    padding: 0 12px;
    height: 24px;
    line-height: 24px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &-round {
    border-radius: 20px;
  }

  &.d-grey {
    background-color: $textCol2;
    color: #fff;
  }

  &.d-grey2 {
    background-color: $colGrey;
    color: #fff;
  }

  &.grey {
    background-color: $colBg;
    color: $textCol2;

    &.line {
      border: 1px solid $textCol3;
      color: $textCol3;
      background-color: transparent;
    }
  }

  &.grey2 {
    background-color: $colGrey;
    color: #000;
  }

  &.black {
    background-color: #000;
    color: #fff;

    &.auto {
      font-size: 1.2rem;
    }

    &.type2 {
      color: $colPrimary;
    }
  }

  &.primary {
    background-color: $colPrimary;
    color: #000;
  }

  &.primary2 {
    background-color: $colSub2;
  }

  &.orange {
    border: 1px solid #ff9d0a;
    color: #ff9d0a;
    line-height: 25px;

    &.badge-sm {
      line-height: 15px;
    }

    &.fill {
      background-color: #ffbc27;
      border: none;
      color: #000;
    }
  }

  &.yellow {
    background-color: #f2dd22;
    color: #000;
  }

  &.purple {
    background-color: #f4ecff;
    color: #000;
  }

  &.purple2 {
    background-color: #d8baff;
    color: #000;
  }

  &.deeppurple {
    background-color: #5712eb;
    color: #fff;
  }

  &.danger {
    background-color: $colError;
    color: #fff;
  }

  &.skyblue {
    background-color: #baeefe;
    color: #000;
  }

  &.blue {
    background-color: #43f4ff;

    &-line {
      border: 1px solid #0a7aff;
      color: #0a7aff;
      line-height: 25px;

      &.badge-sm {
        line-height: 15px;
      }
    }
  }

  &.deepblue {
    background-color: #0a7aff;
    color: #fff;
  }

  &.pink {
    background-color: #ffb2b2;
    color: #000;
  }

  &.lime {
    background-color: #ccff00;
    color: #000;
  }

  &.power {
    @include ic(badge_power, 140, 30);
  }

  @media (max-width: $mo) {
    padding: 0 14px;

    &.badge-sm {
      padding: 0 6px;
    }

    &.badge-md {
      padding: 0 12px;
    }
  }
}

.badges .badge {
  margin-right: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltip-message {
    position: absolute;
    width: max-content;
    pointer-events: none;
  }

  &.guide {
    z-index: 100;

    .tooltip-message {
      font-size: 1.4rem;
      font-weight: 600;
      background-color: $colPrimary;
      padding: 15px 18px;
      border-radius: 5px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      left: 50%;

      @media (max-width: $mo) {
        display: none;
      }
    }

    &.bottom {
      &.right {
        .tooltip-message {
          transform: translate(-80%, 100%);

          &:after {
            left: unset;
            right: 20px;
          }
        }
      }

      .tooltip-message {
        bottom: -15px;
        transform: translate(-50%, 100%);
        animation: bounce_b 1.2s infinite linear 0.5s;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 0 solid #00ff85;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #00ff85;
          border-right: 10px solid transparent;
          position: absolute;
          border-top: 0 solid #d9d9d9;
          border-left: 10px solid #d9d9d9;
          left: 10%;
          bottom: -10px;
          filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.15));
        }
      }
    }

    &.top {
      .tooltip-message {
        top: -15px;
        transform: translate(-50%, -100%);
        animation: bounce_t 1.2s infinite linear 0.5s;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 0 solid #00ff85;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #00ff85;
          border-right: 10px solid transparent;
          position: absolute;
          border-top: 0 solid #d9d9d9;
          border-left: 10px solid #d9d9d9;

          left: 10%;
          bottom: -10px;
          filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.15));
        }
      }

      &.left {
        .tooltip-message {
          transform: translate(-90%, -100%);

          &:after {
            border-left: 10px solid transparent;
            border-right: 10px solid $colPrimary;
            left: 80%;
          }
        }
      }
    }

    @keyframes bounce_t {
      0% {
        top: -15px;
      }

      20% {
        top: -20px;
      }

      50% {
        top: -15px;
      }

      100% {
        top: -15px;
      }
    }

    @keyframes bounce_b {
      0% {
        bottom: -15px;
      }

      20% {
        bottom: -20px;
      }

      50% {
        bottom: -15px;
      }

      100% {
        bottom: -15px;
      }
    }
  }

  &.default {
    .btn.i {
      @include ic(info_green, 16, 16);
    }

    &:hover {
      .tooltip-message {
        z-index: 10;
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    .tooltip-message {
      border: 1px solid $colSub;
      border-radius: 5px;
      padding: 12px 18px;
      background-color: #fff;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.3;
      opacity: 0;
      z-index: -1;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

      span {
        display: inline-flex;

        &:before {
          content: "ㆍ";
          display: inline-block;
        }
      }
    }

    &.right {
      .tooltip-message {
        left: 25px;
        top: 0;
      }
    }
  }

  @media (max-width: $mo) {
    &.guide {
      width: 100%;
    }

    &.default {
      .tooltip-message {
        width: calc(100vw - 200px);
      }
    }
  }
}

input {
  font-family: $font, sans-serif !important;
  outline: none;
  appearance: none;

  &::placeholder {
    color: $textCol3;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.input {
  &-wrap {
    position: relative;
    max-width: 504px;
    margin: 0 auto;

    .btn-bottomfix {
      position: absolute;
      left: 0;
      bottom: -50px;
    }

    &.w-100 {
      max-width: none;
    }

    .right-top {
      position: absolute;
      right: 0;
      top: 0;

      @media (max-width: $mo) {
        top: -5px;
      }
    }

    .btn-full {
      margin-top: 60px;
    }

    .btn-exist {
      display: flex;
      position: relative;

      .sm {
        width: 22%;
        min-width: 66px;
        margin-right: 4px;
      }

      .input-text:nth-last-of-type(1) {
        padding-right: 90px;
      }

      .btn {
        height: 34px;
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 0 10px;
        border-radius: 5px;

        @media (max-width: $mo) {
          font-size: 1.4rem;
        }
      }

      .btn-check {
        @include ic(check_green, 20, 20);
        right: 15px;
        top: 15px;
        cursor: default;
      }

      .check {
        @include ic(check_green, 20, 20);
        margin-right: 8px;
        position: absolute;
        display: block;
        left: 15px;
        top: 15px;

        & + .input-text {
          padding-left: 40px;
        }

        &.right {
          left: unset;
          right: 15px;
        }
      }
    }

    .sns-exist {
      position: relative;

      .input-text {
        padding-left: 44px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 18px;
        top: 15px;
        background-size: 100%;
      }

      &.DEFAULT {
        .input-text {
          padding-left: 18px;
        }
      }

      &.KAKAO:after {
        @include ic(sns_kakao, 20, 20);
      }

      &.GOOGLE:after {
        @include ic(sns_google, 20, 20);
      }

      &.NAVER:after {
        @include ic(sns_naver, 20, 20);
      }
    }

    .flex {
      display: flex;
      align-items: center;

      .sm {
        width: 40%;
      }

      .sm2 {
        width: 30%;
      }

      .xsm {
        width: 20%;
      }

      .xxsm {
        width: 11%;
      }

      .auto {
        width: auto;
        font-size: 1.2rem;
      }

      .btn {
        height: 50px;
        border-radius: 5px;
        padding: 0 14px;
      }

      .end {
        justify-content: flex-end;
      }

      & > * {
        margin-right: 10px;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }
      }

      .datepicker {
        width: auto;
      }

      @media (max-width: $mo) {
        &.type2 {
          flex-wrap: wrap;

          & > * {
            margin-bottom: 5px;
          }

          .xxsm {
            flex: 1;
            width: auto;
          }

          .xsm,
          .sm {
            flex: 2;
            width: auto;
          }
        }
      }
    }
  }

  &-label {
    display: block;
    width: fit-content;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;

    .tooltip {
      position: absolute;
      right: -30px;
      top: 0;
    }

    &.required {
      &:after {
        content: "*";
        color: $colError;
        position: absolute;
        right: -10px;
        top: 0;
      }

      &.type2:after {
        right: unset;
        left: 81px;
      }

      &.type3:after {
        right: unset;
        left: 110px;
      }

      & > .guide {
        margin-left: 14px;
      }
    }

    &.sm {
      font-size: 1.2rem;
      margin: 20px 0 4px;
      font-weight: 400;

      @media (max-width: $mo) {
        margin: 20px 0 6px;
      }

      &.required:after {
        font-size: 1.3rem;
        right: -8px;
      }
    }

    &.sm2 {
      font-size: 1.2rem;
      color: $textCol3;
      margin: 20px 0 4px;
      font-weight: 400;

      &.required:after {
        font-size: 1.3rem;
        right: -8px;
      }
    }

    .guide {
      margin-left: 4px;
      font-size: 1.2rem;
      font-weight: 400;
      color: $textCol2;
      transform: translateX(100%);

      @media (max-width: $mo) {
        &:not(.type2) {
          position: relative;
          display: block;
          top: unset;
          right: unset;
          transform: unset;
          margin-top: 10px;
          font-size: 1.4rem;
        }
      }

      &.type2 {
        right: -20px;
        top: -7px;
      }
    }
  }

  &-text {
    width: 100%;
    height: 50px;
    padding: 0 18px;
    font-size: 1.4rem;
    border: 1px solid $colLine;
    border-radius: 5px;
    font-weight: 400;
    caret-color: $colSub;
    color: $textCol1;
    text-indent: 2px;

    &:read-only,
    &:disabled {
      background-color: $colBg;
      border-color: $colLine;
      color: $textCol3;
      // opacity: 1;
      -webkit-text-fill-color: inherit;

      &:focus {
        border-color: $colLine !important;
      }
    }

    &:focus:not(.input-date) {
      border-color: $colSub;
    }

    &.type2 {
      &:focus {
        border-color: $colLine;
      }
    }

    &.error {
      border-color: $colError;
      caret-color: $colError;
      color: $colError;

      &:focus {
        color: $textCol1;
      }
    }

    &.correct {
      border-color: $colSub;
      color: $colSub;
    }

    &.wd {
      &-500 {
        width: 500px;
      }
    }
  }

  &-date {
    background: url(../assets/images/icon/calendar_fill.svg) no-repeat
      calc(100% - 15px) 50%;
    background-color: #fff !important;
    color: #333 !important;
    caret-color: $textCol1;
    text-align: left;
    font-family: $font, sans-serif;

    &[value=""] {
      color: $textCol3 !important;
    }
  }

  &-number {
    position: relative;

    .input-text {
      padding-right: 40px;
    }

    .budget-replace {
      position: absolute;
      font-size: 1.4rem;
      font-weight: 400;
      color: $textCol1;
      left: 20px;
      top: 18px;
      pointer-events: none;

      & + .input-text {
        color: rgba(0, 0, 0, 0);
      }
    }

    .btn {
      position: absolute;
      right: 16px;

      &.up {
        @include ic(arrow_top_fill, 14, 8);
        top: 12px;
      }

      &.down {
        @include ic(arrow_bottom_fill, 14, 8);
        bottom: 12px;
      }
    }
  }

  &-password {
    position: relative;
    line-height: 0;

    .input-text {
      padding-right: 50px;
    }

    .btn {
      position: absolute;
      right: 15px;
      top: 15px;

      &.show {
        @include ic(view, 20, 20);
      }

      &.hide {
        @include ic(hide, 20, 20);
      }
    }
  }

  &-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    input {
      &:checked ~ .box {
        background-color: $colPrimary;
        border-color: $colPrimary;
        @include ic(check_line, 13, 13);
        background-size: 9px;

        &.radio {
          background-color: $colPrimary;
          border-color: $colPrimary;
        }

        &.sm {
          background-color: #0a7aff;
          border-color: #0a7aff;
          @include ic(check_line_wh, 12, 12);
          background-size: 8px;
        }

        &.purple {
          background-color: #5712eb;
          border-color: #5712eb;
          @include ic(check_line_wh, 16, 16);
          background-size: 11px;
        }
      }

      &:checked + .label {
        background-color: $colPrimary;
        color: #000;
      }

      &:checked + .label.type2 {
        background-color: $colSub2;
        color: #000;
      }
    }

    .box {
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: #fff;
      border: 1px solid $textCol2;

      &.sm {
        width: 12px;
        height: 12px;
        border-radius: 20px;

        & + .text {
          margin-left: 5px;
        }
      }

      &.radio {
        background-color: #ddd;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
      }

      &.purple {
        width: 16px;
        height: 16px;
      }
    }

    .text {
      font-size: 1.4rem;
      margin-left: 10px;
      color: $textCol2;
      white-space: nowrap;

      &.ml-4 {
        margin-left: 4px;
      }

      &.underline {
        span {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }

    &.type2 {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 7px 10px;

      @media (max-width: $mo) {
        width: fit-content;

        &:first-child {
          margin: 0 10px 0 -10px;
        }
      }

      .box {
        margin-top: 2px;

        &:after {
          content: "";
          display: block;
          position: relative;
          top: -2px;
          @include ic(check_active_off, 16, 16);
          background-size: 16px;
        }

        background-size: 100%;
        border: none;
        background-color: transparent;
        margin-right: 2px;
      }

      .text {
        font-size: 1.4rem;
        color: $textCol3;
        position: relative;
      }

      input:checked + .box {
        background-size: 100%;
        background-color: transparent;

        &:after {
          @include ic(check_round, 16, 16);
          background-size: 16px;
        }

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $colPrimary;
          border-radius: 5px;
        }
      }

      input:checked ~ .text {
        color: #000;
      }
    }

    &.blue {
      .box {
        width: 16px !important;
        height: 16px !important;
      }

      input:checked ~ .box {
        background-color: #0a7aff;
        border-color: #0a7aff;
        background-image: url(../assets/images/icon/check_line_wh.svg);
        background-size: 10px;
        background-position: 50% 40%;
      }
    }

    &.sm.end {
      input:checked ~ .box {
        background-color: #000;
        @include ic(check_line_wh, 16, 16);
        background-size: 9px;
        border-color: #000;
      }

      .box {
        border-radius: 10px;
        width: 16px;
        height: 16px;
      }

      .text {
        margin-left: 6px;
      }
    }

    &--list {
      &.box {
        background-color: $colBg;
        padding: 18px;
        border-radius: 4px;
        margin: 26px 0;

        .item:last-child {
          margin-bottom: 0;
        }
      }

      .item {
        margin-bottom: 18px;

        &.top {
          padding-bottom: 18px;
          border-bottom: 1px solid #ddd;

          .text {
            font-size: 1.6rem;
            font-weight: 700;
          }
        }
      }

      .btn {
        float: right;
        margin-top: 3px;
      }
    }

    &--wrap {
      .label {
        margin: 0 10px 12px 0;
      }
    }

    .label {
      display: inline-block;
      padding: 0 14px;
      height: 26px;
      line-height: 26px;
      border-radius: 20px;
      font-size: 1.4rem;
      font-weight: 400;
      color: $textCol3;
      background-color: $colBg;
      cursor: pointer;

      &.wide {
        padding: 0 18px;
      }
    }
  }

  &-radio {
    input {
      position: absolute;
      width: 0;
      height: 0;
      appearance: none;
    }

    input {
      &:checked + .box {
        border-color: $colPrimary;
        background-color: $colPrimary;
        color: #000;
      }
    }

    .box {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      padding: 0 14px;
      font-size: 1.6rem;
      font-weight: 600;
      background-color: $colBg;
      border-radius: 4px;
      color: $textCol3;
      cursor: pointer;
    }
  }

  &-select {
    outline: none;
    height: 50px;
    border: 1px solid $colLine;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 40px 0 18px;

    &.placeholder {
      .ss-list {
        & > *:first-child {
          display: none;
        }
      }
    }

    &.ss-main {
      border: 1px solid $colLine;

      .ss-values {
        padding-left: 13px;
        margin-right: 10px;
      }

      .ss-arrow {
        min-width: 14px;
        margin-right: 14px;
      }
    }

    &.ss-content {
      padding: 0;
      border: 1px solid $colLine;
      border-radius: 5px;
      margin-top: 10px !important;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

      .ss-search {
        display: none;
      }

      .ss-list {
        .ss-option {
          height: 40px;
          line-height: 40px;
          color: #333 !important;
          padding: 0 18px;
          transition: all 0.3s;
          white-space: nowrap;

          &:hover {
            background-color: $colBg;
          }

          &:active {
            background-color: $colGrey;
          }
        }

        .ss-selected {
          color: $colSub !important;

          &:hover {
            background-color: $colBg !important;
          }
        }
      }
    }
  }

  &-textarea {
    width: 100%;
    font-size: 1.4rem;
    border: 1px solid $colLine;
    padding: 14px 17px;
    border-radius: 5px;
    resize: none;
    line-height: 1.5;
    color: $textCol1;

    &::placeholder {
      color: $textCol3;
    }

    @media (max-width: $mo) {
      padding: 15px 18px;
    }
  }

  &-switch {
    &--wrap {
      display: flex;
      align-items: center;

      .text {
        font-size: 1.2rem;
        font-weight: 400;
        margin-right: 4px;
      }
    }

    input {
      position: absolute;

      &:checked + .switch {
        background-color: $colPrimary;

        &:after {
          left: calc(100% - 12px);

          @media (max-width: $mo) {
            left: calc(100% - 23px);
          }
        }
      }
    }

    .switch {
      cursor: pointer;
      display: inline-block;
      width: 26px;
      height: 14px;
      border-radius: 20px;
      background-color: #000;
      position: relative;
      transition: background-color 0.3s;

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 2px;
        top: calc(50% - 5px);
        transition: left 0.3s;
        background-color: #fff;
        border-radius: 20px;
      }

      @media (max-width: $mo) {
        width: 46px;
        height: 26px;

        &:after {
          width: 20px;
          height: 20px;
          top: calc(50% - 10px);
          left: 3px;
        }
      }
    }
  }

  &-file {
    position: relative;
    display: flex;
    cursor: pointer;

    input[type="file"] {
      display: none;
      position: absolute;
      appearance: none;
      width: 0;
      height: 0;
    }

    .input-text {
      background-color: #fff;
      line-height: 50px;
      @include text_ellipsis;

      &.center {
        text-align: center;
        color: $textCol1;
      }

      &.correct {
        color: $colSub;

        &:before {
          @include ic(clip_line_on, 14, 14);
        }
      }

      &:before {
        content: "";
        display: inline-block;
        @include ic(clip_line, 14, 14);
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }

  &-agree {
    margin-top: 60px;

    &--tit {
      font-size: 1.6rem;
      font-weight: 600;
      color: #000;
    }

    &--con {
      margin: 20px 0;
      font-size: 1.4rem;
      color: $textCol2;
      line-height: 1.4;
      white-space: pre-line;
    }
  }

  &-guide {
    font-size: 1.2rem;
    margin-top: 6px;
    font-weight: 400;
    line-height: 1;
    color: $colSub;

    &.center {
      text-align: center;
    }

    &.error {
      color: $colError;
    }

    &.grey {
      color: $textCol3;
    }
  }

  &-card {
    border: 1px solid $colLine;
    border-radius: 5px;
    padding: 20px 30px;

    &.view {
      padding-top: 40px;
      position: relative;

      > .date {
        margin-bottom: 10px;

        @media (max-width: $mo) {
          margin-bottom: 14px;
        }
      }

      .more {
        position: absolute;
        right: 20px;
        top: 20px;
        @include ic(meatball, 20, 20);

        &-menu {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
          width: 76px;
          border-radius: 5px;
          position: absolute;
          right: 20px;
          top: 20px;
          z-index: -1;
          opacity: 0;
          overflow: hidden;

          .btn {
            padding: 14px 20px;
            width: 76px;
            font-size: 1.4rem;
            background-color: #fff;

            &:hover {
              background-color: $colBg;
            }

            &:active {
              background-color: $colGrey;
            }
          }

          &.active {
            z-index: 1;
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }
    }

    & + .input-card {
      margin-top: 20px;

      @media (max-width: $mo) {
        margin-top: 16px;
      }
    }

    .name {
      .input,
      .text {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .detail {
      display: flex;
      margin: 6px 0 10px;
      gap: 2px;
      font-size: 1.2rem;

      @media (max-width: $mo) {
        margin: 6px 0 14px;
      }

      .input,
      .text {
        font-size: 1.2rem;
        font-weight: 400;
      }

      .department {
        width: 22px;
      }
    }

    .date {
      display: flex;
      align-items: center;
      font-size: 1.2rem;

      .input,
      .text {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .text {
        margin: 0;
      }

      .y {
        width: 33px;
      }

      .m {
        width: 22px;
        margin-left: 4px;
      }

      span {
        margin: 0 5px;
        font-size: 1.2rem;
      }

      .input-checkbox {
        input:checked + .box {
          background-color: #000;
          background-image: url(../assets/images/icon/check_line_wh.svg);
          border-color: #000;
        }

        .box {
          border-radius: 20px;
          margin: 0 4px 0 10px;
        }
      }
    }

    .text {
      .input {
        border: none;
        padding: 0;
        margin-top: 10px;
        height: 21px;
        word-break: keep-all;

        @media (max-width: $mo) {
          margin-top: 14px;
        }
      }
    }

    .btn-full {
      margin-top: 20px;
      height: 34px;

      @media (max-width: $mo) {
        height: 45px;
      }
    }
  }

  @media (max-width: $mo) {
    &-wrap {
      .btn-full.mb-20 {
        margin-bottom: 30px !important;

        &.mt-15 {
          margin-bottom: 20px !important;
        }
      }

      .btn-bottomfix {
        right: unset;
        bottom: -34px;
      }

      &.flex16 {
        .input-label {
          .guide.type2 {
            position: relative;
            top: 10px;
            left: -100%;
            right: unset;
            margin: 0 -200px 30px -162px;
            white-space: nowrap;
          }
        }
      }
    }

    &-checkbox.type2 {
      padding: 10px 13px 10px 11px;

      .text {
        margin-left: 4px;
      }
    }

    &-label {
      margin: 14px 0;

      &.m-mt-30 {
        margin-top: 30px;
      }
    }

    &-text {
      &.wd-500 {
        width: 100%;
      }
    }

    &-file {
      .input-photo {
        width: 114px;
        height: 114px;
        position: relative;
        overflow: visible;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

        &:before {
          content: "";
          display: block;
          @include ic(photo_edit, 40, 40);
          position: absolute;
          right: -20px;
          bottom: -20px;
          z-index: 11;
        }
      }
    }

    &-card {
      padding: 40px 24px 20px;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}

.select-multi {
  position: relative;
  // z-index: 10;
  height: 50px;

  .select {
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    border: 1px solid $colLine;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 40px 0 18px;
    background: url(../assets/images/icon/arrow_bottom_fill.svg) no-repeat
      calc(100% - 18px) 50%;
    transition: none;

    &.active {
      background: url(../assets/images/icon/arrow_top_fill.svg) no-repeat
        calc(100% - 18px) 50%;

      & + .select-list {
        transform: scaleY(1);
        z-index: 9;
      }
    }

    &-list {
      text-align: start;
      min-width: 100%;
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      background-color: #fff;
      border: 1px solid $colLine;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      max-height: 220px;
      overflow-y: auto;
      transition: transform 0.2s;
      transform: scaleY(0);
      transform-origin: top;

      &--item {
        padding: 13px 20px;
        white-space: nowrap;

        &.top {
          border-bottom: 1px solid $colLine;
        }
      }
    }

    .all {
      color: $colSub;
      margin-left: 10px;
    }
  }

  @media (max-width: $mo) {
    width: 100%;
  }
}

.datepicker {
  &-picker {
    width: 300px;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.4rem;

    .datepicker-controls .button {
      font-size: 1.6rem;
      font-weight: 600;
      font-family: $font, sans-serif;

      &.prev-button {
        text-indent: -9999px;
        @include ic(arrow_prev_bl, 6, 35);
      }

      &.next-button {
        text-indent: -9999px;
        @include ic(arrow_next_bl, 6, 35);
      }
    }
  }

  &-view,
  .days {
    width: 100% !important;

    .dow {
      font-size: 1.4rem;
      font-weight: 500;
      color: $colSub;
      height: 38px;

      &:first-child,
      &:last-child {
        color: $colGrey;
      }
    }
  }

  .datepicker-cell {
    height: 38px;

    &.prev,
    &.next {
      color: $colGrey !important;
      font-weight: 400;
    }

    &.focused,
    &:hover {
      background-color: transparent !important;
    }

    &.focused {
      position: relative;
      color: #4d4d4d !important;
      font-weight: 500;

      &:after {
        content: "";
        width: 5px;
        height: 5px;
        background-color: $colPrimary;
        position: absolute;
        left: calc(50% - 2px);
        bottom: -2px;
        border-radius: 3px;
      }
    }

    &:nth-child(7n),
    &:nth-child(7n + 1) {
      color: $colGrey !important;
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    border: 1px solid $colLine;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
    border-radius: 4px;
    font-family: $font, sans-serif;

    &__month-container {
      width: 300px;
      border-radius: 5px;
      padding: 10px;
      font-size: 1.4rem;

      .react-datepicker__header {
        background-color: inherit;
        border: none;

        .datepicker-controls {
          display: flex;
          justify-content: space-between;

          .button {
            font-size: 1.6rem;
            font-weight: 600;
            font-family: $font, sans-serif;

            &.prev-button {
              text-indent: -9999px;
              @include ic(arrow_prev_bl, 6, 35);
            }

            &.next-button {
              text-indent: -9999px;
              @include ic(arrow_next_bl, 6, 35);
            }
          }
        }
      }
    }

    &__current-month {
      font-size: 1.6rem;
      font-family: $font, sans-serif;
      padding: 5px 0 10px;
    }

    &__navigation--previous,
    &__navigation--next {
      top: 15px;
    }

    &__navigation-icon--previous::before,
    &__navigation-icon--next::before {
      right: -15px;
      border-color: #000 !important;
      border-width: 1px 1px 0 0;
    }

    &__navigation-icon--next::before {
      right: unset;
      left: -15px;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }

    .react-datepicker__day-names {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 38px;

      .react-datepicker__day-name {
        font-family: $font, sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        color: $colSub;

        &:first-child,
        &:last-child {
          color: $colGrey;
        }
      }
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 38px;
    }

    .react-datepicker__day {
      font-family: $font, sans-serif;
      color: $textCol1;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;

      &.prev,
      &.next {
        color: $colGrey;
        font-weight: 400;
      }

      &:nth-child(7n),
      &:nth-child(7n + 1),
      &--outside-month {
        color: $colGrey;
      }

      &:hover {
        background-color: $colBg;
      }

      &--disabled {
        &:hover {
          background-color: transparent;
        }
      }

      &--selected {
        position: relative;
        background-color: $colPrimary;
      }

      &--today {
        position: relative;
        color: $textCol1;
        font-weight: 500;

        &:after {
          content: "";
          width: 5px;
          height: 5px;
          background-color: $colPrimary;
          position: absolute;
          left: calc(50% - 2px);
          bottom: 2px;
          border-radius: 3px;
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }
  }
}

.col-2 {
  display: flex;
  width: 100%;

  & > * {
    flex: 1;
  }

  .input-wrap {
    width: 100%;
    max-width: 100%;
  }

  .flex16 {
    flex: 1.7;
  }

  .flex2 {
    flex: 2;
  }

  & > .text {
    flex: none;
    font-size: 1.4rem;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: $mo) {
    &:not(.type2) {
      display: block;

      .ml-10,
      .ml-20 {
        margin: 10px 0 0 0;
      }

      .main-top3.ml-20 {
        margin: 60px 0 0 0;
      }

      .btn-half {
        width: calc(50% - 5px);

        &:last-child {
          margin-left: 1px;
        }

        &.m-w-100 {
          width: 100%;

          &.mt-30:last-child {
            margin-top: 10px !important;
          }
        }
      }
    }

    .type2 {
      .ml-10 {
        margin: 0 0 0 10px;
      }
    }

    .flex2 {
      &.ml-10 {
        margin: 0 0 0 10px;
      }
    }
  }
}

.header {
  border-bottom: 1px solid $colLine;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;

  &-wrap {
    position: relative;
    width: 100%;
    max-width: $pc;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
      position: relative;

      .alarm {
        @include ic(bell_line, 22, 23);
        margin: 0 17px;

        &.new {
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: $colSub;
            position: absolute;
            right: -5px;
            top: -5px;

            @media (max-width: $tab) {
              display: none;
            }
          }
        }

        &-list {
          position: absolute;
          right: 0;
          top: 70px;
          z-index: 100;
          background-color: #fff;
          box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          overflow-x: hidden;

          &--item {
            padding: 0 20px;

            &:last-child {
              margin-bottom: 20px;
            }

            .alarm-wrap {
              width: 274px;
              padding: 12px 16px;
              font-size: 1.2rem;
              border-radius: 5px;
              line-height: 1.5;
              text-align: start;
              display: flex;
              flex-direction: column;
              cursor: pointer;
              transition: all 0.3s;
              position: relative;

              &:hover {
                background-color: $colBg;

                .tit {
                  text-decoration: underline;
                  text-underline-offset: 2px;
                }

                .btn-delete {
                  opacity: 1;
                }
              }

              &.new {
                padding-left: 30px;

                &:after {
                  content: "";
                  display: block;
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: $colSub;
                  position: absolute;
                  left: 16px;
                  top: 16px;
                }
              }

              &.read {
                .top,
                .date,
                .tit {
                  color: $colGrey;
                }
              }

              .top,
              .date {
                font-size: 1.2rem;
                font-weight: 400;
                color: $textCol2;
              }

              .date {
                color: $textCol3;
                opacity: 0.5;
              }

              .tit {
                font-size: 1.4rem;
                color: #000;
                margin-bottom: 2px;
              }

              .btn-delete {
                position: absolute;
                right: 16px;
                top: 14px;
                @include ic(delete_line, 14, 14);
                opacity: 0;
              }
            }

            &.none {
              padding: 70px;
              text-align: center;
              font-size: 1.4rem;
              line-height: 1.5;
              width: 274px;

              &:before {
                content: "";
                display: block;
                @include ic(bell_line, 23, 23);
                margin: 0 auto 8px;
              }

              p {
                font-size: 1.2rem;
                font-weight: 400;
                color: $textCol2;
              }
            }

            &.top {
              background-color: $colPrimary;
              width: 100%;
              position: sticky;
              left: 0;
              top: 0;
              z-index: 1;
              border-radius: 5px 5px 0 0;
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;

              .tit {
                font-size: 1.6rem;
                font-weight: 600;
              }

              .right {
                .btn {
                  font-size: 1.2rem;
                  font-weight: 400;
                  margin-left: 8px;
                  padding-left: 8px;
                  border-left: 1px solid $textCol2;

                  &:first-child {
                    border-left: none;
                  }
                }
              }
            }

            &.notice {
              margin-bottom: 20px;
              position: relative;

              &:after {
                content: "";
                display: block;
                width: calc(100% - 40px);
                position: absolute;
                left: 20px;
                bottom: -10px;
                background-color: $colLine;
                height: 1px;
              }

              .top {
                &:after {
                  content: "";
                  display: inline-block;
                  @include ic(notice_line_grey, 12, 10);
                  margin-left: 2px;
                }
              }
            }
          }
        }
      }

      .profile {
        &.active {
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background-color: $colPrimary;
            position: absolute;
            left: 0;
            bottom: -15px;
          }
        }

        .image-wrap {
          position: relative;
          margin-right: 10px;

          .image {
            @include ic(profile, 40, 40);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 20px;

            img {
              // width: 100%;
              // height: auto;
              width: 40px;
              height: 40px;
              object-fit: cover;
            }

            &.set {
              background-image: none;
            }
          }

          .grade {
            display: block;
            position: absolute;
            left: -2px;
            bottom: 0;

            &.type {
              &-1 {
                @include ic(grade_1, 16, 16);
              }

              &-2 {
                @include ic(grade_2, 16, 16);
              }

              &-3 {
                @include ic(grade_3, 16, 16);
              }

              &-4 {
                @include ic(grade_4, 16, 16);
              }

              &-5 {
                @include ic(grade_5, 16, 16);
              }
            }
          }
        }

        .text-wrap {
          text-align: left;

          p {
            width: fit-content;
            display: flex;
            align-items: flex-end;
            font-size: 1.2rem;
            margin-top: 4px;
          }

          .name {
            @include text_ellipsis;
            display: inline-block;
            max-width: 72px;
            font-size: 1.4rem;
            font-weight: 600;
            padding-left: 2px;
          }
        }
      }

      .my-menu {
        position: absolute;
        right: 0;
        top: 48px;
        background-color: #fff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        z-index: -1;

        &.show {
          z-index: 200;
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s;
        }

        .btn {
          width: 184px;
          height: 42px;
          padding: 0 20px;
          font-size: 1.4rem;
          justify-content: flex-start;
          box-sizing: border-box;

          &:hover {
            background-color: $colBg;
          }

          &:active {
            background-color: $colGrey;
          }

          &.bottom {
            justify-content: center;
            color: $textCol3;
            font-size: 1.2rem;
          }

          &.c:before,
          &.h:before {
            content: "";
            @include ic(client_fill, 14, 14);
            margin-right: 6px;
          }

          &.h:before {
            @include ic(heroes_fill, 14, 14);
          }

          &.guide {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 62px;

            .text {
              font-size: 1.2rem;
              color: $textCol3;
              margin: 6px 0 -2px;
            }
          }
        }
      }
    }
  }

  &-logo {
    width: 128px;
    height: 37px;
    background: url(../assets/images/logo.svg) no-repeat center;
    margin-right: 20px;
    position: relative;
    top: 3px;
  }

  &-menu {
    height: 100%;
    display: flex;
    align-items: center;

    &--item {
      padding: 0 12px;
      height: 100%;
      display: flex;
      align-items: center;

      .btn {
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        font-weight: 600;
        white-space: nowrap;
        cursor: pointer;

        &.active {
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background-color: $colPrimary;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        & > img {
          margin-top: -12px;
          margin-left: 4px;
          width: 24px;
          height: 10px;
        }
      }
    }
  }

  .mobile {
    display: none;
    position: relative;
    z-index: 1;

    .menu,
    .dim,
    .right {
      display: none;
    }
  }

  &.type2 {
    background-color: #000;
    border-bottom: none;

    .header-logo {
      filter: invert(1);
    }

    .header-menu--item .btn {
      color: #fff;
    }
  }

  @media (max-width: $pc) {
    &-wrap {
      padding: 0 25px;
    }
  }

  @media (max-width: $tab) {
    &-wrap {
      height: 55px;
      // justify-content: center;

      .right {
        width: 0;
        height: 0;
        position: absolute;

        & > .btn-black,
        .tooltip,
        & > .profile {
          display: none;
        }

        .profile.mobile {
          display: flex;
        }

        & > .btn-login {
          width: 105px;
          position: absolute;
          right: 5px;
        }

        & > .btn-counsel {
          width: 105px;
          position: absolute;
          right: 5px;
        }

        .alarm-list {
          position: fixed;
          left: 0;
          top: 55px;
          right: 0;
          bottom: 0;
          max-height: unset;
          border-radius: 0;

          &--item {
            &.top {
              border-radius: 0;
            }

            &.none {
              width: 100%;
            }
          }
        }
      }
    }

    &-menu {
      display: flex;
      margin-left: 16px;
    }

    &-logo {
      margin: 0;
      width: 100px;
      background-size: 100%;
    }

    &.type2 {
      .header-wrap {
        .right {
          display: block;
          position: absolute;
          right: 20px;
          top: 12px;

          .btn-md {
            height: 30px;
            padding: 0 10px;
          }
        }
      }
    }

    .mobile {
      display: block;

      &.active {
        .dim,
        .right {
          display: block;
        }
      }

      .menu {
        display: block;
        @include ic(hambuger, 32, 32);
        position: absolute;
        right: 0;
        top: calc(50% - 13px);

        &.on:after {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 5px;
          background-color: $colSub;
          position: absolute;
          right: -7px;
          top: -2px;
        }
      }

      .dim {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(51, 51, 51, 0.4);
        backdrop-filter: blur(2px);
      }

      .right {
        position: fixed;
        right: 0;
        top: 0;
        width: 80%;
        height: 100%;
        background-color: #fff;
        overflow-y: auto;

        .close {
          @include ic(close, 30, 30);
          position: fixed;
          right: 10px;
          top: 10px;
          z-index: 2;
        }

        .profile {
          padding: 30px;
          width: 100%;
          justify-content: flex-start;
          border-bottom: 1.5px solid $colLine;

          .text-wrap {
            p {
              font-size: 1.6rem;
              width: fit-content;
            }

            .name {
              max-width: 145px;
              font-size: 2rem;
            }
          }
        }

        .register {
          font-size: 1.8rem;
          font-weight: 800;
          width: 100%;
          padding: 30px;
          justify-content: flex-start;
          border-bottom: 1.5px solid $colLine;

          &:after {
            content: "";
            display: inline-block;
            @include ic(arrow_right, 13, 13);
            margin-left: 10px;
          }
        }

        .menu-list {
          padding: 30px;
          // border-bottom: 1.5px solid $colLine;

          &:last-child {
            border-bottom: none;
          }

          &--item {
            .btn {
              font-size: 1.6rem;
              font-weight: 400;
              margin-bottom: 34px;

              & + .text {
                display: block;
                font-size: 1.4rem;
                color: #999;
                margin: -25px 0 30px;
              }

              &.logout {
                position: absolute;
                right: 30px;
                bottom: 30px;
                font-size: 1.4rem;
              }

              &.new {
                position: relative;

                &:after {
                  content: "";
                  display: block;
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: $colSub;
                  position: absolute;
                  right: -10px;
                  top: 0;
                }
              }

              &.active {
                position: relative;

                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-color: $colPrimary;
                  left: 0;
                  bottom: -5px;
                }
              }

              &.beta {
                &:after {
                  content: "";
                  display: inline-block;
                  background: url(../assets/images/beta.svg) no-repeat center /
                    100%;
                  width: 20px;
                  height: 15px;
                  position: relative;
                  top: -5px;
                  left: 6px;
                }
              }

              &.c:before,
              &.h:before {
                content: "";
                @include ic(client_fill, 14, 14);
                margin-right: 6px;
                min-width: 14px;
              }

              &.h:before {
                @include ic(heroes_fill, 14, 14);
              }
            }

            &:last-child .btn {
              margin-bottom: 0;
            }
          }

          &.etc {
            .menu-list--item .btn {
              color: $textCol2;
            }
          }
        }
      }
    }
  }
}

.m-header--menu {
  display: none;

  @media (max-width: 860px) {
    display: flex;
    height: 50px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .btn {
      font-size: 16px;
      font-weight: 600;
      padding: 0 12px;
      white-space: nowrap;

      &.active {
        position: relative;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background-color: $colPrimary;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      & > img {
        margin-top: -12px;
        margin-left: 4px;
        width: 24px;
        height: 10px;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: $pc;
  position: relative;
  margin: 0 auto;
  min-height: calc(100vh - 354px);

  .top-back {
    margin: 40px 0;
    font-size: 1.6rem;
    font-weight: 600;

    & + .mt-40 {
      margin-top: 20px !important;
    }

    &:before {
      content: "";
      display: inline-block;
      @include ic(arrow_back, 8, 14);
      margin-right: 12px;
    }

    & + .section-tit.lg {
      padding-top: 20px;
    }
  }

  .section {
    &:last-child {
      padding-bottom: 100px;
    }

    &-tit {
      font-size: 1.2rem;
      color: $colSub;
      // padding-top: 30px;
      margin-bottom: 2px;

      &.mt-100 {
        padding-top: 0;

        @media (max-width: $mo) {
          margin-top: 30px !important;

          & + .input-wrap {
            .input-label {
              margin-top: 16px;
            }
          }
        }
      }

      .right {
        float: right;
        font-size: 1.2rem;
      }

      &.lg {
        padding-top: 100px;
        font-size: 2.4rem;
        font-weight: 700;
        color: #000;
        line-height: 1.2;

        .sub {
          font-size: 1.4rem;
          font-weight: 400;
          color: $textCol2;
          margin-top: 6px;
        }
      }
    }
  }

  &-none {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    font-size: 1.8rem;
    color: $textCol3;
    font-weight: 400;
  }

  @media (max-width: $mo) {
    min-height: calc(100vh - 350px);
    padding: 0 25px;

    &.x-hidden {
      overflow-x: hidden;
    }

    .section {
      &-tit {
        &.lg {
          padding-top: 30px;
        }

        &.mt-40 {
          margin-top: 0 !important;
        }
      }

      &:last-child {
        padding-bottom: 50px;
      }
    }

    .top-back {
      margin: 30px 0 20px;

      &.type2 {
        margin: 13px 0 0;
      }
    }
  }
}

.footer {
  background-color: #000;

  &-wrap {
    width: 100%;
    max-width: $pc;
    margin: 0 auto;
    padding: 50px 0 110px;
    display: flex;
    position: relative;
  }

  &-logo {
    width: 104px;
    height: 30px;
    background: url(../assets/images/logo.svg) no-repeat center / 100%;
    margin-right: 40px;
    filter: invert(1);
  }

  &-info {
    padding-top: 5px;

    .text {
      line-height: 1.5;
      color: $textCol3;
      font-size: 1.4rem;
      margin: 20px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-menu {
    .btn {
      color: $colBg;
      font-size: 1.4rem;
      font-weight: 600;
      margin-right: 80px;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 14px;
        background-color: $textCol3;
        position: absolute;
        right: -40px;
        top: 1px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  &-sns {
    position: absolute;
    right: 0;
    top: 50px;
    display: flex;
    align-items: center;

    .btn {
      width: 30px;
      height: 23px;
      margin-left: 24px;

      &.sns {
        &-1 {
          @include ic(footer_sns_linkedin, 23, 23);
        }

        &-2 {
          @include ic(footer_sns_blog, 24, 22);
        }

        &-3 {
          @include ic(footer_sns_youtube, 29, 21);
        }
      }
    }
  }

  @media (max-width: $tab) {
    &-wrap {
      flex-direction: column;
      align-items: center;
    }

    &-logo {
      display: none;
    }

    &-menu {
      text-align: center;

      .btn:last-child {
        margin-right: 0;
      }
    }

    &-info {
      .text {
        text-align: center;
      }
    }

    &-sns {
      position: relative;
      top: 0;
      margin-top: 30px;

      .sns-1 {
        margin-left: 0;
      }
    }
  }

  @media (max-width: $mo) {
    &-wrap {
      padding: 30px 0 60px;
    }

    &-menu {
      .btn {
        margin-right: 28px;

        &:after {
          right: -14px;
        }
      }
    }

    &-info {
      .text {
        font-size: 1.2rem;
      }
    }
  }
}

.main {
  &-banner {
    margin: 40px 0 62px;
    width: 1060px;
    position: relative;
    // left: -30px;
    left: -25px;

    &--slide {
      height: 600px;
      // background-color: #000;
      border-radius: 20px;
      margin: 0 10px;
      // padding: 0 100px;
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .text {
        span,
        p {
          color: #fff;
          font-size: 2.4rem;
        }

        p {
          font-weight: 800;
          font-size: 4rem;
          margin-top: 10px;
        }
      }

      &.type2 {
        background-color: #0a7aff;

        .text {
          span,
          p {
            color: #ff9d0a;
          }
        }
      }

      &.type3 {
        background-color: $colSub;
      }
    }

    .slick-list {
      margin: 0 -5px;
    }

    .slick-slide > div {
      padding: 0 5px;
    }

    .swiper-pagination {
      position: relative;
      bottom: -8px !important;
      text-align: left;
      padding: 0 15px;
      z-index: 1;

      > li {
        margin: 0;
        width: 8px;
        margin-right: 6px;

        > button {
          padding: 0;
          width: 8px;

          &::before {
            color: #d6d6d6;
            opacity: 1;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            content: "";
            background-color: #d6d6d6;
          }
        }

        &.slick-active > button::before {
          background-color: $colPrimary;
        }
      }
    }
  }

  &-search {
    position: relative;
    width: 100%;

    & + .mypage-tab {
      margin-top: 60px;
    }

    .input-text {
      border: 2px solid $colPrimary;
      border-radius: 10px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      height: 80px;
      font-size: 2.4rem;
      font-weight: 700;
      color: #000;
      padding: 0 110px 0 30px;

      &:focus {
        border: 2px solid $colPrimary;
      }

      &::placeholder {
        color: $colGrey;
      }
    }

    .btn-search {
      @include ic(search_line_wh, 50, 50);
      background-color: $colPrimary;
      border-radius: 25px;
      position: absolute;
      top: 15px;
      right: 30px;
    }

    &.type2 {
      margin: 40px 0;

      .text {
        font-size: 2.4rem;
        font-weight: 700;
        position: absolute;
        left: 30px;
        top: 30px;
        @include gradient;
      }
    }
  }

  &-section {
    padding: 100px 0;
    position: relative;

    &--top {
      text-align: center;
      position: relative;

      .tit {
        font-size: 2.4rem;
        font-weight: 700;

        .beta {
          &:after {
            content: "";
            display: inline-block;
            background: url(../assets/images/beta.svg) no-repeat center / 100%;
            width: 36px;
            height: 15px;
            position: relative;
            top: -5px;
            left: 6px;
          }
        }

        &.left {
          text-align: left;
          margin-bottom: 30px;
          font-size: 3rem;

          .more {
            float: right;
            font-size: 1.4rem;
            font-weight: 600;
            color: $textCol3;
            margin-top: 10px;
          }
        }

        .guide {
          font-size: 1.6rem;
          font-weight: 400;
          color: $textCol2;
          margin-top: 10px;

          @media (max-width: $mo) {
            font-size: 1.4rem;
          }
        }
      }

      .right {
        position: absolute;
        right: 0;
        top: 20px;
        display: flex;

        .price {
          background-color: #43f4ff;
          padding: 0 10px;
          height: 32px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          font-size: 1.6rem;
          font-weight: 400;

          strong {
            font-weight: 600;
            margin-right: 4px;
          }

          &:before {
            content: "";
            display: block;
            @include ic(time_line, 16, 16);
            margin-right: 4px;
          }
        }
      }

      .btn-round {
        width: 268px;
        height: 50px;
        border-radius: 25px;
        margin-top: 15px;

        .text {
          font-size: 2rem;
          font-weight: 900;
          @include gradient;
        }

        &:active {
          background-color: $textCol1;
        }
      }

      .bottom {
        text-align: right;
        margin: 17px 0;

        .btn {
          font-size: 1.4rem;
          font-weight: 600;
          color: $textCol2;
        }
      }
    }
  }

  &-guide {
    .top {
      display: flex;
      justify-content: space-between;

      .text {
        font-size: 1.6rem;
        color: $textCol2;
        line-height: 1.2;
        // margin-right: 20px;

        strong {
          display: block;
          font-size: 3rem;
          font-weight: 800;
          margin-bottom: 20px;
        }
      }

      .main-guide--image {
        width: 740px;
        height: 416px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
        }
      }

      @media (max-width: $tab) {
        flex-direction: column;

        .main-guide--image {
          width: 100%;
          height: auto;
        }

        .text strong {
          margin-bottom: 10px;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 205px;
      background-color: $colBg;
      color: #fff;
      font-size: 4rem;
      font-weight: 800;
      border-radius: 20px;
      margin-top: 50px;
      text-align: center;
      line-height: 1.2;
    }
  }

  &-modal {
    &.modal-con {
      background-color: transparent;
      padding: 0;
      position: relative;

      .btn-close {
        position: absolute;
        right: 20px;
        top: 20px;
        @include ic(close_grey, 30, 30);
        background-size: 100%;
      }

      .btn-lg {
        position: absolute;
        left: calc(50% - 150px);
        bottom: 100px;

        @media (max-width: $mo) {
          left: 10%;
          bottom: 30px;
        }
      }
    }
  }

  &-img {
    display: flex;

    &--item {
      flex: 1;
      margin-right: 20px;
      position: relative;

      img {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      .label {
        position: absolute;
        left: 35px;
        top: 35px;
        font-size: 1.6rem;
        color: #fff;
        font-weight: 600;
      }

      .text {
        position: absolute;
        left: 35px;
        top: 71px;
        font-size: 2.4rem;
        color: #fff;
        font-weight: 700;
        line-height: 1.3;
        text-align: left;
      }

      @media (max-width: $mo) {
        .label {
          font-size: 14px;
          font-weight: 400;
        }

        .text {
          font-size: 20px;
          top: 59px;
        }
      }
    }
  }

  &-stage {
    display: flex;

    .left {
      margin-right: 60px;
      min-width: 228px;
      width: 228px;

      .tit {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 36px;
        letter-spacing: -1px;
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 288px);
      flex-direction: column;

      .top,
      .bottom {
        display: flex;
      }

      .bottom {
        flex-direction: row-reverse;

        .step:after {
          transform: rotate(180deg);
        }
      }

      .box {
        width: calc(100% / 3 - 7px);
        height: 151px;
        padding: 30px;
        margin: 0 10px 10px 0;
        border: 1px solid $colLine;
        border-radius: 5px;

        &.no-margin-right {
          margin-right: 0;
        }

        .step.down:after {
          transform: rotate(90deg);
        }

        .step {
          font-size: 2.4rem;
          font-weight: 700;
          position: relative;
          padding-bottom: 10px;
          border-bottom: 1px solid $colPrimary;
          margin-bottom: 20px;

          span {
            @include gradient;
          }

          &:after {
            content: "";
            display: block;
            @include ic(arrow_right_blue2, 16, 16);
            position: absolute;
            right: 0;
            top: 4px;
          }
        }

        .con {
          font-size: 1.6rem;
          color: $textCol2;
          line-height: 1.3;

          strong {
            font-weight: 800;
            color: $textCol2;
          }
        }
      }
    }
  }

  &-top3 {
    max-width: calc(50% - 10px);
    text-align: center;

    & > .tit {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 10px;
      color: $textCol1;

      &:after {
        content: "";
        display: inline-block;
        @include ic(arrow_right_lg, 20, 20);
        margin-left: 18px;
      }
    }

    & > .guide {
      font-size: 1.6rem;
      font-weight: 400;
      color: $textCol2;
    }

    & > .btn-black {
      margin: 20px auto;
    }

    .heroes-list--item {
      width: 100%;
      min-width: unset;
      margin: 0 0 10px 0;
      max-width: unset;

      .title {
        padding-right: 0;
        text-align: left;
      }
    }
  }

  @media (max-width: $pc) {
    &-search {
      transform: translate(0, 0);
    }

    &-search,
    &-banner {
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: $tab) {
    &-search {
      transform: translate(0, 0);
    }

    &-stage {
      flex-direction: column;

      .left {
        width: 100%;

        .tit {
          margin: 0;
          font-size: 2rem;
        }
      }

      .right {
        width: 100%;
        margin-top: 20px;
      }
    }

    &-modal {
      text-align: center;

      &.modal-con {
        width: auto;
      }

      img {
        margin: 0 auto;
      }
    }
  }

  @media (max-width: $mo) {
    &-search {
      transform: translate(0, 0);

      &.mt-40 {
        margin-top: 30px !important;
      }
    }

    &-banner {
      margin: 30px auto 42px;

      &--slide {
        // padding: 0 50px;
        height: auto;
        margin: 0;

        .text {
          word-break: keep-all;

          span {
            font-size: 2rem;
          }

          p {
            font-size: 3.5rem;
            line-height: 1.2;
          }
        }
      }
    }

    &-search {
      .input-text {
        height: 60px;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0 60px 0 20px;
      }

      .btn-search {
        @include ic(search_line_wh, 30, 30);
        background-size: 15px;
        top: 15px;
        right: 20px;
      }

      &.type2 {
        .text {
          font-size: 1.6rem;
          top: 22px;
          left: 20px;
        }
      }
    }

    &-img {
      flex-direction: column;

      &--item {
        margin: 0 0 20px 0;
        text-align: center;
      }
    }

    &-section {
      padding: 50px 0;

      &--top {
        .tit.left {
          font-size: 2rem;
          word-break: keep-all;
        }

        .right {
          margin: -10px 0 0;
          position: relative;
          top: 0;

          .price {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    &-stage {
      .right {
        .top,
        .bottom {
          flex-direction: column;
        }

        .box {
          width: 100%;
          margin: 0 0 10px 0;
          padding: 20px;
          height: auto;

          .step {
            margin-bottom: 16px;
          }

          .step:after {
            transform: rotate(90deg) !important;
          }

          .con br {
            display: none;
          }

          strong:not(:first-child) {
            margin-left: 3px;
          }
        }
      }
    }

    &-top3 {
      max-width: unset;

      & > .tit {
        font-size: 2rem;

        &:after {
          margin-left: 12px;
          @include ic(arrow_right_lg_m, 10, 16);
        }
      }

      & > .guide {
        font-size: 1.4rem;
      }
    }

    &-guide {
      .top {
        flex-direction: column;

        .text {
          margin-bottom: 20px;
          font-size: 1.4rem;

          strong {
            font-size: 2rem;
          }
        }

        .main-guide--image {
          width: 100%;
          height: auto;
        }
      }
    }

    &-modal {
      &.modal-con {
        width: auto;
        margin: 0 18px;
      }

      img {
        width: 100%;
      }

      .btn-lg {
        width: 80%;
        left: 10%;
      }
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &.show {
    z-index: 999;
    opacity: 1;
    transition: opacity 0.3s;

    .bottom {
      transform: translateY(0);
    }

    .left {
      transform: translateX(0);
      padding: 0;
    }
  }

  &-dim {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.4);
    backdrop-filter: blur(2px);
  }

  &-tit {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 22px;

    &.sub {
      text-align: left;
      font-size: 1.6rem;
      font-weight: 600;
      color: $textCol3;
      border-bottom: 1px solid #eee;
      padding-bottom: 18px;
    }

    &.left {
      text-align: left;
    }
  }

  &-con {
    position: relative;
    z-index: 1;
    background-color: #fff;
    min-width: 428px;
    padding: 70px 80px;
    border-radius: 10px;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.1);

    &.bg-black {
      background-color: #000;
      padding: 40px 65px 45px;

      .modal-tit {
        color: #fff;
      }
    }

    &.only-image {
      padding: 0;
      min-width: unset;
      background-color: transparent;
      text-align: center;
      max-width: 90vw;
      max-height: 90vh;
      border-radius: 5px;
      overflow: hidden;

      img {
        margin: 0 auto;
        width: 100%;
      }
    }

    .scroll {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      padding-right: 25px;
      width: calc(100% + 25px);
    }

    .btn-close {
      position: absolute;
      right: 25px;
      top: 25px;
      @include ic(close, 25, 25);

      &.wh {
        filter: invert(1);
      }
    }

    .btn-wrap {
      margin: 30px 0 0;

      @media (max-width: $mo) {
        margin: 22px 0 0;
      }
    }

    &.lg {
      width: 800px;
      border-radius: 5px;

      &.p-20 {
        padding: 25px 20px;
      }

      &.p-50 {
        padding: 50px 65px;
      }

      @media (max-width: $mo) {
        &.history {
          width: 100vw;
          height: 100%;
          border-radius: 0;
          border: none;
        }
      }
    }

    &.message {
      background-color: #000;
      padding: 50px 95px 55px 95px;
      width: 680px;

      & > .text {
        text-align: center;
        white-space: pre-line;

        p:not(.strong) {
          font-size: 14px;
          color: $colGrey;
          font-weight: 400;
          line-height: 17.5px;
        }

        .strong {
          font-size: 2rem;
          color: #fff;
          font-weight: 600;
          line-height: 100%;
          margin-bottom: 22px;

          @media (max-width: $mo) {
            margin-bottom: 12px;
          }
        }

        &.sub {
          text-align: left;
          font-size: 1.2rem;
          color: $textCol3;
          margin-top: 6px;
        }
      }

      .input {
        display: flex;
        margin-top: 30px;

        &-label {
          color: $textCol3;

          &.sm {
            margin: 0 0 4px 10px !important;
          }
        }

        .input-text,
        .input-select {
          background-color: transparent !important;
          color: $textCol3 !important;

          &.color-b {
            color: transparent !important;
          }
        }

        .sm {
          width: 40%;
          min-width: 156px;
          margin-left: 10px;
          white-space: nowrap;

          & + * {
            margin-left: 10px;

            &.input-password {
              margin: 4px 0 0 0;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          @media (max-width: $mo) {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .input-wrap {
          width: 100%;

          .sm {
            margin: 0 0 4px 0;
          }
        }
      }
    }

    &.terms {
      width: 950px;
      padding: 70px 50px;

      .modal-tit {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 20px 0;
      }

      .terms-con {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        padding-right: 20px;
      }
    }

    &.full {
      width: 100vw;
      height: 100%;
      padding: 60px 25px 50px;
      border-radius: 0;

      .scroll {
        max-height: calc(100% - 20px);
        // padding-bottom: 50px;
      }

      .btn-wrap {
        margin-top: 0 !important;
        position: relative;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
        width: calc(100% + 50px);
        left: -25px;
        padding: 4px 4px 0;
        // &:after{
        //     content:"";
        //     display:block;
        //     width:calc(100% + 50px);
        //     height:100%;
        //     position:absolute;
        //     left:-25px;
        //     top:-4px;
        //     background-color:#fff;
        //     box-shadow: 0 -2px 5px rgba(0, 0, 0, .15);
        // }
      }
    }

    .text-box {
      font-size: 1.4rem;
      color: $textCol2;
      line-height: 1.5;
      word-break: keep-all;
      text-align: center;
      max-height: 294px;
      overflow: auto;
    }
  }

  @media (max-width: $tab) {
    &-con {
      min-width: unset;
      width: 90%;
      padding: 50px 40px 40px;

      &.m-full {
        width: 100%;
        height: 100%;
        padding: 50px 25px;
        border-radius: 0;

        & > .tit {
          font-size: 1.8rem;
          font-weight: 500;
          text-align: center;
          margin: -25px 0 30px;
        }

        .alarm-list--item {
          padding: 0;
          overflow-y: auto;
          height: calc(100% - 27px);

          .alarm-wrap {
            width: 100%;
          }

          &.top {
            background-color: transparent;
            margin-bottom: 0;
            padding: 0 0 20px;
            height: auto;
          }
        }
      }

      .text-box {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
      }

      &.lg {
        width: 90%;

        &.p-50 {
          padding: 20px 30px;
        }
      }

      &.full {
        width: 100%;
      }

      .btn-close {
        width: 20px;
        height: 20px;
        right: 20px;
        top: 20px;
        background-size: 18px;
      }

      &.message {
        padding: 33px 32px;
        margin: 0 18px;
        border-radius: 5px;

        .text {
          width: 100%;
        }
      }

      &.terms {
        padding: 15px 25px;
        width: 100%;
        height: 100%;
        border-radius: 0;

        .terms-con {
          max-height: calc(100% - 140px);
          height: 100%;
        }

        .btn-wrap {
          margin: 25px 0 0;
        }
      }

      .btn-wrap {
        .btn-lg {
          width: 100%;
          margin: 0;

          & + .btn-lg {
            margin-left: 4px;
          }
        }

        .btn-half {
          width: calc(50% - 5px);

          &:last-child {
            margin-left: 3px;
          }
        }
      }
    }
  }

  @media (max-width: $mo) {
    &-tit {
      font-weight: 500;
    }

    &-con {
      &.lg.p-20 {
        padding: 20px;
      }

      &.message {
        .input {
          flex-direction: column;
          margin-top: 16px;

          &.input-2 {
            flex-direction: row;

            .input-number .btn.down {
              bottom: 22px;
            }
          }

          &.type2 {
            .input-number {
              margin: 10px 0 0;
            }
          }

          .sm {
            margin: 10px 0 0 0 !important;

            &.mt-0 {
              margin-top: 0 !important;

              & + .input-number {
                .btn.down {
                  bottom: 12px;
                }
              }
            }
          }

          .input-text {
            width: 100%;
          }
        }
      }

      &.bg-black {
        padding: 40px;
        border-radius: 5px;
      }

      &.terms {
        .modal-tit {
          border: none;
          padding: 9px 0;
          margin-bottom: 11px;
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 100px;

  & + .btn-wrap {
    margin-top: 0 !important;
  }

  &-item:not(.arrow) {
    width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      font-size: 1.6rem;
      font-weight: 600;
      margin-right: 14px;
      color: $textCol2;

      &.active {
        background-color: $colPrimary;
        color: #000;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #000;
      }
    }
  }

  .first .btn {
    @include ic(arrow_first, 30, 30);
    margin-right: 10px;
  }

  .prev .btn {
    @include ic(arrow_prev, 30, 30);
  }

  .next .btn {
    @include ic(arrow_next, 30, 30);
  }

  .last .btn {
    @include ic(arrow_last, 30, 30);
    margin-left: 10px;
  }

  @media (max-width: $mo) {
    padding: 30px 0 40px;

    &-item:not(.arrow) {
      .btn {
        margin-right: 7px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.textalign_center {
  text-align: center;
}

.custom-select {
  position: relative;
  // z-index: 10;
  height: 36px;

  &.first:after {
    content: "";
    display: block;
    width: 1px;
    height: 28px;
    position: absolute;
    right: -20px;
    top: 0;
    background-color: #eee;
  }

  &.error {
    .btn {
      border-color: #df2e47;
      caret-color: #df2e47;
      color: #df2e47;
    }
  }

  &.revenue {
    margin-right: 40px;
    width: auto;
    height: 28px;

    &.mr-none {
      margin-right: 0;
    }
  }

  &.text-start {
    .btn {
      justify-content: flex-start;
    }
  }

  &.project-request {
    height: 50px;

    .btn {
      justify-content: flex-start;
    }
  }

  &.full {
    width: 100%;
    height: 50px;

    .btn {
      justify-content: flex-start;
    }
  }

  &.color-w {
    .btn {
      color: #999;
    }
  }

  .select {
    width: 100%;
    white-space: nowrap;
    height: 100%;
    border: 1px solid $colLine;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 45px 0 18px;
    color: #333;
    background: url(../assets/images/icon/arrow_bottom_fill.svg) no-repeat
      calc(100% - 18px) 50%;
    transition: none;
    justify-content: flex-start;

    &.active {
      background: url(../assets/images/icon/arrow_top_fill.svg) no-repeat
        calc(100% - 18px) 50%;

      & + .select-list {
        transform: scaleY(1);
      }
    }

    &-list {
      min-width: 100%;
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      background-color: #fff;
      border: 1px solid $colLine;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      max-height: 220px;
      overflow-x: hidden;
      overflow-y: scroll;
      transition: transform 0.2s;
      transform: scaleY(0);
      transform-origin: top;
      z-index: 9;

      &--item {
        height: 40px;
        line-height: 40px;
        color: #4d4d4d !important;
        padding: 0 18px;
        transition: all 0.3s;
        white-space: nowrap;
        cursor: pointer;

        .text {
          font-size: 1.4rem;
          color: $textCol2;
        }

        &:hover {
          background-color: $colBg;
        }

        &:active {
          background-color: $colGrey;
        }
      }

      &--selected {
        .text {
          color: $colSub !important;
        }

        &:hover {
          background-color: $colBg !important;
        }
      }
    }

    &:disabled {
      background-color: #f6f6f6 !important;
      border-color: #eee !important;
      color: #999 !important;
    }
  }
}

.big-hr {
  margin: 100px 0;
  width: 100vw;
  border-top: 1px solid #eee;
  position: absolute;
  left: calc(-1 * ((100vw - 100%) / 2));

  @media (max-width: $mo) {
    margin: 50px 0;
  }
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100%;
  min-height: calc(100vh - 354px);

  $offset: 187;
  $duration: 1.4s;

  .spinner {
    animation: rotator $duration linear infinite;
    stroke: $colPrimary;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
      colors ($duration * 4) ease-in-out infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }

    50% {
      stroke-dashoffset: $offset/4;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}

.fade-container {
  display: flex;
  position: relative;
  width: 25%;

  .fade {
    // position: absolute;
    opacity: 0;
    transition: opacity 0.25s ease-out;
    pointer-events: none;
    word-break: keep-all;
    cursor: pointer;
    width: 0;
    height: 0;
    overflow: hidden;

    &.active {
      opacity: 1;
      transition: opacity 0.5s ease-in;
      pointer-events: all;
      width: 100%;
      margin: 0 0 20px 0;
      height: auto;
    }
  }

  @media (max-width: $tab) {
    width: 100%;
  }
}

.d-flex {
  display: flex !important;

  &.align-center {
    align-items: center !important;
  }
}

.text.badge-flex-box {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  * {
    margin: 0 !important;
  }
}

.hr {
  width: calc(100% + 50px);
  position: relative;
  left: -25px;
  height: 1px;
  background-color: #eee;
  margin-bottom: 37px;
}

@media (max-width: $mo) {
  .btn-bottomfix {
    position: absolute;
    bottom: 50px;
    left: 25px;
    right: 25px;
  }
}

.mobile {
  &.alarm {
    height: 100%;
  }

  .alarm-list {
    height: 100%;

    &--item {
      padding: 0 20px;

      &:last-child {
        margin-bottom: 20px;
      }

      .alarm-wrap {
        width: 274px;
        padding: 14px 18px;
        font-size: 1.2rem;
        border-radius: 5px;
        line-height: 1.5;
        text-align: start;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s;
        position: relative;

        &:hover {
          background-color: $colBg;

          .tit {
            text-decoration: underline;
            text-underline-offset: 2px;
          }

          .btn-delete {
            opacity: 1;
          }
        }

        &.new {
          // padding-left: 30px;

          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: $colSub;
            position: absolute;
            left: 0;
            top: 18px;
          }
        }

        &.read {
          .top,
          .date,
          .tit {
            color: $colGrey;
          }
        }

        .top,
        .date {
          font-size: 1.2rem;
          font-weight: 400;
          color: $textCol2;
        }

        .date {
          color: $textCol3;
          opacity: 0.5;
        }

        .tit {
          font-size: 1.6rem;
          color: #000;
          margin-bottom: 2px;
        }

        .btn-delete {
          position: absolute;
          right: 0;
          top: 14px;
          @include ic(delete_line, 14, 14);
          // opacity: 0;
        }
      }

      &.none {
        padding: 70px;
        text-align: center;
        font-size: 2rem;
        line-height: 1.5;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0;
        color: #000;
        font-weight: 600;

        &:before {
          content: "";
          display: block;
          @include ic(bell_line, 23, 23);
          margin: 0 auto 10px;
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          color: $textCol2;
          margin-top: 10px;
        }
      }

      &.top {
        background-color: $colPrimary;
        width: 100%;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 5px 5px 0 0;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .tit {
          font-size: 1.6rem;
          font-weight: 600;
        }

        .right {
          .btn {
            font-size: 1.2rem;
            font-weight: 400;
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid $textCol2;

            &:first-child {
              border-left: none;
              padding-left: 0;
              margin-left: 0;
            }
          }
        }
      }

      &.notice {
        margin-bottom: 20px;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: calc(100% - 40px);
          position: absolute;
          left: 20px;
          bottom: -10px;
          background-color: $colLine;
          height: 1px;
        }

        .top {
          &:after {
            content: "";
            display: inline-block;
            @include ic(notice_line_grey, 12, 10);
            margin-left: 2px;
          }
        }
      }
    }
  }
}

.m-floating-multi {
  position: sticky;
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  left: 25px;
  bottom: 50px;
  z-index: 99;
  margin: -50px 0 50px;

  &:before {
    content: "";
    display: block;
    height: calc(100% + 75px);
    position: absolute;
    left: -25px;
    top: -25px;
    background-color: #fff;
    z-index: -1;
  }

  & button {
    width: 100%;
    height: 50px;

    &:first-child {
      min-width: 222px;
    }

    &:last-child {
      min-width: 114px;
    }
  }
}

.m-floating {
  position: sticky;
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  left: 25px;
  bottom: 50px;
  z-index: 99;
  margin: -50px 0 50px;
  background-color: #000;

  &.mt-0 {
    margin: 0 !important;
  }

  & + .section {
    @media (max-width: $mo) {
      margin-top: -20px;
    }
  }

  & + .community {
    margin-top: -67px;
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: calc(100% + 75px);
    position: absolute;
    left: -25px;
    top: -25px;
    background-color: #fff;
    z-index: -1;
  }

  &.link:not(.m-btn) span,
  :not(.m-btn) span {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  &.link span:after {
    content: "";
    display: block;
    @include ic(arrow_right_active, 6, 12);
    margin-left: 6px;
  }

  & button.send {
    background-color: $colPrimary;

    &:before {
      content: "";
      display: block;
      @include ic(send_line, 16, 16);
      margin-right: 6px;
    }
  }
}
