@charset "UTF-8";
@import "global.scss";

.input-photo {
  width: 162px;
  height: 162px;
  border: 1px solid $colLine;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &.set {
    &:after {
      content: "";
      background: none;
    }
  }

  &:after {
    content: "jpg,png 최대 5MB 첨부";
    background: url(../assets/images/icon/camera_line.svg) no-repeat 50% 35%;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: $textCol3;
    padding-top: 20px;

    @media (max-width: $mo) {
      content: "";
      background: url(../assets/images/icon/camera_line.svg) no-repeat 50% 50%;
      padding-top: 0;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
    }
  }
}
