// 사용하는 폰트
$font: "Pretendard"; // 기본폰트

// 컬러셋
$colPrimary: #00ff85;
$colSub: #0bb899;
$colSub2: #c5ffea;
$colError: #df2e47;
$colGrey: #d6d6d6;
$colBg: #f6f6f6;
$colLine: #eee;

// 텍스트
$textCol1: #333;
$textCol2: #666;
$textCol3: #999;
$textCol4: #ddd;

$pc: 1028px;
$tab: 860px;
$mo: 660px;

// 모바일
@mixin m {
  @media (max-width: 1024px) {
    @content;
  }
}

/**********
텍스트 말줄임
***********/
@mixin text_ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  width: 100%;
  max-width: 100%;
}

@mixin text_ellipsis_multi($line, $lh) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: #{$lh}px;
  height: #{$lh * $line}px;
  max-width: 100%;
}

/**********
아이콘
***********/
@mixin ic($name, $w, $h) {
  $url: "../assets/images/icon/#{$name}.svg";
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  width: #{$w}px;
  height: #{$h}px;
}

@mixin gradient() {
  background: linear-gradient(to bottom right, #00ff85, #0a7aff);
  color: transparent;
  -webkit-background-clip: text;
}
