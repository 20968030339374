@charset "utf-8";
@import "global.scss";


// @font-face {
// 	font-family: 'Pretendard Variable';
// 	font-weight: 45 920;
// 	font-style: normal;
// 	font-display: swap;
// 	src: local('Pretendard Variable'), url('./fonts/PretendardVariable.woff2') format('woff2-variations');
// } 

@each $fonts in 1,
2,
3,
4,
5,
6,
7,
8,
9 {
	@font-face {
		font-family: $font;
		src: url('../assets/fonts/#{$font}#{$fonts}.woff') format('woff');

		@if $fonts ==1 {
			font-weight: 100;
		}

		@else if $fonts ==2 {
			font-weight: 200;
		}

		@else if $fonts ==3 {
			font-weight: 300;
		}

		@else if $fonts ==4 {
			font-weight: 400;
		}

		@else if $fonts ==5 {
			font-weight: 500;
		}

		@else if $fonts ==6 {
			font-weight: 600;
		}

		@else if $fonts ==7 {
			font-weight: 700;
		}

		@else if $fonts ==8 {
			font-weight: 800;
		}

		@else if $fonts ==9 {
			font-weight: 900;
		}

		font-style: normal;
	}
}

// 디폴트 설정
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea,
input,
select {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: $font, sans-serif;
	color: $textCol1;
	vertical-align: baseline;
	/* a안 img가 들어갈 때 a를 위로 정렬시켜 여백을 없앨 때 사용 */
}

html,
body {
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
}

html {
	font-weight: 500;
	font-size: 10px;
	letter-spacing: -0.003em;
}

body {
	overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	/* 한글 기준은 1.2 */
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

textarea {
	outline: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

a {
	text-decoration: none;
	display: inline-block;
	color: #333;
}

i {
	display: inline-block;
	vertical-align: middle;
}

strong {
	font-weight: 700;
}

th,
td {
	vertical-align: middle;
}

input[type=password] {
	font-family: initial;
}


* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

span,
div,
p {
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	color: inherit;
}

select::-ms-expand {
	display: none;
}

b {
	color: inherit;
	font-family: inherit;
}

.web-basic {
	font-size: 16px;

	h1 {
		display: block;
		font-size: 2em;
		margin-block-start: 0.67em;
		margin-block-end: 0.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	h2 {
		display: block;
		font-size: 1.5em;
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	h3 {
		display: block;
		font-size: 1.17em;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	h4 {
		display: block;
		margin-block-start: 1.33em;
		margin-block-end: 1.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	h5 {
		display: block;
		font-size: 0.83em;
		margin-block-start: 1.67em;
		margin-block-end: 1.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	h6 {
		display: block;
		font-size: 0.67em;
		margin-block-start: 2.33em;
		margin-block-end: 2.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	strong {
		color: inherit;
	}

	ul,
	ol {
		list-style: revert;
		padding: revert;
	}

	a {
		display: revert;
	}
}